import { createAction } from 'redux-actions';
import { SET_TOKEN, SET_PENDING, SET_ERROR, SIGN_OUT } from './types';

import { login } from '../../api/account';
import { setUserData } from '../account/actions';
import { getProjects } from '../projects/actions';

export const setToken = createAction(SET_TOKEN);
const setPending = createAction(SET_PENDING);
const setError = createAction(SET_ERROR);
const setSignOut = createAction(SIGN_OUT);

export const auth = (email, password, history) => async (dispatch) => {
  dispatch(setError(false));
  dispatch(setPending(true));

  try {
    const {
      tokens: { accessToken },
      user: { _id, name, roles },
    } = await login({ email, password });
    dispatch(setToken(accessToken));
    dispatch(
      setUserData({
        id: _id,
        name,
        role: roles[0].code,
      }),
    );

    await dispatch(getProjects(true));
    dispatch(setError(null));
    dispatch(setPending(false));
  } catch (error) {
    console.log('[ERROR]', error);
    dispatch(setError(true));
    dispatch(setPending(false));
  }
};

export const signOut = () => async (dispatch) => {
  dispatch(setPending(true));
  dispatch(setSignOut());
};
