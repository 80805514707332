import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { Grid } from '@material-ui/core';
import { useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import { MoreVert as MoreIcon } from '@material-ui/icons';

import { Typography } from '../../../../components/Wrappers/Wrappers';
import Widget from '../../../../components/Widget/Widget';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import { ROUTES, NAMES, IDS } from '../../../../constants/routes';
import { ROLES } from '../../../../constants/roles';
import { getAds, getAdExpenses, deleteAdExpense, setAdExpensesPage, setAdExpensesPageItems } from '../../../../store/marketing/actions';

import Modal from './modal';

const breadcrumsRoutes = [
  {
    route: ROUTES[IDS.MARKETING],
    name: NAMES[IDS.MARKETING],
  },
  {
    route: ROUTES[IDS.MARKETING_ADS],
    name: NAMES[IDS.MARKETING_ADS],
  },
];

const MarketingAd = () => {
  const classes = useStyles();
  const { id: adId } = useParams();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedAdExpense, setSelectedAdExpense] = useState(null);
  const [anchorEls, setAnchorEl] = useState([]);
  // global
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedProjectId = useSelector((state) => state.projects.selectedProjectId);
  const ads = useSelector((state) => state.marketing.ads.data);
  const data = useSelector((state) => state.marketing.adExpenses.data);
  const count = useSelector((state) => state.marketing.adExpenses.count);
  const isPending = useSelector((state) => state.marketing.adExpenses.pending);
  const pageNumber = useSelector((state) => state.marketing.adExpenses.pageNumber);
  const pageItemCount = useSelector((state) => state.marketing.adExpenses.pageItemCount);

  useEffect(() => {
    dispatch(getAds());
    dispatch(getAdExpenses({ adId }));
  }, [selectedProjectId]);

  useEffect(() => {
    dispatch(getAdExpenses({ adId }));
  }, [pageNumber, pageItemCount]);

  const adData = useMemo(() => ads.find((ad) => ad._id === adId), [ads, adId]);

  const tableData = useMemo(
    () =>
      data.reduce((acc, i) => {
        const d = _.pick(i, ['date', 'amount', 'comment', 'createdAt', 'updatedAt']);
        return [...acc, d];
      }, []),
    [data],
  );

  const handleRowClick = useCallback((item) => {}, []);

  const handleButtonPress = () => {
    setIsOpenModal(true);
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);
    setSelectedAdExpense(null);
  };

  const handleSubmit = async () => {
    try {
      dispatch(getAdExpenses({ adId }));
      setIsOpenModal(false);
    } catch (error) {
      console.log('[CREATE AD EXPENSE MODAL ERROR]', error);
    }
  };

  const handleAdExpenseEdit = useCallback(
    (id, e) => {
      e.preventDefault();
      e.stopPropagation();

      const ad = data.find((a) => a._id === id);

      anchorEls[id] = null;
      setAnchorEl({
        ...anchorEls,
        [id]: null,
      });

      dispatch(getAdExpenses({ adId }));
      setSelectedAdExpense(ad);
      setIsOpenModal(true);
    },
    [data],
  );

  const handleAdExpenseRemove = useCallback(async (id, e) => {
    e.preventDefault();
    e.stopPropagation();
    if (window.confirm('Are you sure you want to remove this ad expense?')) {
      await dispatch(deleteAdExpense(id));
      dispatch(getAdExpenses({ adId }));
    }

    anchorEls[id] = null;
    setAnchorEl({
      ...anchorEls,
      [id]: null,
    });
  }, []);

  const handleMenuClick = useCallback(
    (id, e) => {
      e.preventDefault();
      e.stopPropagation();

      setAnchorEl({
        ...anchorEls,
        [id]: e.target,
      });
    },
    [anchorEls],
  );

  const handleMenuClose = useCallback(
    (id, e) => {
      e.preventDefault();
      e.stopPropagation();

      anchorEls[id] = null;
      setAnchorEl({
        ...anchorEls,
        [id]: null,
      });
    },
    [anchorEls],
  );

  const handleChangeRowsPerPage = (event) => {
    dispatch(setAdExpensesPageItems(parseInt(event.target.value, 10)));
    dispatch(setAdExpensesPage(1));
  };

  const handleChangePage = (event, newPage) => {
    if (newPage - 1 < pageNumber) {
      dispatch(setAdExpensesPage(newPage + 1));
    }

    if (newPage - 1 > pageNumber) {
      dispatch(setAdExpensesPage(newPage - 1));
    }
  };

  return (
    <>
      <Grid container xs={12}>
        <Grid item xs={10}>
          <Breadcrumbs links={breadcrumsRoutes} currentRoute={adData?.name} />
        </Grid>
        <Grid container xs={2} alignItems="flex-end" justifyContent="flex-end">
          <Button variant="contained" size="large" color="primary" type="button" className={classes.button} onClick={handleButtonPress}>
            Add
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={4} className={classes.main}>
        <Grid item xs={12}>
          <Widget title="Expenses" disableWidgetMenu noBodyPadding bodyClass={classes.tableTopOverflow}>
            <Grid item xs={12}>
              <TableContainer>
                <Table stickyHeader className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">
                        <Typography size="sm" color="text" colorBrightness="secondary">
                          Date
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography size="sm" color="text" colorBrightness="secondary">
                          Amount($)
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography size="sm" color="text" colorBrightness="secondary">
                          Comment
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography size="sm" color="text" colorBrightness="secondary">
                          Created At
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography size="sm" color="text" colorBrightness="secondary">
                          Updated At
                        </Typography>
                      </TableCell>
                      <TableCell align="right" />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableData.map((row, index) => (
                      <TableRow key={data[index]._id} hover>
                        <TableCell scope="row">
                          <Typography size="sm" color="text" colorBrightness="secondary">
                            {moment(row.date).format('YYYY-MM-DD')}
                          </Typography>
                        </TableCell>
                        <TableCell scope="row">
                          <Typography size="sm" color="text" colorBrightness="secondary">
                            {row.amount}
                          </Typography>
                        </TableCell>
                        <TableCell scope="row">
                          <Typography size="sm" color="text" colorBrightness="secondary">
                            {row.comment}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography size="sm" color="text" colorBrightness="secondary">
                            {moment(row.createdAt).format('YYYY-MM-DD h:mm:ss')}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography size="sm" color="text" colorBrightness="secondary">
                            {moment(row.updatedAt).format('YYYY-MM-DD h:mm:ss')}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <IconButton
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={(e) => handleMenuClick(data[index]._id, e)}
                          >
                            <MoreIcon />
                          </IconButton>
                          <Menu
                            id={data[index]._id}
                            anchorEl={anchorEls[data[index]._id]}
                            keepMounted
                            open={Boolean(anchorEls[data[index]._id])}
                            onClose={(e) => handleMenuClose(data[index]._id, e)}
                          >
                            <MenuItem onClick={(e) => handleAdExpenseEdit(data[index]._id, e)}>Edit</MenuItem>
                            <MenuItem onClick={(e) => handleAdExpenseRemove(data[index]._id, e)}>Remove</MenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={count}
                rowsPerPage={pageItemCount}
                page={pageNumber - 1}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />

              {isPending && (
                <div className={classes.loaderWrap}>
                  <div className={classes.loader}>
                    <CircularProgress size={26} />
                  </div>
                </div>
              )}
            </Grid>
          </Widget>
        </Grid>
      </Grid>
      {isOpenModal && (
        <Modal
          isOpen={isOpenModal}
          isPending={isPending}
          onClose={handleCloseModal}
          onSubmit={handleSubmit}
          adId={adId}
          selectedAdExpense={selectedAdExpense}
        />
      )}
    </>
  );
};

export default MarketingAd;

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  main: {
    marginTop: theme.spacing(2),
  },
  tableTopOverflow: {
    display: 'flex',
    flexDirection: 'column',
  },
  button: {},
  loaderWrap: {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255,255,255,0.7)',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 9,
  },
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));
