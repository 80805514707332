export const SET_PENDING = 'MARKETING/SET_PENDING';

export const SET_ADS_DATA = 'MARKETING/ADS/SET_DATA';
export const SET_ADS_PENDING = 'MARKETING/ADS/SET_PENDING';

export const SET_ADS_EXPENSES_PENDING = 'MARKETING/ADS/EXPENSES/SET_PENDING';
export const SET_ADS_EXPENSES_DATA = 'MARKETING/ADS/EXPENSES/SET_DATA';
export const SET_ADS_EXPENSES_PAGE_NUMBER = 'MARKETING/ADS/EXPENSES/SET_PAGE_NUMBER';
export const SET_ADS_EXPENSES_PAGE_ITEM_COUNT = 'MARKETING/ADS/EXPENSES/SET_PAGE_ITEM_COUNT';

export const SET_REPORTS_DATA = 'MARKETING/REPORTS/SET_DATA';
export const SET_REPORTS_PENDING = 'MARKETING/REPORTS/SET_PENDING';

export const SET_TIMELINE_REPORT_DATA = 'MARKETING/TIMELINE_REPORT/SET_DATA';
export const SET_TIMELINE_REPORT_PENDING = 'MARKETING/TIMELINE_REPORT/SET_PENDING';
