import React, { useState, useEffect, useMemo } from 'react';
import { AppBar, Toolbar, IconButton, InputBase, Menu, MenuItem, Fab, Select, OutlinedInput } from '@material-ui/core';
import {
  Menu as MenuIcon,
  // MailOutline as MailIcon,
  // NotificationsNone as NotificationsIcon,
  Person as AccountIcon,
  Queue as QueueIcon,
  Search as SearchIcon,
  Send as SendIcon,
  ArrowBack as ArrowBackIcon,
  Apple as AppleIcon,
  Android as AndroidIcon,
  Language as WebIcon,
  Info as InfoIcon,
} from '@material-ui/icons';

import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
// styles
import useStyles from './styles';

// components
import { Badge, Typography, Button } from '../Wrappers';
import Notification from '../Notification/Notification';
import UserAvatar from '../UserAvatar/UserAvatar';
import FormModal from '../Modal';
import { ROLES } from '../../constants/roles';
import { setSidebarOpen } from '../../store/app/actions';
import { signOut } from '../../store/auth/actions';
import { createProject, getProjects, getProject, updateProject, setEditProjectModalShow } from '../../store/projects/actions';

const messages = [
  {
    id: 0,
    variant: 'warning',
    name: 'Jane Hew',
    message: 'Hey! How is it going?',
    time: '9:32',
  },
  {
    id: 1,
    variant: 'success',
    name: 'Lloyd Brown',
    message: 'Check out my new Dashboard',
    time: '9:18',
  },
  {
    id: 2,
    variant: 'primary',
    name: 'Mark Winstein',
    message: 'I want rearrange the appointment',
    time: '9:15',
  },
  {
    id: 3,
    variant: 'secondary',
    name: 'Liana Dutti',
    message: 'Good news from sale department',
    time: '9:09',
  },
];

const notifications = [
  { id: 0, color: 'warning', message: 'Check out this awesome ticket' },
  {
    id: 1,
    color: 'success',
    type: 'info',
    message: 'What is the best way to get ...',
  },
  {
    id: 2,
    color: 'secondary',
    type: 'notification',
    message: 'This is just a simple notification',
  },
  {
    id: 3,
    color: 'primary',
    type: 'e-commerce',
    message: '12 new orders has arrived today',
  },
];

export default function Header() {
  const classes = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  // global
  const dispatch = useDispatch();
  const accountName = useSelector((state) => state.account.name);
  const isSidebarOpened = useSelector((state) => state.app.isSidebarOpened);
  const projects = useSelector((state) => state.projects.list);
  const isPending = useSelector((state) => state.projects.pending);
  const selectedProjectId = useSelector((state) => state.projects.selectedProjectId);
  const isEditModalShow = useSelector((state) => state.projects.isEditModalShow);
  const role = useSelector((state) => state.account.role);

  const selectedProject = useMemo(() => projects.find((p) => p._id === selectedProjectId), [selectedProjectId, projects]);

  // local
  const [mailMenu, setMailMenu] = useState(null);
  // const [isMailsUnread, setIsMailsUnread] = useState(true);
  const [notificationsMenu, setNotificationsMenu] = useState(null);
  // const [isNotificationsUnread, setIsNotificationsUnread] = useState(true);
  const [profileMenu, setProfileMenu] = useState(null);
  const [isSearchOpen, setSearchOpen] = useState(false);
  const [isOpenCreateProjectModal, setIsOpenCreateProjectModal] = useState(false);

  useEffect(() => {
    if (isEditModalShow) {
      setIsOpenCreateProjectModal(true);
    } else {
      setIsOpenCreateProjectModal(false);
    }
  }, [isEditModalShow]);

  useEffect(() => {
    dispatch(getProjects());
  }, []);

  const handleTabbarToggle = () => {
    dispatch(setSidebarOpen(!isSidebarOpened));
  };

  const handleSignOut = () => {
    dispatch(signOut());
  };

  const handleProjectChange = (e) => {
    dispatch(getProject(e.target.value));
  };

  const handleCreateNewProject = () => {
    if (isEditModalShow) {
      dispatch(setEditProjectModalShow(false));
    }
    setIsOpenCreateProjectModal(true);
  };

  const handleCloseModal = () => {
    setIsOpenCreateProjectModal(false);
    if (isEditModalShow) {
      dispatch(setEditProjectModalShow(false));
    }
  };

  const handleCreateNewProjectSubmit = async (data) => {
    try {
      if (isEditModalShow) {
        await dispatch(updateProject(data));
      } else {
        await dispatch(createProject(data));
      }
      await dispatch(getProjects());
      setIsOpenCreateProjectModal(false);
      dispatch(setEditProjectModalShow(false));
    } catch (error) {
      console.log('[ERROR PROJECT MODAL]', error);
    }
  };

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          color="inherit"
          onClick={handleTabbarToggle}
          className={classNames(classes.headerMenuButtonSandwich, classes.headerMenuButtonCollapse)}
        >
          {isSidebarOpened ? (
            <ArrowBackIcon
              classes={{
                root: classNames(classes.headerIcon, classes.headerIconCollapse),
              }}
            />
          ) : (
            <MenuIcon
              classes={{
                root: classNames(classes.headerIcon, classes.headerIconCollapse),
              }}
            />
          )}
        </IconButton>

        {!isSmall && (
          <div
            className={classNames(classes.search, {
              [classes.searchFocused]: isSearchOpen,
            })}
          >
            <div
              className={classNames(classes.searchIcon, {
                [classes.searchIconOpened]: isSearchOpen,
              })}
              onClick={() => setSearchOpen(!isSearchOpen)}
            >
              <SearchIcon classes={{ root: classes.headerIcon }} />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
            />
          </div>
        )}

        <div className={classes.grow} />
        {!!projects.length && (
          <Select
            value={selectedProjectId}
            onChange={handleProjectChange}
            input={
              <OutlinedInput
                labelWidth={0}
                classes={{
                  notchedOutline: classes.projectSelectRoot,
                  input: classes.projectSelect,
                }}
              />
            }
            autoWidth
          >
            {projects.map(({ name, _id, platform }) => (
              <MenuItem key={_id} value={_id} className={classes.projectMenuItem}>
                {platform === 'android' && <AndroidIcon className={classes.projectMenuIcon} />}
                {platform === 'ios' && <AppleIcon className={classes.projectMenuIcon} />}
                {platform === 'web' && <WebIcon className={classes.projectMenuIcon} />}
                {name}
              </MenuItem>
            ))}
          </Select>
        )}

        {role === ROLES.OWNER && (
          <IconButton
            color="inherit"
            aria-haspopup="true"
            aria-controls="profile-menu"
            onClick={handleCreateNewProject}
            className={classes.headerMenuNewButton}
          >
            <QueueIcon classes={{ root: classes.headerNewIcon }} />
          </IconButton>
        )}

        {/* <IconButton
          color="inherit"
          aria-haspopup="true"
          aria-controls="mail-menu"
          onClick={(e) => {
            setNotificationsMenu(e.currentTarget);
            setIsNotificationsUnread(false);
          }}
          className={classes.headerMenuButton}
        >
          <Badge badgeContent={isNotificationsUnread ? notifications.length : null} color="warning">
            <NotificationsIcon classes={{ root: classes.headerIcon }} />
          </Badge>
        </IconButton> */}
        {/* <IconButton
          color="inherit"
          aria-haspopup="true"
          aria-controls="mail-menu"
          onClick={(e) => {
            setMailMenu(e.currentTarget);
            setIsMailsUnread(false);
          }}
          className={classes.headerMenuButton}
        >
          <Badge badgeContent={isMailsUnread ? messages.length : null} color="secondary">
            <MailIcon classes={{ root: classes.headerIcon }} />
          </Badge>
        </IconButton> */}
        <IconButton
          aria-haspopup="true"
          color="inherit"
          className={classes.headerMenuButton}
          aria-controls="profile-menu"
          onClick={(e) => setProfileMenu(e.currentTarget)}
        >
          <AccountIcon classes={{ root: classes.headerIcon }} />
        </IconButton>
        <Menu
          id="mail-menu"
          open={Boolean(mailMenu)}
          anchorEl={mailMenu}
          onClose={() => setMailMenu(null)}
          MenuListProps={{ className: classes.headerMenuList }}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          <div className={classes.profileMenuUser}>
            <Typography variant="h4" weight="medium">
              New Messages
            </Typography>
            <Typography className={classes.profileMenuLink} component="a" color="secondary">
              {messages.length} New Messages
            </Typography>
          </div>
          {messages.map((message) => (
            <MenuItem key={message.id} className={classes.messageNotification}>
              <div className={classes.messageNotificationSide}>
                <UserAvatar color={message.variant} name={message.name} />
                <Typography size="sm" color="text" colorBrightness="secondary">
                  {message.time}
                </Typography>
              </div>
              <div className={classNames(classes.messageNotificationSide, classes.messageNotificationBodySide)}>
                <Typography weight="medium" gutterBottom>
                  {message.name}
                </Typography>
                <Typography color="text" colorBrightness="secondary">
                  {message.message}
                </Typography>
              </div>
            </MenuItem>
          ))}
          <Fab variant="extended" color="primary" aria-label="Add" className={classes.sendMessageButton}>
            Send New Message
            <SendIcon className={classes.sendButtonIcon} />
          </Fab>
        </Menu>
        <Menu
          id="notifications-menu"
          open={Boolean(notificationsMenu)}
          anchorEl={notificationsMenu}
          onClose={() => setNotificationsMenu(null)}
          className={classes.headerMenu}
          disableAutoFocusItem
        >
          {notifications.map((notification) => (
            <MenuItem key={notification.id} onClick={() => setNotificationsMenu(null)} className={classes.headerMenuItem}>
              <Notification {...notification} typographyVariant="inherit" />
            </MenuItem>
          ))}
        </Menu>
        <Menu
          id="profile-menu"
          open={Boolean(profileMenu)}
          anchorEl={profileMenu}
          onClose={() => setProfileMenu(null)}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          <div className={classes.profileMenuUser}>
            <Typography variant="h4" weight="medium">
              {accountName}
            </Typography>
          </div>
          <MenuItem className={classNames(classes.profileMenuItem, classes.headerMenuItem)}>
            <AccountIcon className={classes.profileMenuIcon} /> Profile
          </MenuItem>
          <MenuItem className={classNames(classes.profileMenuItem, classes.headerMenuItem)}>
            <AccountIcon className={classes.profileMenuIcon} /> Tasks
          </MenuItem>
          <MenuItem className={classNames(classes.profileMenuItem, classes.headerMenuItem)}>
            <AccountIcon className={classes.profileMenuIcon} /> Messages
          </MenuItem>
          <div className={classes.profileMenuUser}>
            <Typography className={classes.profileMenuLink} color="primary" onClick={handleSignOut}>
              Sign Out
            </Typography>
          </div>
        </Menu>
      </Toolbar>
      {isOpenCreateProjectModal && (
        <FormModal
          isEdit={isEditModalShow}
          selectedProject={selectedProject}
          isOpen={isOpenCreateProjectModal}
          onClose={handleCloseModal}
          onSubmit={handleCreateNewProjectSubmit}
          isPending={isPending}
        />
      )}
    </AppBar>
  );
}
