import React, { useCallback, useEffect, useState, useReducer, useRef } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import {
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import DateFnsUtils from '@date-io/moment';
import Button from '@material-ui/core/Button';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { getInstalls, getInstallsFilters, setInstallsPage, setInstallsPageItems } from '../../../store/evtrack/actions';
// components
import Widget from '../../../components/Widget';
import { Typography } from '../../../components/Wrappers';
import ApexLineChart from '../../../components/Charts/ApexLineChart';
import { ROUTES, NAMES, IDS } from '../../../constants/routes';
import Breadcrumbs from '../../../components/Breadcrumbs';
import withRouter from '../../../hooks/with-router';
import { formatDate } from '../../../utils/date';

const breadcrumsRoutes = [
  {
    route: ROUTES[IDS.EVTRACK],
    name: NAMES[IDS.EVTRACK],
  },
];

const FROM_DATE = formatDate(moment().subtract(1, 'months'));
const TO_DATE = formatDate();

const headCells = [
  { id: 'id', numeric: false, disablePadding: true, label: 'Id' },
  { id: 'appVersion', numeric: false, disablePadding: false, label: 'App Version' },
  { id: 'osVersion', numeric: false, disablePadding: false, label: 'OS Version' },
  { id: 'afStatus', numeric: false, disablePadding: false, label: 'Af Status' },
  { id: 'adName', numeric: false, disablePadding: false, label: 'Ad Name' },
  { id: 'adsetName', numeric: false, disablePadding: false, label: 'Adset Name' },
  { id: 'mediaSource', numeric: false, disablePadding: false, label: 'Media Source' },
  { id: 'createdAt', numeric: false, disablePadding: false, label: 'Created At' },
  { id: 'updatedAt', numeric: false, disablePadding: false, label: 'Updated At' },
];

const formReducer = (state, action) => {
  const { name, value } = action;

  switch (action.type) {
    case 'SET':
      return {
        ...state,
        [name]: {
          ...state[name],
          value,
        },
      };
    case 'RESET':
      return {};
    default:
      throw new Error(`Unhandled expression in switch: ${action.type}`);
  }
};

const Installs = () => {
  const navigate = useNavigate();
  const isFieldsRendered = useRef(false);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const classes = useStyles();
  const [valueFrom, setValueFrom] = useState(FROM_DATE);
  const [valueTo, setValueTo] = useState(TO_DATE);
  const [fields, setFormData] = useReducer(formReducer, {});
  // global
  const dispatch = useDispatch();
  const isPending = useSelector((state) => state.evtrack.pending);
  const installs = useSelector((state) => state.evtrack.installs.data);
  const chartData = useSelector((state) => state.evtrack.installs.chartData);
  const count = useSelector((state) => state.evtrack.installs.count);
  const fieldsData = useSelector((state) => state.evtrack.installs.fields);
  const pageNumber = useSelector((state) => state.evtrack.installs.pageNumber);
  const pageItemCount = useSelector((state) => state.evtrack.installs.pageItemCount);
  const selectedProjectId = useSelector((state) => state.projects.selectedProjectId);

  useEffect(() => {
    if (!isFieldsRendered.current && !_.isEmpty(fieldsData)) {
      isFieldsRendered.current = true;
      Object.keys(fieldsData).map((key) => {
        setFormData({
          type: 'SET',
          name: key,
          value: '',
        });
        return key;
      });
    }
  }, [fieldsData]);

  useEffect(() => {
    dispatch(getInstallsFilters());
  }, []);

  const getInstallsByQueries = () => {
    const valuesFields = Object.keys(fields).reduce((acc, key) => {
      if (fields[key].value) {
        return {
          ...acc,
          [key]: fields[key].value,
        };
      }

      return acc;
    }, {});

    dispatch(getInstalls({ dateFrom: valueFrom, dateTo: valueTo, ...valuesFields }));
  };

  useEffect(() => {
    isFieldsRendered.current = false;
    setFormData({
      type: 'RESET',
    });
  }, [selectedProjectId]);

  useEffect(() => {
    getInstallsByQueries();
  }, [pageNumber, pageItemCount, selectedProjectId]);

  const handleChangeRowsPerPage = (event) => {
    dispatch(setInstallsPageItems(parseInt(event.target.value, 10)));
    dispatch(setInstallsPage(1));
  };

  const handleChangePage = (event, newPage) => {
    if (newPage - 1 < pageNumber) {
      dispatch(setInstallsPage(newPage + 1));
    }

    if (newPage - 1 > pageNumber) {
      dispatch(setInstallsPage(newPage - 1));
    }
  };

  const handleButtonPress = () => {
    getInstallsByQueries();
  };

  const handleButtonResetPress = () => {
    setFormData({
      type: 'RESET',
    });

    isFieldsRendered.current = false;

    setValueFrom(FROM_DATE);
    setValueTo(TO_DATE);
    dispatch(getInstalls({ dateFrom: FROM_DATE, dateTo: TO_DATE }));
  };

  const handleFromChange = (newValue) => {
    const date = formatDate(newValue);

    setValueFrom(date);
  };

  const handleToChange = (newValue) => {
    const date = formatDate(newValue);

    setValueTo(date);
  };

  const handleRowClick = useCallback((data) => {
    window.open(`${ROUTES[IDS.USERS]}/${data.customer}`);
    // navigate(`${ROUTES[IDS.USERS]}/${data.customer._id}`);
  }, []);

  const handleChange = (e) => {
    e.preventDefault();
    const { value } = e.target;
    const { name } = e.target;

    setFormData({
      type: 'SET',
      name,
      value,
    });
  };

  const renderField = (key, attributes) => {
    const { value } = attributes;

    return (
      <Grid item xs={12} key={key} className={classes.fieldWrap}>
        <FormControl className={classes.formControl} variant="outlined">
          <InputLabel>{key}</InputLabel>
          <Select
            className={classes.field}
            label={key}
            name={key}
            variant="outlined"
            value={value}
            id={key}
            onChange={handleChange}
            autoWidth
          >
            {fieldsData[key].map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    );
  };

  const renderFields = () => (
    <Grid item xs={12} className={classes.form}>
      {Object.keys(fields).map((key) => renderField(key, fields[key]))}
    </Grid>
  );

  const renderFilters = () => {
    if (!isSmall) {
      return renderFields();
    }

    return (
      <Accordion className={classes.accordion}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography className={classes.heading}>Filters</Typography>
        </AccordionSummary>
        <AccordionDetails>{renderFields()}</AccordionDetails>
      </Accordion>
    );
  };

  return (
    <>
      <Breadcrumbs links={breadcrumsRoutes} currentRoute={NAMES[IDS.INSTALLS]} />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget disableWidgetMenu bodyClass={classes.tableTopOverflow}>
            <Grid container xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} className={classes.datepicker}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="DD/MM/YYYY"
                  margin="none"
                  id="date-picker-inline"
                  label="Date From"
                  value={moment(valueFrom, 'YYYY-MM-DD').toDate()}
                  onChange={handleFromChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  className={classes.datepickerLeft}
                />
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="DD/MM/YYYY"
                  margin="none"
                  id="date-picker-inline"
                  label="Date To"
                  value={moment(valueTo, 'YYYY-MM-DD').toDate()}
                  onChange={handleToChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  className={classes.datepickerRight}
                />
              </MuiPickersUtilsProvider>
            </Grid>

            {renderFilters()}

            <Grid container xs={12} className={classes.buttons}>
              <Button variant="contained" size="large" color="primary" type="button" className={classes.button} onClick={handleButtonPress}>
                Apply
              </Button>
              <Button
                variant="contained"
                size="large"
                color="secondary"
                type="button"
                className={classes.buttonReset}
                onClick={handleButtonResetPress}
              >
                Reset
              </Button>
            </Grid>
          </Widget>
        </Grid>

        <Grid item xs={12}>
          <Widget noBodyPadding disableWidgetMenu bodyClass={classes.tableOverflow}>
            <ApexLineChart
              data={{
                series: [
                  {
                    name: 'count',
                    data: Object.values(chartData),
                  },
                ],
                categories: Object.keys(chartData),
              }}
            />
            {isPending && (
              <div className={classes.loaderWrap}>
                <div className={classes.loader}>
                  <CircularProgress size={26} />
                </div>
              </div>
            )}
          </Widget>
        </Grid>

        <Grid item xs={12}>
          <Widget disableWidgetMenu noBodyPadding noHeaderPadding bodyClass={classes.tableOverflow}>
            <TableContainer>
              <Table stickyHeader className={classes.table} aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell key={headCell.id} align="left">
                        <TableSortLabel>
                          <Typography size="sm" color="text" colorBrightness="secondary">
                            {headCell.label}
                          </Typography>
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {installs.map((row) => (
                    <TableRow hover tabIndex={-1} key={row._id} onClick={() => handleRowClick(row)}>
                      <TableCell component="th" scope="row">
                        <Typography size="sm" color="text" colorBrightness="secondary">
                          {row._id}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography size="sm" color="text" colorBrightness="secondary">
                          {row.appVersion}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography size="sm" color="text" colorBrightness="secondary">
                          {row.osVersion}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography size="sm" color="text" colorBrightness="secondary">
                          {row?.afStatus}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography size="sm" color="text" colorBrightness="secondary">
                          {row?.afAd || row?.adName}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography size="sm" color="text" colorBrightness="secondary">
                          {row?.afAdset || row?.adsetName}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography size="sm" color="text" colorBrightness="secondary">
                          {row?.mediaSource}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography size="sm" color="text" colorBrightness="secondary">
                          {moment(row.createdAt).format('YYYY-MM-DD h:mm:ss')}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography size="sm" color="text" colorBrightness="secondary">
                          {moment(row.updatedAt).format('YYYY-MM-DD h:mm:ss')}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              component="div"
              count={count}
              rowsPerPage={pageItemCount}
              page={pageNumber - 1}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            {isPending && (
              <div className={classes.loaderWrap}>
                <div className={classes.loader}>
                  <CircularProgress size={26} />
                </div>
              </div>
            )}
          </Widget>
        </Grid>
      </Grid>
    </>
  );
};

export default withRouter(Installs);

const useStyles = makeStyles((theme) => ({
  tableOverflow: {
    position: 'relative',
  },
  root: {
    width: '100%',
  },
  accordion: {
    width: '100%',
    marginTop: theme.spacing(2),
    '&::before': {
      opacity: 0,
    },
  },
  tableTopOverflow: {
    display: 'flex',
    flexDirection: 'column',
  },
  datepickerLeft: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  datepickerRight: {
    marginLeft: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      marginLeft: 0,
      width: '100%',
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      flexDirection: 'column',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
    },
  },
  fieldWrap: {
    display: 'flex',
    width: '100%',
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      marginBottom: theme.spacing(2),
    },
  },
  formControl: {
    display: 'flex',
    width: '100%',
  },
  field: {
    width: '100%',
  },
  buttons: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  button: {},
  buttonReset: {
    marginLeft: theme.spacing(2),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  loaderWrap: {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255,255,255,0.7)',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 9,
  },
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContainer: {
    backgroundColor: '#fff',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxWidth: '70vw',
    minWidth: '70vw',
    minHeight: 400,
    maxHeight: '70vh',
    overflow: 'scroll',
  },
}));
