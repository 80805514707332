import http from '../utils/http';

const PATH = '/marketing';

export const getAds = (query) => http.get(`${PATH}/ads?${query}`);
export const createAd = (data) =>
  http.post(`${PATH}/ads`, {
    ...data,
  });
export const updateAd = (data) =>
  http.put(`${PATH}/ads`, {
    ...data,
  });
export const deleteAd = (_id) => http.delete(`${PATH}/ads`, { data: { _id } });

export const getAdExpenses = (query) => http.get(`${PATH}/ads/expenses?${query}`);
export const createAdExpense = (data) =>
  http.post(`${PATH}/ads/expenses`, {
    ...data,
  });
export const updateAdExpense = (data) =>
  http.put(`${PATH}/ads/expenses`, {
    ...data,
  });
export const deleteAdExpense = (_id) => http.delete(`${PATH}/ads/expenses`, { data: { _id } });

export const getReports = (query) => http.get(`${PATH}/reports?${query}`);
export const getTimelineReport = (query) => http.get(`${PATH}/reports/timeline?${query}`);
