import React, { useState, useEffect, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { getProducts, createProduct, updateProduct, deleteProduct } from '../../../store/projects/actions';
// components
import Widget from '../../../components/Widget/Widget';
import Table from '../../../components/Table';
import { ROUTES, NAMES, IDS } from '../../../constants/routes';
import { ROLES } from '../../../constants/roles';
import Breadcrumbs from '../../../components/Breadcrumbs';
import Modal from './modal';

const breadcrumsRoutes = [
  {
    route: ROUTES[IDS.APP],
    name: NAMES[IDS.APP],
  },
];

const headCells = [
  { id: 'id', numeric: false, disablePadding: true, label: 'Id' },
  { id: 'code', numeric: false, disablePadding: false, label: 'Code' },
  { id: 'currencyCode', numeric: false, disablePadding: false, label: 'Currency Code' },
  { id: 'trial', numeric: false, disablePadding: false, label: 'Trial' },
  { id: 'period', numeric: false, disablePadding: false, label: 'Period' },
  { id: 'createdAt', numeric: false, disablePadding: false, label: 'Created At' },
  { id: 'updatedAt', numeric: false, disablePadding: false, label: 'Updated At' },
];

const Products = () => {
  const theme = useTheme();
  const role = useSelector((state) => state.account.role);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const classes = useStyles();

  // global
  const dispatch = useDispatch();
  const selectedProjectId = useSelector((state) => state.projects.selectedProjectId);
  const isPending = useSelector((state) => state.projects.products.pending);
  const data = useSelector((state) => state.projects.products.data);
  const count = useSelector((state) => state.projects.products.count);

  const tableData = useMemo(
    () =>
      data.reduce((acc, i) => {
        const d = _.pick(i, ['_id', 'code', 'currencyCode', 'trial', 'period', 'createdAt', 'updatedAt']);

        return [...acc, d];
      }, []),
    [data],
  );

  useEffect(() => {
    dispatch(getProducts());
  }, [selectedProjectId]);

  const handleButtonPress = () => {
    setIsOpenModal(true);
  };

  const handleCloseModal = () => {
    setSelectedProduct(null);
    setIsOpenModal(false);
  };

  const handleSubmit = async (body) => {
    try {
      if (!selectedProduct) {
        await dispatch(createProduct(body));
      } else {
        await dispatch(updateProduct({ id: selectedProduct._id, ...body }));
      }

      setIsOpenModal(false);
      setSelectedProduct(null);
    } catch (error) {
      console.log('[CREATE PRODUCT MODAL ERROR]', error);
    }
  };

  const handleRemove = async () => {
    try {
      console.log(selectedProduct._id);
      await dispatch(deleteProduct(selectedProduct._id));

      setIsOpenModal(false);
      setSelectedProduct(null);
    } catch (error) {
      console.log('[REMOVE PRODUCT MODAL ERROR]', error);
    }
  };

  const handleRowClick = (product) => {
    setSelectedProduct(product);
    setIsOpenModal(true);
  };

  return (
    <>
      <Grid container xs={12}>
        <Grid item xs={10}>
          <Breadcrumbs links={breadcrumsRoutes} currentRoute={NAMES[IDS.PRODUCTS]} />
        </Grid>
        {role === ROLES.OWNER && (
          <Grid container xs={2} alignItems="flex-end" justifyContent="flex-end">
            <Button variant="contained" size="large" color="primary" type="button" className={classes.button} onClick={handleButtonPress}>
              Create
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={4} className={classes.main}>
        <Grid item xs={12}>
          <Widget disableWidgetMenu noBodyPadding noHeaderPadding bodyClass={classes.tableTopOverflow}>
            <Grid item xs={12}>
              <Table
                headCells={headCells}
                rows={tableData}
                count={count}
                order="asc"
                orderBy="createdAt"
                page={0}
                rowsPerPage={100}
                onRowClick={handleRowClick}
              />
              {isPending && (
                <div className={classes.loaderWrap}>
                  <div className={classes.loader}>
                    <CircularProgress size={26} />
                  </div>
                </div>
              )}
            </Grid>
          </Widget>
        </Grid>
      </Grid>

      {isOpenModal && (
        <Modal
          selectedProduct={selectedProduct}
          isEdit={!!selectedProduct}
          isOpen={isOpenModal}
          isPending={isPending}
          onClose={handleCloseModal}
          onSubmit={handleSubmit}
          onRemove={handleRemove}
        />
      )}
    </>
  );
};

export default Products;

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  main: {
    marginTop: theme.spacing(2),
  },
  tableTopOverflow: {
    display: 'flex',
    flexDirection: 'column',
  },
  button: {},
  loaderWrap: {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255,255,255,0.7)',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 9,
  },
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));
