import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import IconButton from '@material-ui/core/IconButton';

import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { Close as CloseIcon } from '@material-ui/icons';

import Widget from '../../../../components/Widget/Widget';
import { Typography } from '../../../../components/Wrappers/Wrappers';
import LinkCopyButton from '../../../../components/LinkCopyButton';
import { uploadToS3 } from '../../../../utils/aws';

export default function UploadToS3() {
  const classes = useStyles();
  const [selectedFiles, setSelectedFiles] = useState([]);

  const onDrop = (acceptedFiles) => {
    const files = acceptedFiles.reduce((acc, file) => {
      const exists = selectedFiles.find((f) => f.id === file.name);

      if (!exists) {
        acc.push({
          isLoading: false,
          isUploaded: false,
          isError: false,
          url: '',
          id: file.name,
          data: file,
        });
      } else {
        alert(`File already added`);
      }
      return acc;
    }, []);

    setSelectedFiles([...selectedFiles, ...files]);
  };

  const handleUploadButtonClick = async (e, file) => {
    e.preventDefault();

    setSelectedFiles((prevState) => {
      const newState = prevState.map((f) => {
        if (f.id === file.id) {
          return {
            ...f,
            isLoading: true,
            isUploaded: false,
            isError: false,
            url: '',
          };
        }

        return f;
      });

      return newState;
    });

    try {
      // const promiseTimeout = new Promise((res) => {
      //   setTimeout(() => res(`https://google.com?=${file.id}`), 2000);
      // });
      // const url = await promiseTimeout;

      const url = await uploadToS3(file.data);

      setSelectedFiles((prevState) => {
        const newState = prevState.map((f) => {
          if (f.id === file.id) {
            return {
              ...f,
              isLoading: false,
              isUploaded: true,
              isError: false,
              url,
            };
          }

          return f;
        });

        return newState;
      });
    } catch (error) {
      console.log('[ERROR UPLOAD FILE]: ', error);

      setSelectedFiles((prevState) => {
        const newState = prevState.map((f) => {
          if (f.id === file.id) {
            return {
              ...f,
              isLoading: false,
              isUploaded: false,
              isError: true,
              url: '',
            };
          }

          return f;
        });

        return newState;
      });
    }
  };

  const handleCloseButtonClick = (e, file) => {
    e.preventDefault();
    setSelectedFiles(selectedFiles.filter((f) => f.id !== file.id));
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <Grid container xs={12} className={classes.header} alignItems="center" justifyContent="space-between">
      <Grid item xs={12} className={classes.container}>
        <Typography variant="h5" color="textSecondary" noWrap className={classes.title}>
          Upload File to S3
        </Typography>
      </Grid>

      <Grid item xs={6}>
        <div {...getRootProps()} className={classes.dropzone}>
          <input {...getInputProps()} />
          {isDragActive ? <p>Drop the files here ...</p> : <p>Drag `n drop some files here, or click to select files</p>}
        </div>
      </Grid>

      <Grid container spacing={4} className={classes.main}>
        <Grid item xs={12}>
          <Widget disableWidgetMenu noBodyPadding noHeaderPadding bodyClass={classes.tableTopOverflow}>
            <Grid item xs={12}>
              <TableContainer>
                <Table stickyHeader className={classes.table}>
                  <TableBody>
                    {selectedFiles.map((file, index) => (
                      <TableRow key={file.id} hover>
                        <TableCell scope="row">
                          <Typography size="sm" color="text" colorBrightness="secondary">
                            {file.id}
                          </Typography>
                        </TableCell>
                        <TableCell scope="row">
                          <Typography size="sm" color="text" colorBrightness="secondary">
                            {file.data.type}
                          </Typography>
                        </TableCell>
                        <TableCell scope="row">
                          <Typography size="sm" color="text" colorBrightness="secondary">
                            {Math.round(file.data.size / 10) / 100} Kb
                          </Typography>
                        </TableCell>
                        <TableCell scope="row" align="right">
                          <Grid container alignItems="center" justifyContent="flex-end">
                            {file.isError && (
                              <Typography size="sm" color="secondary" colorBrightness="main" className={classes.errorText}>
                                ERROR
                              </Typography>
                            )}
                            {!file.isUploaded ? (
                              <Button
                                variant="contained"
                                size="large"
                                color="primary"
                                type="button"
                                disabled={file.isLoading}
                                className={classes.cellButtons}
                                onClick={(e) => handleUploadButtonClick(e, file)}
                              >
                                Upload
                                {file.isLoading && (
                                  <div className={classes.loaderWrap}>
                                    <div className={classes.loader}>
                                      <CircularProgress size={26} />
                                    </div>
                                  </div>
                                )}
                              </Button>
                            ) : (
                              <LinkCopyButton link={file.url} />
                            )}
                          </Grid>
                        </TableCell>
                        <TableCell align="right">
                          <IconButton
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={(e) => handleCloseButtonClick(e, file)}
                          >
                            <CloseIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Widget>
        </Grid>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    marginBottom: theme.spacing(3),
  },
  dropzone: {
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff',
    border: `2px dotted ${theme.palette.primary.main}`,
    borderRadius: 15,
    padding: theme.spacing(4),
  },
  main: {
    marginTop: theme.spacing(3),
  },
  errorText: {
    marginRight: 20,
    fontWeight: 'bold',
  },
  cellButtons: {
    position: 'relative',
  },
  loaderWrap: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 4,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loader: {},
}));
