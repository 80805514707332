import React, { useCallback, useEffect, useState, useReducer, useRef } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { useNavigate, useSearchParams } from 'react-router-dom';

import queryString from 'query-string';
import { Grid, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

// components
import Widget from '../../../components/Widget';
import { Typography } from '../../../components/Wrappers';
import ApexLineChart from '../../../components/Charts/ApexLineChart';
import { ROUTES, NAMES, IDS } from '../../../constants/routes';
import Breadcrumbs from '../../../components/Breadcrumbs';
import withRouter from '../../../hooks/with-router';
import { formatDate } from '../../../utils/date';
import * as utilsApi from '../../../api/utils';
import { getQueryParams } from '../../../utils/query-string';

const breadcrumsRoutes = [
  {
    route: ROUTES[IDS.UTILS],
    name: NAMES[IDS.UTILS],
  },
];

const FROM_DATE = formatDate(moment().subtract(1, 'months'));
const TO_DATE = formatDate(moment().add(1, 'days'));

const headCells = [
  { id: 'id', numeric: false, disablePadding: true, label: 'Id' },
  { id: 'appVersion', numeric: false, disablePadding: false, label: 'App Version' },
  { id: 'osVersion', numeric: false, disablePadding: false, label: 'OS Version' },
  { id: 'afStatus', numeric: false, disablePadding: false, label: 'Af Status' },
  { id: 'adName', numeric: false, disablePadding: false, label: 'Ad Name' },
  { id: 'adsetName', numeric: false, disablePadding: false, label: 'Adset Name' },
  { id: 'mediaSource', numeric: false, disablePadding: false, label: 'Media Source' },
  { id: 'createdAt', numeric: false, disablePadding: false, label: 'Created At' },
  { id: 'updatedAt', numeric: false, disablePadding: false, label: 'Updated At' },
];

const Devices = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const classes = useStyles();
  const queryParams = getQueryParams();
  const [valueDateFrom, setValueDateFrom] = useState(queryParams && queryParams.dateFrom ? queryParams.dateFrom : FROM_DATE);
  const [valueDateTo, setValueDateTo] = useState(queryParams && queryParams.dateTo ? queryParams.dateTo : TO_DATE);
  const [deviceModel, setDeviceModel] = useState(queryParams && queryParams.deviceModel ? queryParams.deviceModel : 'iPhone12ProMax');
  const [data, setData] = useState([]);
  const [chartData, setChartData] = useState({});
  // global
  const dispatch = useDispatch();

  const fetchDevices = async () => {
    try {
      const d = await utilsApi.getDevices({
        dateFrom: valueDateFrom,
        dateTo: valueDateTo,
        deviceModel,
      });
      console.log('d', d);
      setData(d.all);
      setChartData(d.chartData);
    } catch (error) {
      console.log('[FETCH DEVICES ERROR]:', error);
    }
  };

  useEffect(() => {
    fetchDevices();
  }, []);

  // const handleRowClick = useCallback((data) => {
  //   window.open(`${ROUTES[IDS.USERS]}/${data.customer}`);
  //   // navigate(`${ROUTES[IDS.USERS]}/${data.customer._id}`);
  // }, []);

  return (
    <>
      <Breadcrumbs links={breadcrumsRoutes} currentRoute={NAMES[IDS.UTILS_DEVICES]} />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget noBodyPadding disableWidgetMenu bodyClass={classes.tableOverflow}>
            <ApexLineChart
              data={{
                series: [
                  {
                    name: 'count',
                    data: Object.values(chartData),
                  },
                ],
                categories: Object.keys(chartData),
              }}
            />
          </Widget>
        </Grid>

        <Grid item xs={12}>
          <Widget disableWidgetMenu noBodyPadding noHeaderPadding bodyClass={classes.tableOverflow}>
            <TableContainer>
              <Table stickyHeader className={classes.table} aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell key={headCell.id} align="left">
                        <TableSortLabel>
                          <Typography size="sm" color="text" colorBrightness="secondary">
                            {headCell.label}
                          </Typography>
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* {installs.map((row) => (
                    <TableRow hover tabIndex={-1} key={row._id} onClick={() => handleRowClick(row)}>
                      <TableCell component="th" scope="row">
                        <Typography size="sm" color="text" colorBrightness="secondary">
                          {row._id}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography size="sm" color="text" colorBrightness="secondary">
                          {row.appVersion}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography size="sm" color="text" colorBrightness="secondary">
                          {row.osVersion}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography size="sm" color="text" colorBrightness="secondary">
                          {row?.afStatus}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography size="sm" color="text" colorBrightness="secondary">
                          {row?.afAd || row?.adName}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography size="sm" color="text" colorBrightness="secondary">
                          {row?.afAdset || row?.adsetName}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography size="sm" color="text" colorBrightness="secondary">
                          {row?.mediaSource}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography size="sm" color="text" colorBrightness="secondary">
                          {moment(row.createdAt).format('YYYY-MM-DD h:mm:ss')}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography size="sm" color="text" colorBrightness="secondary">
                          {moment(row.updatedAt).format('YYYY-MM-DD h:mm:ss')}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))} */}
                </TableBody>
              </Table>
            </TableContainer>
          </Widget>
        </Grid>
      </Grid>
    </>
  );
};

export default withRouter(Devices);

const useStyles = makeStyles((theme) => ({
  tableOverflow: {
    position: 'relative',
  },
  root: {
    width: '100%',
  },
  accordion: {
    width: '100%',
    marginTop: theme.spacing(2),
    '&::before': {
      opacity: 0,
    },
  },
  tableTopOverflow: {
    display: 'flex',
    flexDirection: 'column',
  },
  datepickerLeft: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  datepickerRight: {
    marginLeft: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      marginLeft: 0,
      width: '100%',
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      flexDirection: 'column',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
    },
  },
  fieldWrap: {
    display: 'flex',
    width: '100%',
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      marginBottom: theme.spacing(2),
    },
  },
  formControl: {
    display: 'flex',
    width: '100%',
  },
  field: {
    width: '100%',
  },
  buttons: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  button: {},
  buttonReset: {
    marginLeft: theme.spacing(2),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  loaderWrap: {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255,255,255,0.7)',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 9,
  },
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContainer: {
    backgroundColor: '#fff',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxWidth: '70vw',
    minWidth: '70vw',
    minHeight: 400,
    maxHeight: '70vh',
    overflow: 'scroll',
  },
}));
