export const SET_TRANSACTIONS_PAGE_NUMBER = 'SUBTRACK/TRANSACTIONS/SET_PAGE_NUMBER';
export const SET_TRANSACTIONS_PAGE_ITEM_COUNT = 'SUBTRACK/TRANSACTIONS/SET_PAGE_ITEM_COUNT';

export const SET_TRANSACTIONS_DATA = 'SUBTRACK/TRANSACTIONS/SET_DATA';
export const SET_TRANSACTIONS_FILTERS = 'SUBTRACK/TRANSACTIONS/SET_FILTERS';
export const SET_TRANSACTIONS_FILTERS_PENDING = 'SUBTRACK/TRANSACTIONS/SET_FILTERS_PENDING';

export const SET_PENDING = 'SUBTRACK/SET_PENDING';
export const SET_ACTIVE_TRANSACTIONS_DATA = 'SUBTRACK/TRANSACTIONS/SET_ACTIVE_DATA';
export const SET_ALL_TRANSACTIONS_DATA = 'SUBTRACK/TRANSACTIONS/SET_ALL_DATA';
export const SET_TRANSACTION_DATA = 'SUBTRACK/SET_TRANSACTION_DATA';

export const SET_REVENUES_DATA = 'SUBTRACK/REVENUES/SET_DATA';
export const SET_ALL_REVENUES_DATA = 'SUBTRACK/REVENUES/SET_ALL_DATA';
export const SET_REVENUES_PAGE_NUMBER = 'SUBTRACK/REVENUES/SET_PAGE_NUMBER';
export const SET_REVENUES_PAGE_ITEM_COUNT = 'SUBTRACK/REVENUES/SET_PAGE_ITEM_COUNT';
export const SET_REVENUES_PENDING = 'SUBTRACK/REVENUES/SET_PENDING';
export const SET_REVENUES_FILTERS = 'SUBTRACK/REVENUES/SET_FILTERS';
export const SET_REVENUES_FILTERS_PENDING = 'SUBTRACK/REVENUES/SET_FILTERS_PENDING';
export const SET_REVENUES_CHART_DATA = 'SUBTRACK/REVENUES/SET_CHART_DATA';
export const SET_REVENUES_CHART_DATA_PENDING = 'SUBTRACK/REVENUES/SET_CHART_DATA_PENDING';

export const SET_CHARTS_DATA = 'SUBTRACK/CHARTS/SET_DATA';
export const SET_CHARTS_PENDING = 'SUBTRACK/CHARTS/SET_PENDING';
