import React, { useState } from 'react';
import { Grid, CircularProgress, Typography, Button, TextField, Fade } from '@material-ui/core';

import { useDispatch, useSelector } from 'react-redux';
import { auth } from '../../store/auth/actions';

// styles
import useStyles from './styles';

// logo
import logo from './logo.svg';

const Login = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.auth.pending);
  const error = useSelector((state) => state.auth.error);

  // local
  const [loginValue, setLoginValue] = useState('');
  const [passwordValue, setPasswordValue] = useState('');

  const handleSingin = () => {
    dispatch(auth(loginValue, passwordValue));
  };

  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}>
        <img src={logo} alt="logo" className={classes.logotypeImage} />
        <Typography className={classes.logotypeText}>Metrix Admin</Typography>
      </div>
      <div className={classes.formContainer}>
        <div className={classes.form}>
          <Typography variant="h1" className={classes.greeting}>
            Hi, User!
          </Typography>
          <Fade in={error}>
            <Typography color="secondary" className={classes.errorMessage}>
              Something is wrong with your login or password :(
            </Typography>
          </Fade>
          <TextField
            id="email"
            InputProps={{
              classes: {
                underline: classes.textFieldUnderline,
                input: classes.textField,
              },
            }}
            value={loginValue}
            onChange={(e) => setLoginValue(e.target.value)}
            margin="normal"
            placeholder="Email Adress"
            type="email"
            fullWidth
          />
          <TextField
            id="password"
            InputProps={{
              classes: {
                underline: classes.textFieldUnderline,
                input: classes.textField,
              },
            }}
            value={passwordValue}
            onChange={(e) => setPasswordValue(e.target.value)}
            margin="normal"
            placeholder="Password"
            type="password"
            fullWidth
          />
          <div className={classes.formButtons}>
            {isLoading ? (
              <CircularProgress size={26} className={classes.loginLoader} />
            ) : (
              <Button
                disabled={loginValue.length === 0 || passwordValue.length === 0}
                onClick={handleSingin}
                variant="contained"
                color="primary"
                size="large"
              >
                Login
              </Button>
            )}
            <Button color="primary" size="large" className={classes.forgetButton}>
              Forget Password
            </Button>
          </div>
        </div>
        <Typography color="primary" className={classes.copyright}>
          © 2014-{new Date().getFullYear()}{' '}
          <a
            style={{
              textDecoration: 'none',
              color: 'inherit',
            }}
            href="https://metrix.work"
            rel="noopener noreferrer"
            target="_blank"
          >
            Metrix
          </a>{' '}
          LLC. All rights reserved.
        </Typography>
      </div>
    </Grid>
  );
};

export default Login;
