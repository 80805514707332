import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import queryString from 'query-string';

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

import { IDS } from '../../../../constants/routes';
import { getQueryParams } from '../../../../utils/query-string';

import { getCharts, createChart, updateChart } from '../../../../store/subtrack/actions';

export default function ModalForm({ isOpen, onClose, onSubmit, route }) {
  const classes = useStyles();
  const params = getQueryParams();
  const dispatch = useDispatch();
  const selectedProjectId = useSelector((state) => state.projects.selectedProjectId);
  const isPending = useSelector((state) => state.subtrack.charts.pending);
  const data = useSelector((state) => state.subtrack.charts.data);
  const [value, setValue] = useState('');

  const selectedChartData = useMemo(
    () => params.chart && !_.isEmpty(data) && data.find((c) => c.section === IDS.SUBTRACK && c._id === params.chart),
    [params, data],
  );

  useEffect(() => {
    if (!_.isEmpty(selectedChartData)) {
      setValue(_.get(selectedChartData, ['name']));
    }
  }, [selectedChartData]);

  useEffect(() => {
    if (!isOpen) {
      setValue('');
    }
  }, [isOpen]);

  useEffect(() => {
    dispatch(getCharts());
  }, [selectedProjectId]);

  const handleChange = (e) => {
    e.preventDefault();
    setValue(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (value.length > 0) {
      const p = queryString.stringify(_.omit(params, ['chart']));
      try {
        if (_.isEmpty(selectedChartData)) {
          await dispatch(createChart({ name: value, section: IDS.SUBTRACK, route, params: p }));
        } else {
          await dispatch(updateChart({ _id: selectedChartData._id, name: value, params: p }));
        }
        onSubmit({ name: value });
      } catch (error) {
        console.log('[CREATE CHART MODAL ERROR]', error);
      }
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isOpen}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <div className={classes.container}>
          {isPending ? (
            <CircularProgress size={26} className={classes.loader} />
          ) : (
            <>
              <div className={classes.header}>
                <h2 className={classes.title}>{!_.isEmpty(selectedChartData) ? 'Edit Chart' : 'Create Chart'}</h2>
              </div>
              <form className={classes.form} noValidate autoComplete="off" onSubmit={handleSubmit}>
                <TextField
                  placeholder="Enter Chart Name"
                  name="name"
                  label="name"
                  value={value}
                  onChange={handleChange}
                  variant="outlined"
                />

                <Button variant="contained" size="large" color="primary" type="submit">
                  Submit
                </Button>
              </form>
            </>
          )}
        </div>
      </Fade>
    </Modal>
  );
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    backgroundColor: '#fff',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    minWidth: 500,
    minHeight: 200,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  header: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: 20,
    margin: 0,
  },
  headerDeleteIcon: {
    fontSize: 28,
  },
}));
