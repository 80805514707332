import React, { useState, useEffect, useCallback, useMemo } from 'react';
import _ from 'lodash';
import { Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

import { getEvents, setPage, setPageItems } from '../../../store/evtrack/actions';
// components
import Table from '../../../components/Table';
import Widget from '../../../components/Widget';
import Breadcrumbs from '../../../components/Breadcrumbs';
import { ROUTES, NAMES, IDS } from '../../../constants/routes';
import withRouter from '../../../hooks/with-router';

const headCells = [
  { id: 'name', numeric: false, disablePadding: true, label: 'Name' },
  { id: 'count', numeric: false, disablePadding: false, label: 'Count' },
  { id: 'unique', numeric: false, disablePadding: false, label: 'Uniq' },
];

const breadcrumsRoutes = [
  {
    route: ROUTES[IDS.EVTRACK],
    name: NAMES[IDS.EVTRACK],
  },
];

const EvtrackEvents = () => {
  const classes = useStyles();

  // global
  const dispatch = useDispatch();
  const selectedProjectId = useSelector((state) => state.projects.selectedProjectId);
  const eventsData = useSelector((state) => state.evtrack.events.data);
  const count = useSelector((state) => state.evtrack.events.count);
  const pageNumber = useSelector((state) => state.evtrack.events.pageNumber);
  const pageItemCount = useSelector((state) => state.evtrack.events.pageItemCount);
  const isPending = useSelector((state) => state.evtrack.pending);

  const [searchValue, setSearchValue] = useState('');
  const [events, setEvents] = useState([]);

  useEffect(() => {
    dispatch(getEvents());
  }, [pageNumber, pageItemCount, selectedProjectId]);

  const handleSearchDebounce = (search) => {
    const evs = _.filter(eventsData, (ev) => _.get(ev, ['name']).indexOf(search) !== -1);
    setEvents(evs);
  };

  useEffect(() => {
    handleSearchDebounce(searchValue);
  }, [eventsData]);

  const tableData = useMemo(
    () =>
      events.reduce((acc, i) => {
        const d = _.pick(i, ['name', 'count', 'unique']);

        return [...acc, d];
      }, []),
    [events],
  );

  const handleRowClick = useCallback((event) => {
    if (event.name) {
      window.open(`${ROUTES[IDS.EVENTS]}/${event.name}`);
      // navigate({
      //   pathname: `${ROUTES[IDS.EVENTS]}/${event.name}`,
      //   state: {
      //     event,
      //   },
      // });
    }
  }, []);

  const handleChangeRowsPerPage = (event) => {
    // dispatch(setPageItems(parseInt(event.target.value, 10)));
    // dispatch(setPage(1));
  };

  const handleChangePage = (event, newPage) => {
    // if (newPage - 1 < pageNumber) {
    //   dispatch(setPage(newPage + 1));
    // }

    // if (newPage - 1 > pageNumber) {
    //   dispatch(setPage(newPage - 1));
    // }
  };

  const handleSearchChange = (e) => {
    e.preventDefault();
    setSearchValue(e.target.value);
    handleSearchDebounce(e.target.value);
  };

  return (
    <>
      <Breadcrumbs links={breadcrumsRoutes} currentRoute={NAMES[IDS.EVENTS]} />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget disableWidgetMenu bodyClass={classes.tableTopOverflow}>
            <Grid item xs={6}>
              <TextField
                className={classes.field}
                name="search"
                label="Find Event"
                value={searchValue}
                onChange={handleSearchChange}
                variant="outlined"
              />
            </Grid>
          </Widget>
        </Grid>

        <Grid item xs={12}>
          <Widget disableWidgetMenu noBodyPadding noHeaderPadding bodyClass={classes.tableOverflow}>
            <Table
              headCells={headCells}
              rows={tableData}
              count={tableData.length}
              order=""
              orderBy=""
              page={0}
              // page={pageNumber - 1}
              rowsPerPage={1}
              // rowsPerPage={tableData.length}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              onRowClick={handleRowClick}
            />

            {isPending && (
              <div className={classes.loaderWrap}>
                <div className={classes.loader}>
                  <CircularProgress size={26} />
                </div>
              </div>
            )}
          </Widget>
        </Grid>
      </Grid>
    </>
  );
};

export default withRouter(EvtrackEvents);

const useStyles = makeStyles((theme) => ({
  tableOverflow: {
    position: 'relative',
  },
  tableTopOverflow: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  root: {
    width: '100%',
  },
  field: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  loaderWrap: {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255,255,255,0.7)',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 9,
  },
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));
