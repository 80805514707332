import React, { useCallback } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Chip from '@material-ui/core/Chip';
import { Info as InfoIcon } from '@material-ui/icons';

import { Typography } from '../Wrappers';

const states = {
  continue_subscribe: 'success',
  continue_purchase: 'primary',
  pending: 'warning',
  cancel: 'secondary',
  test: 'test',
};

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort, headCells, onInfoClick } = props;
  const createSortHandler = (property) => (event) => {
    if (typeof onRequestSort === 'function') {
      onRequestSort(event, property);
    }
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align="left" sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <Typography size="sm" color="text" colorBrightness="secondary">
                {headCell.label}
              </Typography>
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        {typeof onInfoClick === 'function' && <TableCell />}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    // minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  primary: {
    backgroundColor: theme.palette.primary.light,
    color: '#fff',
  },
  success: {
    backgroundColor: theme.palette.success.light,
    color: '#fff',
  },
  warning: {
    backgroundColor: theme.palette.warning.light,
    color: '#fff',
  },
  secondary: {
    backgroundColor: theme.palette.secondary.light,
    color: '#fff',
  },
  test: {
    backgroundColor: theme.palette.info.light,
    color: '#fff',
  },
  infoButton: {
    backgroundColor: 'transparent',
    border: 'none',
    outline: 'none',
    padding: 10,
    cursor: 'pointer',
  },
  infoIcon: {
    opacity: 0.6,
  },
}));

export default function TableWithPagination({
  order,
  rows,
  count,
  orderBy,
  page,
  rowsPerPage,
  onRequestSort,
  onPageChange,
  onRowsPerPageChange,
  headCells,
  onInfoClick,
  onRowClick,
}) {
  const classes = useStyles();

  const handleParamClick = useCallback((_id) => {
    onInfoClick(_id);
  }, []);

  const renderRow = (key, value) => {
    if (key === 'status' && _.isString(value)) {
      return (
        <TableCell align="left" key={key}>
          <Typography size="sm" color="text" colorBrightness="secondary">
            <Chip label={value} classes={{ root: classes[states[value.toLowerCase()]] }} />
          </Typography>
        </TableCell>
      );
    }

    const text = ['createdAt', 'updatedAt'].includes(key) ? moment(value).format('YYYY-MM-DD h:mm:ss') : value;

    return (
      <TableCell key={key} component="th" scope="row">
        <Typography size="sm" color="text" colorBrightness="secondary">
          {!_.isBoolean(text) ? text : JSON.stringify(text)}
        </Typography>
      </TableCell>
    );
  };

  return (
    <>
      <TableContainer>
        <Table stickyHeader className={classes.table} aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={onRequestSort}
            rowCount={rows.length}
            headCells={headCells}
            onInfoClick={onInfoClick}
          />
          <TableBody>
            {rows.map((row) => (
              <TableRow
                hover
                tabIndex={-1}
                key={row._id ?? row.id}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onRowClick(row);
                }}
              >
                {Object.keys(row).map((key) => renderRow(key, row[key]))}

                {typeof onInfoClick === 'function' && (
                  <TableCell align="center" className={classes.endCell}>
                    <button
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleParamClick(row._id ?? row.id);
                      }}
                      className={classes.infoButton}
                    >
                      <InfoIcon className={classes.infoIcon} />
                    </button>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </>
  );
}
