import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import ReactPlayer from 'react-player';
import moment from 'moment';
import classNames from 'classnames';
import { Grid } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import { Typography } from '../../../../components/Wrappers/Wrappers';
import Widget from '../../../../components/Widget/Widget';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import { ROUTES, NAMES, IDS } from '../../../../constants/routes';

import {
  // getList,
  // saveListOrders,
  setActiveCategoryId,
} from '../../../../store/apps/clockly/actions';

import useStyles from '../styles';

const breadcrumsRoutes = [
  {
    route: ROUTES[IDS.APP_CONTENT],
    name: NAMES[IDS.APP_CONTENT],
  },
];

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getChangedItems = (originalData, updatedData) => {
  const changedItems = {};

  updatedData.forEach((updatedItem, updatedIndex) => {
    const originalItem = originalData.find((item) => item.id === updatedItem.id);
    if (originalItem) {
      const indexBefore = originalData.indexOf(originalItem);
      const indexAfter = updatedIndex;
      const indexChanged = indexBefore - indexAfter;
      if (indexChanged !== 0) {
        changedItems[updatedItem.id] = indexChanged;
      }
    }
  });

  return changedItems;
};

const LengthItem = ({ row, index, list, onChangeList }) => {
  const classes = useStyles();

  const [isEdit, setIsEdit] = useState(false);
  const [value, setValue] = useState('');

  const handleClick = (e) => {
    e.preventDefault();
    setValue(row.length);
    setIsEdit(true);
  };

  const handleChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    setValue(Number(val));
  };

  const handleButtonPress = (e) => {
    e.preventDefault();

    if (value !== row.length) {
      const changedIndex = list.findIndex((i) => i.length === value);

      if (_.isNumber(changedIndex)) {
        const updatedItems = reorder(list, index, changedIndex);
        const changedItems = getChangedItems(list, updatedItems);
        onChangeList(updatedItems, changedItems);
      }
    }

    setIsEdit(false);
  };

  return (
    <TableCell scope="row" className={classes.cellLength}>
      {isEdit ? (
        <div className={classes.cellLengthEdit}>
          <TextField
            className={classes.cellLengthEditInput}
            value={value}
            onChange={handleChange}
            variant="outlined"
            type="number"
            size="small"
          />
          <Button disabled={!value} variant="contained" size="small" color="primary" type="button" onClick={handleButtonPress}>
            Save
          </Button>
        </div>
      ) : (
        <Typography size="sm" color="text" colorBrightness="secondary" onClick={handleClick}>
          {row.length}
        </Typography>
      )}
    </TableCell>
  );
};

const Preview = () => {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(0);
  const [list, setList] = useState([]);

  const dispatch = useDispatch();
  const data = useSelector((state) => state.apps.clockly.data);
  const searchQuery = useSelector((state) => state.apps.clockly.searchQuery);
  const isPending = useSelector((state) => state.apps.clockly.pending);

  useEffect(() => {
    setList(data);
  }, [data]);

  useEffect(() => {
    // const collection_id = Object.values(COLLECTIONS_IDS)[activeTab];
    // dispatch(setActiveCollectionId(collection_id));
  }, [activeTab, searchQuery]);

  useEffect(() => {
    setTimeout(() => {
      // dispatch(getList(true));
    }, 300);
  }, [activeTab]);

  const tableData = useMemo(
    () =>
      list.reduce((acc, i) => {
        const d = _.pick(i, ['id', 'length', 'collection_name', 'template_id', 'cover_url']);
        return [...acc, d];
      }, []),
    [list],
  );

  const handleTabChange = (e, tab) => {
    setActiveTab(tab);
  };

  const handleChangeList = useCallback(async (updatedItems, changedItems) => {
    setList(updatedItems);
    // dispatch(saveListOrders(changedItems));
  }, []);

  const handleDragEnd = ({ destination, source }) => {
    if (!destination) return;

    const updatedItems = reorder(list, source.index, destination.index);
    const changedItems = getChangedItems(list, updatedItems);

    handleChangeList(updatedItems, changedItems);
  };

  return (
    <>
      {/* <Breadcrumbs links={breadcrumsRoutes} currentRoute={NAMES.APP_CLOCKLY_CONTENT_PREVIEW} /> */}
      <Grid container xs={12} className={classes.header} alignItems="center" justifyContent="space-between">
        <Grid container xs={6} className={classes.headerGrid} alignItems="center">
          <Grid item>
            <Typography variant="h5" color="textSecondary" noWrap className={classes.title}>
              Preview Videos
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      {!searchQuery && (
        <Grid container xs={12} className={classes.header} alignItems="center" justifyContent="space-between">
          <Grid item xs={12} className={classes.tabs}>
            <Tabs
              value={activeTab}
              variant="scrollable"
              scrollButtons="auto"
              indicatorColor="primary"
              textColor="primary"
              onChange={handleTabChange}
            >
              {/* {Object.values(COLLECTIONS).map((collection) => (
                <Tab key={collection} label={collection} />
              ))} */}
            </Tabs>
          </Grid>
        </Grid>
      )}

      <Grid container spacing={4} className={classes.main}>
        <Grid item xs={12}>
          <Widget disableWidgetMenu noBodyPadding noHeaderPadding bodyClass={classes.tableTopOverflow}>
            <Grid item xs={12}>
              <TableContainer>
                <Table stickyHeader className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">
                        <Typography size="sm" color="text" colorBrightness="secondary">
                          Position
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography size="sm" color="text" colorBrightness="secondary">
                          Template Id
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography size="sm" color="text" colorBrightness="secondary">
                          Collection Name
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography size="sm" color="text" colorBrightness="secondary">
                          Preview
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {!isPending && (
                    <DragDropContext onDragEnd={handleDragEnd}>
                      <Droppable droppableId="droppable">
                        {(pr) => (
                          <TableBody ref={pr.innerRef} {...pr.droppableProps}>
                            {tableData.map((row, index) => (
                              <Draggable key={row.id} index={index} draggableId={row.id}>
                                {(provided) => (
                                  <TableRow ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} hover>
                                    <LengthItem row={row} index={index} list={list} onChangeList={handleChangeList} />
                                    <TableCell scope="row">
                                      <Typography size="sm" color="text" colorBrightness="secondary">
                                        {row.template_id}
                                      </Typography>
                                    </TableCell>
                                    <TableCell scope="row">
                                      <Typography size="sm" color="text" colorBrightness="secondary">
                                        {row.collection_name}
                                      </Typography>
                                    </TableCell>
                                    <TableCell scope="row" align="center" width={200}>
                                      <img src={row.cover_url} width={200} height={300} />
                                      {/* <ReactPlayer url={row.video_url} controls width={200} height={300} /> */}
                                    </TableCell>
                                  </TableRow>
                                )}
                              </Draggable>
                            ))}
                            {pr.placeholder}
                          </TableBody>
                        )}
                      </Droppable>
                    </DragDropContext>
                  )}
                </Table>
              </TableContainer>

              {isPending && (
                <div className={classes.loaderWrap}>
                  <div className={classes.loader}>
                    <CircularProgress size={26} />
                  </div>
                </div>
              )}
            </Grid>
          </Widget>
        </Grid>
      </Grid>
    </>
  );
};

export default Preview;
