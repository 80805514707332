import React from 'react';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import BreadcrumbsMaterial from '@material-ui/core/Breadcrumbs';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const Breadcrumbs = ({ links = [], currentRoute }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <BreadcrumbsMaterial aria-label="breadcrumb">
          {/* <Link color="inherit" href="/">
          Dashboard
        </Link> */}

          {links.map(({ route, name }) => (
            <Link key={route} color="inherit" to={route} className={classes.link}>
              <Typography color="textSecondary">{name}</Typography>
            </Link>
          ))}
          {currentRoute && <Typography color="textPrimary">{currentRoute}</Typography>}
        </BreadcrumbsMaterial>
      </Grid>
    </Grid>
  );
};

export default Breadcrumbs;

const useStyles = makeStyles((theme) => ({
  link: {
    position: 'relative',
    textDecoration: 'none',
    '&::after': {
      content: '""',
      position: 'absolute',
      left: 0,
      bottom: 2,
      width: '100%',
      height: '1px',
      backgroundColor: theme.palette.text.secondary,
      transition: 'all .3s ease',
    },
    '&:hover::after': {
      opacity: 0,
    },
  },
}));
