import AWS from 'aws-sdk';
import { v4 as uuid } from 'uuid';

// const S3_REGION = 'eu-north-1';
const S3_BUCKET = 'inspiro';

const s3 = new AWS.S3({
  accessKeyId: process.env.REACT_APP_S3_KEY,
  secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
});

// AKIAWE7ZU4LCGEGEBTTN
// habGdCIDyYlQhuQgaBILfPjeTIbFJ9E4X5N6wBSD

const getName = (filename) => {
  const i = filename.indexOf('.');
  return i < 0 ? '' : filename.substr(0, i);
};

export const uploadToS3 = async (file) => {
  const fileId = uuid();
  const contentType = file.type;
  // const filename = getName(file.name);

  return new Promise((res, rej) => {
    const params = {
      Bucket: S3_BUCKET,
      // region: S3_REGION,
      Key: `videos/${fileId}/${file.name}`,
      Body: file,
      ContentType: contentType,
    };

    s3.upload(params, (err, data) => {
      if (err) {
        return rej(err);
      }
      const url = data.Location;

      console.log('UPLOADED', url);
      return res(url);
    });
  });
};
