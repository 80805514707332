import { createAction } from 'redux-actions';
import { SET_LOADED, SET_SIDEBAR_OPEN } from './types';

export const setLoaded = createAction(SET_LOADED);
export const setSidebarOpen = createAction(SET_SIDEBAR_OPEN);

export const load = () => (dispatch) => {
  // .then(() => dispatch(setLoaded()))
};

