import React, { useState, useEffect, useRef, useReducer, useMemo } from 'react';
import { Grid, Select, MenuItem, InputLabel, FormControl } from '@material-ui/core';
import _ from 'lodash';
import moment from 'moment';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import DateFnsUtils from '@date-io/moment';
import Button from '@material-ui/core/Button';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import JSONPretty from 'react-json-pretty';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';

import {
  getRevenues,
  getRevenuesChartData,
  setRevenuesPageItems,
  setRevenuesPage,
  getRevenuesFilters,
} from '../../../store/subtrack/actions';
// components
import Widget from '../../../components/Widget/Widget';
import Table from '../../../components/Table';
import { Typography } from '../../../components/Wrappers/Wrappers';
import ApexLineChart from '../../../components/Charts/ApexLineChart';
import { ROUTES, NAMES, IDS } from '../../../constants/routes';
import { ROLES } from '../../../constants/roles';
import Breadcrumbs from '../../../components/Breadcrumbs';
import { getQueryParams } from '../../../utils/query-string';
import { formatDate } from '../../../utils/date';
import ChartModal from '../dashboard/modal';

const breadcrumsRoutes = [
  {
    route: ROUTES[IDS.SUBTRACK],
    name: NAMES[IDS.SUBTRACK],
  },
];

const headCells = [
  { id: 'id', numeric: false, disablePadding: true, label: 'Id' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
  { id: 'countryCode', numeric: false, disablePadding: false, label: 'Country Code' },
  { id: 'currencyCode', numeric: false, disablePadding: false, label: 'Currency Code' },
  { id: 'amount', numeric: false, disablePadding: false, label: 'Amount' },
  { id: 'amountUSD', numeric: false, disablePadding: false, label: 'Amount USD' },
  { id: 'isFirst', numeric: false, disablePadding: false, label: 'First pay' },
  { id: 'createdAt', numeric: false, disablePadding: false, label: 'Created At' },
  { id: 'updatedAt', numeric: false, disablePadding: false, label: 'Updated At' },
];

const formReducer = (state, e) => {
  const { name, value } = e;
  return {
    ...state,
    [name]: {
      ...state[name],
      value,
    },
  };
};

const FROM_DATE = formatDate(moment().subtract(1, 'months'));
const TO_DATE = formatDate();

const Revenue = () => {
  const isFieldsRendered = useRef(false);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const queryParams = getQueryParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  const classes = useStyles();
  const [valueDateFrom, setValueDateFrom] = useState(queryParams && queryParams.dateFrom ? queryParams.dateFrom : FROM_DATE);
  const [valueDateTo, setValueDateTo] = useState(queryParams && queryParams.dateTo ? queryParams.dateTo : TO_DATE);
  const [fields, setFormData] = useReducer(formReducer, {});
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isChartOpenModal, setIsChartOpenModal] = useState(false);
  const [isPrevPeriodEnabled, setPrevPeriodEnabled] = useState(!!queryParams?.prevPeriodEnabled);
  const [activeRowId, setActiveRowId] = useState(null);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('createdAt');

  // global
  const dispatch = useDispatch();
  const selectedProjectId = useSelector((state) => state.projects.selectedProjectId);
  const role = useSelector((state) => state.account.role);
  const data = useSelector((state) => state.subtrack.revenues.data);
  const isPending = useSelector((state) => state.subtrack.revenues.pending);
  const chartDataCurrent = useSelector((state) => state.subtrack.revenues.chartData.current);
  const chartDataPrev = useSelector((state) => state.subtrack.revenues.chartData.prev);
  const isChartDataPending = useSelector((state) => state.subtrack.revenues.chartDataPending);
  const count = useSelector((state) => state.subtrack.revenues.count);
  const fieldsData = useSelector((state) => state.subtrack.revenues.filters);
  const isFiltersPending = useSelector((state) => state.subtrack.revenues.filtersPending);
  const pageNumber = useSelector((state) => state.subtrack.revenues.pageNumber);
  const pageItemCount = useSelector((state) => state.subtrack.revenues.pageItemCount);

  const getRevenueByQueries = (isPagination = false) => {
    const valuesFields = Object.keys(queryParams).reduce(
      (acc, key) => ({
        ...acc,
        [key]: queryParams[key],
      }),
      {},
    );

    if (!isPagination) {
      dispatch(
        getRevenuesChartData({ dateFrom: valueDateFrom, dateTo: valueDateTo, ...valuesFields, prevPeriodEnabled: isPrevPeriodEnabled }),
      );
    }

    dispatch(getRevenues({ dateFrom: valueDateFrom, dateTo: valueDateTo, ...valuesFields, sortBy: orderBy }));
  };

  const modalData = useMemo(() => {
    if (activeRowId) {
      return data.find((t) => t._id === activeRowId);
    }

    return null;
  }, [data, activeRowId]);

  const tableData = useMemo(
    () =>
      data.reduce(
        (acc, i) => [
          ...acc,
          {
            _id: i._id,
            status: i.status,
            countryCode: _.get(_.pick(i.transaction, ['countryCode']), ['countryCode']),
            currencyCode: i.currencyCode,
            amount: i.amount,
            amountUSD: i.amountUSD,
            isFirst: i.isFirst,
            createdAt: i.createdAt,
            updatedAt: i.updatedAt,
          },
        ],
        [],
      ),
    [data],
  );

  useEffect(() => {
    if (!_.isEmpty(fieldsData)) {
      if (!isFieldsRendered.current) {
        isFieldsRendered.current = true;

        Object.keys(fieldsData).map((key) => {
          const defaultValue = key === 'countryCodes' ? [] : '';
          const queryValue =
            key === 'countryCodes' && !_.isEmpty(queryParams.countryCodes) ? queryParams.countryCodes.split(',') : queryParams[key];
          const value = !queryParams[key] ? defaultValue : queryValue;

          setFormData({
            name: key,
            value,
          });

          return key;
        });

        if (!queryParams.dateFrom) {
          setSearchParams((params) => {
            params.set('dateFrom', FROM_DATE);
            return params;
          });
        }
        if (!queryParams.dateTo) {
          setSearchParams((params) => {
            params.set('dateTo', TO_DATE);
            return params;
          });
        }
      }
    }
  }, [fieldsData, queryParams]);

  useEffect(() => {
    dispatch(getRevenuesFilters());
    getRevenueByQueries();
  }, [selectedProjectId]);

  const handleChangeRowsPerPage = (event) => {
    dispatch(setRevenuesPageItems(parseInt(event.target.value, 10)));
    dispatch(setRevenuesPage(1));

    getRevenueByQueries(true);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    if (property === 'updatedAt' || property === 'createdAt') {
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    }

    getRevenueByQueries(true);
  };

  const handleChangePage = (event, newPage) => {
    if (newPage - 1 < pageNumber) {
      dispatch(setRevenuesPage(newPage + 1));
    }
    if (newPage - 1 > pageNumber) {
      dispatch(setRevenuesPage(newPage - 1));
    }

    getRevenueByQueries(true);
  };

  const handleChartButtonPress = () => {
    setIsChartOpenModal(true);
  };

  const handleButtonPress = () => {
    getRevenueByQueries();
  };

  const handleButtonResetPress = () => {
    Object.keys(fieldsData).map((key) => {
      setFormData({
        name: key,
        value: key === 'countryCodes' ? [] : '',
      });

      setSearchParams((params) => {
        params.delete(key);
        return params;
      });

      return false;
    });

    setValueDateFrom(FROM_DATE);
    setValueDateTo(TO_DATE);
    setPrevPeriodEnabled(false);

    setSearchParams((params) => {
      params.set('dateFrom', FROM_DATE);
      params.set('dateTo', TO_DATE);
      params.delete('prevPeriodEnabled');
      return params;
    });

    dispatch(getRevenuesChartData({ dateFrom: FROM_DATE, dateTo: TO_DATE, prevPeriodEnabled: false }));
    dispatch(getRevenues({ dateFrom: FROM_DATE, dateTo: TO_DATE, sortBy: orderBy }));
  };

  const handleFromChange = (newValue) => {
    const date = formatDate(newValue);
    setValueDateFrom(date);

    setSearchParams((params) => {
      params.set('dateFrom', date);
      return params;
    });
  };

  const handleToChange = (newValue) => {
    const date = formatDate(newValue);
    setValueDateTo(date);

    setSearchParams((params) => {
      params.set('dateTo', date);
      return params;
    });
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { value } = e.target;
    const { name } = e.target;

    setSearchParams((params) => {
      if (!_.isEmpty(value)) {
        params.set(name, value);
      } else {
        params.delete(name);
      }
      return params;
    });

    setFormData({
      name,
      value,
    });
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);
    setIsChartOpenModal(false);
    setActiveRowId(null);
  };

  const handleInfoRowClick = (_id) => {
    setIsOpenModal(true);
    setActiveRowId(_id);
  };

  const handleRowClick = (tr) => {
    console.log('handleRowClick', tr);
    // navigate(`${ROUTES[IDS.TRANSACTIONS]}/${tr._id}`);
  };

  const handlePrevPeriodCheck = (e) => {
    setPrevPeriodEnabled(e.target.checked);

    setSearchParams((params) => {
      if (e.target.checked) {
        params.set('prevPeriodEnabled', e.target.checked);
      } else {
        params.delete('prevPeriodEnabled');
      }
      return params;
    });

    const valuesFields = Object.keys(queryParams).reduce(
      (acc, key) => ({
        ...acc,
        [key]: queryParams[key],
      }),
      {},
    );

    dispatch(getRevenuesChartData({ ...valuesFields, prevPeriodEnabled: e.target.checked }));
  };

  const handleChartSubmit = () => {
    setIsChartOpenModal(false);
  };

  const renderField = (key, attributes) => {
    const { value } = attributes;

    return (
      <Grid item xs={12} key={key} className={classes.fieldWrap}>
        <FormControl className={classes.formControl} variant="outlined">
          <InputLabel>{key}</InputLabel>
          <Select
            className={classes.field}
            label={key}
            name={key}
            variant="outlined"
            value={value}
            id={key}
            onChange={handleChange}
            autoWidth
            multiple={key === 'countryCodes'}
          >
            {key !== 'countryCodes' && <MenuItem value="">&nbsp;</MenuItem>}

            {fieldsData[key].map((item) => {
              const val = _.isBoolean(item) ? JSON.stringify(item) : item;
              return (
                <MenuItem key={val} value={val}>
                  {val}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
    );
  };

  const renderFields = () => (
    <Grid item xs={12} className={classes.form}>
      {Object.keys(fields).map((key) => renderField(key, fields[key]))}
    </Grid>
  );

  const renderFilters = () => {
    if (!isSmall) {
      return renderFields();
    }

    return (
      <Accordion className={classes.accordion}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography className={classes.heading}>Filters</Typography>
        </AccordionSummary>
        <AccordionDetails>{renderFields()}</AccordionDetails>
      </Accordion>
    );
  };

  return (
    <>
      <Grid container xs={12}>
        <Grid item xs={10}>
          <Breadcrumbs links={breadcrumsRoutes} currentRoute={NAMES[IDS.REVENUE]} />
        </Grid>
        <Grid container xs={2} alignItems="flex-end" justifyContent="flex-end">
          <Button
            variant="contained"
            size="large"
            color="primary"
            type="button"
            className={classes.button}
            onClick={handleChartButtonPress}
          >
            Save
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={4} className={classes.main}>
        <Grid item xs={12}>
          <Widget disableWidgetMenu bodyClass={classes.tableTopOverflow}>
            <Grid container item xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} className={classes.datepicker}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="DD/MM/YYYY"
                  margin="none"
                  id="date-picker-inline"
                  label="Date From"
                  value={moment(valueDateFrom, 'YYYY-MM-DD').toDate()}
                  onChange={handleFromChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  className={classes.datepickerLeft}
                />
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="DD/MM/YYYY"
                  margin="none"
                  id="date-picker-inline"
                  label="Date To"
                  value={moment(valueDateTo, 'YYYY-MM-DD').toDate()}
                  onChange={handleToChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  className={classes.datepickerRight}
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid container item xs={12} className={classes.filters}>
              {isFiltersPending ? (
                <div className={classes.filtersLoaderWrap}>
                  <div className={classes.loader}>
                    <CircularProgress size={26} />
                  </div>
                </div>
              ) : (
                renderFilters()
              )}
            </Grid>

            <Grid container item xs={12} className={classes.buttons}>
              <Button variant="contained" size="large" color="primary" type="button" className={classes.button} onClick={handleButtonPress}>
                Apply
              </Button>
              <Button
                variant="contained"
                size="large"
                color="secondary"
                type="button"
                className={classes.buttonReset}
                onClick={handleButtonResetPress}
              >
                Reset
              </Button>
            </Grid>
          </Widget>
        </Grid>

        <Grid item xs={12}>
          <Widget noBodyPadding disableWidgetMenu bodyClass={classes.tableOverflow}>
            <ApexLineChart
              data={{
                series: [
                  {
                    name: 'current',
                    data: Object.values(chartDataCurrent.data),
                  },
                  {
                    name: 'prev',
                    data: Object.values(chartDataPrev.data),
                  },
                ],
                categories: Object.keys(chartDataCurrent.data),
              }}
            />
            <Grid xs={12} container direction="row" justifyContent="center" alignItems="center">
              <Box className={classes.revenuePeriodWrap}>
                <Grid container direction="row" alignItems="center">
                  <Checkbox checked={isPrevPeriodEnabled} onChange={handlePrevPeriodCheck} color="default" />

                  <Grid item direction="column" alignItems="center" justifyContent="flex-start">
                    <Typography size="sm" weight="medium" noWrap color="text" colorBrightness="secondary" className={classes.revenuePeriod}>
                      Previous Period
                    </Typography>
                    <Typography size="md" weight="medium" noWrap color="text" colorBrightness="secondary" className={classes.revenuePeriod}>
                      {chartDataPrev.total}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>

              <Box className={classes.revenuePeriodWrap}>
                <Typography size="sm" weight="medium" noWrap color="text" colorBrightness="secondary" className={classes.revenuePeriod}>
                  Current Period
                </Typography>
                <Typography size="md" weight="medium" noWrap color="text" colorBrightness="secondary" className={classes.revenuePeriod}>
                  {chartDataCurrent.total}
                </Typography>
              </Box>
            </Grid>
            {isChartDataPending && (
              <div className={classes.loaderWrap}>
                <div className={classes.loader}>
                  <CircularProgress size={26} />
                </div>
              </div>
            )}
          </Widget>
        </Grid>

        <Grid item xs={12}>
          <Widget noBodyPadding bodyClass={classes.tableOverflow} disableWidgetMenu noHeaderPadding>
            <Table
              headCells={headCells}
              rows={tableData}
              count={count}
              order={order}
              orderBy={orderBy}
              page={pageNumber - 1}
              rowsPerPage={pageItemCount}
              onRequestSort={handleRequestSort}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              onInfoClick={role === ROLES.OWNER && handleInfoRowClick}
              onRowClick={handleRowClick}
            />
            {isPending && (
              <div className={classes.loaderWrap}>
                <div className={classes.loader}>
                  <CircularProgress size={26} />
                </div>
              </div>
            )}
          </Widget>
        </Grid>
      </Grid>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isOpenModal}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpenModal}>
          <div className={classes.modalContainer}>{!_.isEmpty(modalData) && <JSONPretty id="json-pretty" data={modalData} />}</div>
        </Fade>
      </Modal>

      <ChartModal isOpen={isChartOpenModal} onClose={handleCloseModal} onSubmit={handleChartSubmit} route="REVENUE" />
    </>
  );
};

export default Revenue;

const useStyles = makeStyles((theme) => ({
  main: {
    marginTop: theme.spacing(2),
  },
  tableOverflow: {
    position: 'relative',
  },
  root: {
    width: '100%',
  },
  accordion: {
    width: '100%',
    marginTop: theme.spacing(2),
    '&::before': {
      opacity: 0,
    },
  },
  tableTopOverflow: {
    display: 'flex',
    flexDirection: 'column',
  },
  datepickerLeft: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  datepickerRight: {
    marginLeft: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      marginLeft: 0,
      width: '100%',
    },
  },
  filtersLoaderWrap: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
    marginTop: theme.spacing(2),
    minHeight: theme.spacing(4),
  },
  filters: {},
  form: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      flexDirection: 'column',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
    },
  },
  fieldWrap: {
    display: 'flex',
    width: '100%',
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      marginBottom: theme.spacing(2),
    },
  },
  formControl: {
    display: 'flex',
    width: '100%',
  },
  fieldWrapMulti: {
    display: 'flex',
    width: '100%',
    marginTop: theme.spacing(4),
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      marginBottom: theme.spacing(2),
    },
  },
  field: {
    width: '100%',
  },
  buttons: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  button: {},
  buttonReset: {
    marginLeft: theme.spacing(2),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  revenuePeriod: {
    // marginRight: theme.spacing(1),
    // marginBottom: theme.spacing(2),
  },
  revenuePeriodWrap: {
    marginRight: theme.spacing(6),
    marginLeft: theme.spacing(6),
    marginBottom: theme.spacing(2),
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  loaderWrap: {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255,255,255,0.7)',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 9,
  },
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContainer: {
    backgroundColor: '#fff',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxWidth: '70vw',
    minWidth: '70vw',
    minHeight: 400,
    maxHeight: '70vh',
    overflow: 'scroll',
  },
}));
