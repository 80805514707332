import React, { useState, useEffect, useReducer } from 'react';
import moment from 'moment';
import _ from 'lodash';
import JSONPretty from 'react-json-pretty';
import { Grid, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Info as InfoIcon } from '@material-ui/icons';

import {
  getDeviceLogs,
  getCustomerEvents,
  setCustomerPage,
  setCustomerPageItems,
  resetDeviceLogsData,
} from '../../../store/evtrack/actions';
// components
import Widget from '../../../components/Widget';
import { Typography } from '../../../components/Wrappers';
import { ROUTES, NAMES, IDS } from '../../../constants/routes';
import Breadcrumbs from '../../../components/Breadcrumbs';
import withRouter from '../../../hooks/with-router';

const breadcrumsRoutes = [
  {
    route: ROUTES[IDS.EVTRACK],
    name: NAMES[IDS.EVTRACK],
  },
];

const trimString = (string, length = 50) => (string.length > length ? `${string.substring(0, length)}...` : string);

const FIELDS = {
  idfa: {
    label: 'IDFA',
  },
  idfm: {
    label: 'IDFM',
  },
  idfv: {
    label: 'IDFV',
  },
  ip: {
    label: 'IP',
  },
};

const formReducer = (state, e) => {
  const { name, value } = e;

  return {
    ...state,
    [name]: {
      ...state[name],
      value,
    },
  };
};

const initialForm = Object.keys(FIELDS).reduce(
  (acc, key) => ({
    ...acc,
    [key]: {
      ...FIELDS[key],
      value: '',
    },
  }),
  {},
);

const DeviceLogs = () => {
  const classes = useStyles();
  // global
  const dispatch = useDispatch();
  const [values, setValues] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [fields, setFormData] = useReducer(formReducer, initialForm);
  const props = useSelector((state) => state.evtrack.deviceLogs.props);
  const installs = useSelector((state) => state.evtrack.deviceLogs.installs);
  const isPending = useSelector((state) => state.evtrack.pending);
  const events = useSelector((state) => state.evtrack.deviceLogs.events.data);
  const count = useSelector((state) => state.evtrack.deviceLogs.events.count);
  const pageNumber = useSelector((state) => state.evtrack.deviceLogs.events.pageNumber);
  const pageItemCount = useSelector((state) => state.evtrack.deviceLogs.events.pageItemCount);

  useEffect(() => {
    if (values) {
      dispatch(getCustomerEvents(values));
    }
  }, [pageNumber, pageItemCount, values]);

  useEffect(
    () => () => {
      dispatch(resetDeviceLogsData());
    },
    [],
  );

  const handleSubmit = async (e) => {
    e.preventDefault();

    const valuesFields = Object.keys(fields).reduce((acc, key) => {
      if (fields[key].value) {
        return {
          ...acc,
          [key]: fields[key].value,
        };
      }

      return acc;
    }, {});

    dispatch(getDeviceLogs(valuesFields));
    dispatch(getCustomerEvents(valuesFields));
    setValues(valuesFields);
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setCustomerPageItems(parseInt(event.target.value, 10)));
    dispatch(setCustomerPage(1));
  };

  const handleChangePage = (event, newPage) => {
    if (newPage - 1 < pageNumber) {
      dispatch(setCustomerPage(newPage + 1));
    }

    if (newPage - 1 > pageNumber) {
      dispatch(setCustomerPage(newPage - 1));
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { value } = e.target;
    const { name } = e.target;

    setFormData({
      name,
      value,
    });
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);
    setModalData({});
  };

  const handleParamClick = (data) => {
    setIsOpenModal(true);
    setModalData(data);
  };

  const renderField = (key, attributes) => {
    const { label, value } = attributes;

    return (
      <Grid item xs={4}>
        <TextField
          className={classes.field}
          key={key}
          name={key}
          id={key}
          label={label}
          value={value}
          onChange={handleChange}
          variant="outlined"
        />
      </Grid>
    );
  };

  return (
    <>
      <Breadcrumbs links={breadcrumsRoutes} currentRoute={NAMES[IDS.DEVICE_LOGS]} />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget disableWidgetMenu bodyClass={classes.tableOverflow}>
            <form className={classes.form} noValidate autoComplete="off" onSubmit={handleSubmit}>
              {Object.keys(fields).map((key) => renderField(key, fields[key]))}
              <Button variant="contained" size="large" color="primary" type="submit">
                Apply
              </Button>
            </form>
          </Widget>
        </Grid>

        <Grid item xs={12}>
          <Widget title="Events" noBodyPadding disableWidgetMenu bodyClass={classes.tableOverflow}>
            <TableContainer>
              <Table stickyHeader className={classes.table} aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">
                      <Typography size="sm" color="text" colorBrightness="secondary">
                        Time
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography size="sm" color="text" colorBrightness="secondary">
                        Name
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography size="sm" color="text" colorBrightness="secondary">
                        Params
                      </Typography>
                    </TableCell>
                    <TableCell align="left" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {events.map((row) => (
                    <TableRow hover tabIndex={-1} key={row._id}>
                      <TableCell component="th" scope="row">
                        <Typography size="sm" color="text" colorBrightness="secondary">
                          {row.name}
                        </Typography>
                      </TableCell>
                      <TableCell align="left" className={classes.time}>
                        <Typography size="sm" color="text" colorBrightness="secondary">
                          {moment(row.createdAt).format('YYYY-MM-DD h:mm:ss')}
                        </Typography>
                      </TableCell>
                      <TableCell align="left" className={classes.params}>
                        <Typography size="sm" color="text" colorBrightness="secondary">
                          {trimString(row.params, 100)}
                        </Typography>
                      </TableCell>
                      <TableCell align="center" className={classes.endCell}>
                        {!!row.params && (
                          <button
                            type="button"
                            onClick={() => handleParamClick(JSON.parse(JSON.stringify(row.params)))}
                            className={classes.infoButton}
                          >
                            <InfoIcon className={classes.infoIcon} />
                          </button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              component="div"
              count={count}
              rowsPerPage={pageItemCount}
              page={pageNumber - 1}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            {isPending && (
              <div className={classes.loaderWrap}>
                <div className={classes.loader}>
                  <CircularProgress size={26} />
                </div>
              </div>
            )}
          </Widget>
        </Grid>
        <Grid item xs={12}>
          <Widget title="User Props" noBodyPadding disableWidgetMenu bodyClass={classes.tableOverflow}>
            <TableContainer>
              <Table stickyHeader className={classes.table} aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">
                      <Typography size="sm" color="text" colorBrightness="secondary">
                        Device Country
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography size="sm" color="text" colorBrightness="secondary">
                        Device Locale
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography size="sm" color="text" colorBrightness="secondary">
                        Device Model
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography size="sm" color="text" colorBrightness="secondary">
                        Device Vendor
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography size="sm" color="text" colorBrightness="secondary">
                        Device V ID
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography size="sm" color="text" colorBrightness="secondary">
                        Geo City
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography size="sm" color="text" colorBrightness="secondary">
                        Geo Country
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography size="sm" color="text" colorBrightness="secondary">
                        Session Number
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography size="sm" color="text" colorBrightness="secondary">
                        Last Session
                      </Typography>
                    </TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.map((row) => (
                    <TableRow hover tabIndex={-1} key={row._id}>
                      <TableCell component="th" scope="row">
                        <Typography size="sm" color="text" colorBrightness="secondary">
                          {row.deviceCountry}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography size="sm" color="text" colorBrightness="secondary">
                          {row.deviceLocale}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography size="sm" color="text" colorBrightness="secondary">
                          {row.deviceModel}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography size="sm" color="text" colorBrightness="secondary">
                          {row.deviceVendor}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography size="sm" color="text" colorBrightness="secondary">
                          {row.deviceVID}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography size="sm" color="text" colorBrightness="secondary">
                          {row.geoCity}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography size="sm" color="text" colorBrightness="secondary">
                          {row.geoCountry}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography size="sm" color="text" colorBrightness="secondary">
                          {row.sessionNumber}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography size="sm" color="text" colorBrightness="secondary">
                          {moment(row.lastSession).format('YYYY-MM-DD h:mm:ss')}
                        </Typography>
                      </TableCell>
                      <TableCell align="center" className={classes.endCell}>
                        <button type="button" onClick={() => handleParamClick(JSON.stringify(row))} className={classes.infoButton}>
                          <InfoIcon className={classes.infoIcon} />
                        </button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {isPending && (
              <div className={classes.loaderWrap}>
                <div className={classes.loader}>
                  <CircularProgress size={26} />
                </div>
              </div>
            )}
          </Widget>
        </Grid>
        <Grid item xs={12}>
          <Widget title="Installs" noBodyPadding disableWidgetMenu bodyClass={classes.tableOverflow}>
            <TableContainer>
              <Table stickyHeader className={classes.table} aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">
                      <Typography size="sm" color="text" colorBrightness="secondary">
                        Time
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography size="sm" color="text" colorBrightness="secondary">
                        Source
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography size="sm" color="text" colorBrightness="secondary">
                        App Version
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography size="sm" color="text" colorBrightness="secondary">
                        OS Version
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography size="sm" color="text" colorBrightness="secondary">
                        af Status
                      </Typography>
                    </TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {installs.map((row) => (
                    <TableRow hover tabIndex={-1} key={row._id}>
                      <TableCell component="th" scope="row">
                        <Typography size="sm" color="text" colorBrightness="secondary">
                          {moment(row.installTime).format('YYYY-MM-DD h:mm:ss')}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography size="sm" color="text" colorBrightness="secondary">
                          {row.mediaSource}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography size="sm" color="text" colorBrightness="secondary">
                          {row.appVersion}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography size="sm" color="text" colorBrightness="secondary">
                          {row.osVersion}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography size="sm" color="text" colorBrightness="secondary">
                          {row.afStatus}
                        </Typography>
                      </TableCell>
                      <TableCell align="center" className={classes.endCell}>
                        <button type="button" onClick={() => handleParamClick(JSON.stringify(row))} className={classes.infoButton}>
                          <InfoIcon className={classes.infoIcon} />
                        </button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {isPending && (
              <div className={classes.loaderWrap}>
                <div className={classes.loader}>
                  <CircularProgress size={26} />
                </div>
              </div>
            )}
          </Widget>
        </Grid>
      </Grid>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isOpenModal}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpenModal}>
          <div className={classes.modalContainer}>{!_.isEmpty(modalData) && <JSONPretty id="json-pretty" data={modalData} />}</div>
        </Fade>
      </Modal>
    </>
  );
};

export default withRouter(DeviceLogs);

const useStyles = makeStyles((theme) => ({
  tableOverflow: {
    position: 'relative',
  },
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  loaderWrap: {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255,255,255,0.7)',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 9,
  },
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  form: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  field: {
    width: '90%',
  },
  time: {
    minWidth: '150px',
  },
  params: {
    maxWidth: '500px',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContainer: {
    backgroundColor: '#fff',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxWidth: '70vw',
    minWidth: '70vw',
    minHeight: 400,
    maxHeight: '70vh',
    overflow: 'scroll',
  },
  infoButton: {
    backgroundColor: 'transparent',
    border: 'none',
    outline: 'none',
    padding: 10,
  },
  infoIcon: {
    opacity: 0.6,
  },
  endCell: {
    width: '100px',
  },
}));
