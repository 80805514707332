import { createAction } from 'redux-actions';
import * as TYPES from './types';
import * as messagingApi from '../../api/messaging';

export const setPending = createAction(TYPES.SET_PENDING);
export const setTokens = createAction(TYPES.SET_TOKENS);
export const setNotificationsData = createAction(TYPES.SET_NOTIFICATIONS_DATA);
export const setNotificationsPending = createAction(TYPES.SET_NOTIFICATIONS_PENDING);

export const getNotifications = () => async (dispatch) => {
  dispatch(setNotificationsPending(true));
  try {
    const notifications = await messagingApi.getNotifications();
    dispatch(setNotificationsData(notifications));
    return notifications;
  } catch (error) {
    console.log('[GET NOTIFICATIONS ERROR]', error);
    throw error;
  } finally {
    dispatch(setNotificationsPending(false));
  }
};

export const sendMessage = (data) => async (dispatch) => {
  dispatch(setPending(true));
  try {
    const message = await messagingApi.sendMessage(data);

    console.log('message', message);
  } catch (error) {
    console.log('[SEND MESSAGE ERROR]', error);
    throw error;
  } finally {
    dispatch(setPending(false));
  }
};

export const setToken = (token) => async (dispatch, getState) => {
  const {
    messaging: { tokens },
  } = getState();

  dispatch(setTokens([...tokens, token]));
};

export const removeToken = (token) => async (dispatch, getState) => {
  const {
    messaging: { tokens },
  } = getState();

  const filteredTokens = tokens.filter((t) => t.indexOf(token) < 0);

  dispatch(setTokens(filteredTokens));
};
