import http from '../../utils/http';
import { API_PATH } from './consts';

const PATH = `${API_PATH}/inspiro/admin`;

export const getCategoryList = (query) => http.get(`${PATH}/category/list${query ? `?${query}` : ''}`);

export const setCategoryListOrders = (data) =>
  http.post(`${PATH}/category/list/orders`, {
    ...data,
  });

export const createCategory = (data) =>
  http.post(`${PATH}/category`, {
    ...data,
  });

export const updateCategory = (data) =>
  http.put(`${PATH}/category`, {
    ...data,
  });

export const deleteCategory = (id) => http.delete(`${PATH}/category`, { data: { id } });

export const saveCategoryOrders = (data) =>
  http.post(`${PATH}/category/orders`, {
    ...data,
  });

export const getCollectionList = (query) => http.get(`${PATH}/collection/list${query ? `?${query}` : ''}`);

export const setCollectionListOrders = (data) =>
  http.post(`${PATH}/collection/list/orders`, {
    ...data,
  });

export const createCollection = (data) =>
  http.post(`${PATH}/collection`, {
    ...data,
  });

export const updateCollection = (data) =>
  http.put(`${PATH}/collection`, {
    ...data,
  });

export const deleteCollection = (id) => http.delete(`${PATH}/collection`, { data: { id } });

export const saveCollectionOrders = (data) =>
  http.post(`${PATH}/collection/orders`, {
    ...data,
  });

export const getMessageList = (query) => http.get(`${PATH}/message/list${query ? `?${query}` : ''}`);

export const setMessageListOrders = (data) =>
  http.post(`${PATH}/message/list/orders`, {
    ...data,
  });

export const createMessage = (data) =>
  http.post(`${PATH}/message`, {
    ...data,
  });

export const updateMessage = (data) =>
  http.put(`${PATH}/message`, {
    ...data,
  });

export const deleteMessage = (id) => http.delete(`${PATH}/message`, { data: { id } });

export const saveMessageOrders = (data) =>
  http.post(`${PATH}/message/orders`, {
    ...data,
  });

export const getBackgroundList = (query) => http.get(`${PATH}/background/list${query ? `?${query}` : ''}`);

export const setBackgroundListOrders = (data) =>
  http.post(`${PATH}/background/list/orders`, {
    ...data,
  });

export const createBackground = (data) =>
  http.post(`${PATH}/background`, {
    ...data,
  });

export const updateBackground = (data) =>
  http.put(`${PATH}/background`, {
    ...data,
  });

export const deleteBackground = (id) => http.delete(`${PATH}/background`, { data: { id } });

export const saveBackgroundOrders = (data) =>
  http.post(`${PATH}/background/orders`, {
    ...data,
  });
