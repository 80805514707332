import { createAction } from 'redux-actions';
import queryString from 'query-string';

import * as API from '../../../api/apps/inspiro';

import * as TYPES from './types';

const setPending = createAction(TYPES.SET_PENDING);
const setPageNumber = createAction(TYPES.SET_PAGE_NUMBER);
const setItemCount = createAction(TYPES.SET_PAGE_ITEM_COUNT);

const setCategoryListData = createAction(TYPES.SET_CATEGORY_LIST_DATA);
const setCollectionListData = createAction(TYPES.SET_COLLECTION_LIST_DATA);
const setMessageListData = createAction(TYPES.SET_MESSAGE_LIST_DATA);
const setBackgroundListData = createAction(TYPES.SET_BACKGROUND_LIST_DATA);

export const setActiveCollectionId = createAction(TYPES.SET_ACTIVE_COLLECTION_ID);
export const setSearchQuery = createAction(TYPES.SET_SEARCH_QUERY);

export const setPage = (page) => async (dispatch) => {
  dispatch(setPageNumber(page));
};

export const setPageItems = (numb) => async (dispatch) => {
  dispatch(setItemCount(numb));
};

export const getBackgroundList = () => async (dispatch) => {
  dispatch(setPending(true));

  try {
    const data = await API.getBackgroundList();
    return dispatch(setBackgroundListData(data));
  } catch (error) {
    console.log('[ERROR GET BACKGROUND LIST]', error);
    throw error;
  } finally {
    dispatch(setPending(false));
  }
};

export const saveBackgroundListOrders = (ordersList) => async (dispatch) => {
  try {
    await API.setBackgroundListOrders(ordersList);
    const data = await API.getBackgroundList();
    return dispatch(setBackgroundListData(data));
  } catch (error) {
    console.log('[ERROR SET BACKGROUND LIST ORDERS]', error);
    throw error;
  }
};

export const createBackground = (data) => async (dispatch) => {
  dispatch(setPending(true));

  try {
    await API.createBackground(data);
    return true;
  } catch (error) {
    console.log('[ERROR CREATE BACKGROUND]', error);
    throw error;
  } finally {
    dispatch(setPending(false));
  }
};

export const updateBackground = (data) => async (dispatch) => {
  dispatch(setPending(true));

  try {
    await API.updateBackground(data);
    return true;
  } catch (error) {
    console.log('[ERROR UPDATE BACKGROUND]', error);
    throw error;
  } finally {
    dispatch(setPending(false));
  }
};

export const deleteBackground = (data) => async (dispatch) => {
  dispatch(setPending(true));

  try {
    await API.deleteBackground(data);
    return true;
  } catch (error) {
    console.log('[ERROR DELETE BACKGROUND]', error);
    throw error;
  } finally {
    dispatch(setPending(false));
  }
};

export const getCategoryList = () => async (dispatch) => {
  dispatch(setPending(true));

  try {
    const data = await API.getCategoryList();
    return dispatch(setCategoryListData(data));
  } catch (error) {
    console.log('[ERROR GET CATEGORY LIST]', error);
    throw error;
  } finally {
    dispatch(setPending(false));
  }
};

export const saveCategoryListOrders = (ordersList) => async (dispatch) => {
  try {
    await API.setCategoryListOrders(ordersList);
    const data = await API.getCategoryList();
    return dispatch(setCategoryListData(data));
  } catch (error) {
    console.log('[ERROR SET CATEGORY LIST ORDERS]', error);
    throw error;
  }
};

export const createCategory = (data) => async (dispatch) => {
  dispatch(setPending(true));

  try {
    await API.createCategory(data);
    return true;
  } catch (error) {
    console.log('[ERROR CREATE CATEGORY]', error);
    throw error;
  } finally {
    dispatch(setPending(false));
  }
};

export const updateCategory = (data) => async (dispatch) => {
  dispatch(setPending(true));

  try {
    await API.updateCategory(data);
    return true;
  } catch (error) {
    console.log('[ERROR UPDATE CATEGORY]', error);
    throw error;
  } finally {
    dispatch(setPending(false));
  }
};

export const deleteCategory = (data) => async (dispatch) => {
  dispatch(setPending(true));

  try {
    await API.deleteCategory(data);
    return true;
  } catch (error) {
    console.log('[ERROR DELETE CATEGORY]', error);
    throw error;
  } finally {
    dispatch(setPending(false));
  }
};

export const getCollectionList = (withoutPending) => async (dispatch) => {
  if (!withoutPending) {
    dispatch(setPending(true));
  }

  try {
    const data = await API.getCollectionList();
    return dispatch(setCollectionListData(data));
  } catch (error) {
    console.log('[ERROR GET COLLECTION LIST]', error);
    throw error;
  } finally {
    dispatch(setPending(false));
  }
};

export const saveCollectionListOrders = (ordersList) => async (dispatch) => {
  try {
    await API.setCollectionListOrders(ordersList);
    return dispatch(getCollectionList(true));
  } catch (error) {
    console.log('[ERROR SET COLLECTION LIST ORDERS]', error);
    throw error;
  }
};

export const createCollection = (data) => async (dispatch) => {
  dispatch(setPending(true));

  try {
    await API.createCollection(data);
    return true;
  } catch (error) {
    console.log('[ERROR CREATE COLLECTION]', error);
    throw error;
  } finally {
    dispatch(setPending(false));
  }
};

export const updateCollection = (data) => async (dispatch) => {
  dispatch(setPending(true));

  try {
    await API.updateCollection(data);
    return true;
  } catch (error) {
    console.log('[ERROR UPDATE COLLECTION]', error);
    throw error;
  } finally {
    dispatch(setPending(false));
  }
};

export const deleteCollection = (data) => async (dispatch) => {
  dispatch(setPending(true));

  try {
    await API.deleteCollection(data);
    return true;
  } catch (error) {
    console.log('[ERROR DELETE COLLECTION]', error);
    throw error;
  } finally {
    dispatch(setPending(false));
  }
};

export const getMessageList =
  ({ withoutPagination, withoutPending }) =>
  async (dispatch, getState) => {
    const {
      apps: {
        inspiro: {
          message: { pageNumber, pageItemCount, activeCollectionId, searchQuery },
        },
      },
    } = getState();

    if (!withoutPending) {
      dispatch(setPending(true));
    }

    const query = {};

    if (searchQuery) {
      query.text = searchQuery;
    } else if (activeCollectionId) {
      query.collection_id = activeCollectionId;
    }

    if (!withoutPagination) {
      query.pageNumber = pageNumber;
      query.pageItemCount = pageItemCount;
    }

    try {
      const data = await API.getMessageList(queryString.stringify(query));
      return dispatch(setMessageListData(data));
    } catch (error) {
      console.log('[ERROR GET MESSAGE LIST]', error);
      throw error;
    } finally {
      dispatch(setPending(false));
    }
  };

export const saveMessageListOrders = (ordersList) => async (dispatch) => {
  try {
    await API.setMessageListOrders(ordersList);
    return dispatch(getMessageList({ withoutPagination: true, withoutPending: true }));
  } catch (error) {
    console.log('[ERROR SET MESSAGE LIST ORDERS]', error);
    throw error;
  }
};

export const createMessage = (data) => async (dispatch) => {
  dispatch(setPending(true));

  try {
    await API.createMessage(data);
    return true;
  } catch (error) {
    console.log('[ERROR CREATE MESSAGE]', error);
    throw error;
  } finally {
    dispatch(setPending(false));
  }
};

export const updateMessage = (data) => async (dispatch) => {
  dispatch(setPending(true));

  try {
    await API.updateMessage(data);
    return true;
  } catch (error) {
    console.log('[ERROR UPDATE MESSAGE]', error);
    throw error;
  } finally {
    dispatch(setPending(false));
  }
};

export const deleteMessage = (data) => async (dispatch) => {
  dispatch(setPending(true));

  try {
    await API.deleteMessage(data);
    return true;
  } catch (error) {
    console.log('[ERROR DELETE MESSAGE]', error);
    throw error;
  } finally {
    dispatch(setPending(false));
  }
};
