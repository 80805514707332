import http from '../utils/http';

const PATH = '/subtrack';

export const getTransaction = (id) => http.get(`${PATH}/transaction/${id}`);
export const getTransactions = (query) => http.get(`${PATH}/transactions/list?${query}`);
export const getTransactionsFiltes = () => http.get(`${PATH}/transactions/list/filters`);
export const getAllActiveTransactions = () => http.get(`${PATH}/transactions/active`);
export const getAllTransactions = () => http.get(`${PATH}/transactions/all`);

export const getRevenues = (query) => http.get(`${PATH}/revenues/?${query}`);
export const getRevenuesChartData = (query) => http.get(`${PATH}/revenues/chart?${query}`);
export const getRevenuesFiltes = () => http.get(`${PATH}/revenues/filters`);
export const getAllRevenues = () => http.get(`${PATH}/revenues/all`);

export const verifyTransactions = () => http.get(`${PATH}/verify/all`);
export const verifyAllTransactions = () =>
  http.post(`${PATH}/verify`, {
    data: [],
  });

export const getCharts = () => http.get(`${PATH}/charts`);
export const createChart = (data) =>
  http.post(`${PATH}/charts`, {
    ...data,
  });

export const updateChart = (data) =>
  http.put(`${PATH}/charts`, {
    ...data,
  });

export const deleteChart = (_id) => http.delete(`${PATH}/charts`, { data: { _id } });
