export const SET_PENDING = 'APPS/INSPIRO/SET_PENDING';
export const SET_PAGE_NUMBER = 'APPS/INSPIRO/SET_PAGE_NUMBER';
export const SET_PAGE_ITEM_COUNT = 'APPS/INSPIRO/SET_PAGE_ITEM_COUNT';
export const SET_ACTIVE_COLLECTION_ID = 'APPS/INSPIRO/SET_ACTIVE_COLLECTION_ID';
export const SET_SEARCH_QUERY = 'APPS/INSPIRO/SET_SEARCH_QUERY';

export const SET_CATEGORY_LIST_DATA = 'APPS/INSPIRO/SET_CATEGORY_LIST_DATA';
export const SET_COLLECTION_LIST_DATA = 'APPS/INSPIRO/SET_COLLECTION_LIST_DATA';
export const SET_MESSAGE_LIST_DATA = 'APPS/INSPIRO/SET_MESSAGE_LIST_DATA';
export const SET_BACKGROUND_LIST_DATA = 'APPS/INSPIRO/SET_BACKGROUND_LIST_DATA';
