import axios from 'axios';
import { BASE_URL } from '../constants/general';
import { signOut } from '../store/auth/actions';

let store;

export const init = (storeEntry = store) => {
  store = storeEntry;
};

const http = axios.create({
  baseURL: BASE_URL,
  headers: {
    'x-api-key': process.env.REACT_APP_API_KEY,
    'Content-Type': 'application/json',
  },
});

http.interceptors.request.use(
  (config) => {
    const newConfig = config;

    if (config.url !== '/login') {
      const url = `${config.baseURL}${config.url}`;
      newConfig.headers.Authorization = `Bearer ${getToken()}`;
      newConfig.headers.project = `${getProjectId()}`;
      newConfig.url = url;
    }

    return newConfig;
  },
  (error) => {
    console.warn('[ERROR AXIOS REQUEST]:', error);
    return Promise.reject(error);
  },
);

http.interceptors.response.use(
  (response) => response.data.data,
  (error) => {
    if (error?.response?.status === 401 || error?.response?.status === 401) {
      store.dispatch(signOut());
      console.log(`URL: ${error.response.config.url}: Status: ${error.response.status}`);
    }

    return Promise.reject(error);
  },
);

export const getToken = () => {
  const {
    auth: { token },
  } = store.getState();

  return token;
};

export const getProjectId = () => {
  const {
    projects: { selectedProjectId },
  } = store.getState();

  return selectedProjectId;
};

export default http;
