import http from '../../utils/http';
import { API_PATH } from './consts';

const PATH = `${API_PATH}/clockly/admin`;

export const getUserList = (query) => http.get(`${PATH}/user/list${query ? `?${query}` : ''}`);
export const getUserData = (id) => http.get(`${PATH}/user/${id}`);
export const setUserData = (data) =>
  http.put(`${PATH}/user`, {
    ...data,
  });
export const getUserFiltes = () => http.get(`${PATH}/user/filters`);

export const getCategoryList = (query) => http.get(`${PATH}/category/list${query ? `?${query}` : ''}`);

export const setCategoryListOrders = (data) =>
  http.post(`${PATH}/category/list/orders`, {
    ...data,
  });

export const createCategory = (data) =>
  http.post(`${PATH}/category`, {
    ...data,
  });

export const updateCategory = (data) =>
  http.put(`${PATH}/category`, {
    ...data,
  });

export const deleteCategory = (id) => http.delete(`${PATH}/category`, { data: { id } });

export const saveCategoryOrders = (data) =>
  http.post(`${PATH}/category/orders`, {
    ...data,
  });

export const getFaceList = (query) => http.get(`${PATH}/face/list${query ? `?${query}` : ''}`);

export const setFaceListOrders = (data) =>
  http.post(`${PATH}/face/list/orders`, {
    ...data,
  });

export const createFace = (data) =>
  http.post(`${PATH}/face`, {
    ...data,
  });

export const updateFace = (data) =>
  http.put(`${PATH}/face`, {
    ...data,
  });

export const deleteFace = (id) => http.delete(`${PATH}/face`, { data: { id } });

export const saveFaceOrders = (data) =>
  http.post(`${PATH}/face/orders`, {
    ...data,
  });
