import React, { useState, useEffect, useRef, useReducer, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import DateFnsUtils from '@date-io/moment';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

// components
import { updateProject } from '../../store/projects/actions';
import Widget from '../../components/Widget/Widget';
import PageTitle from '../../components/PageTitle/PageTitle';
import Table from '../../components/Table';
import { Typography } from '../../components/Wrappers/Wrappers';
import { ROUTES, NAMES, IDS } from '../../constants/routes';
import { ROLES } from '../../constants/roles';
import withRouter from '../../hooks/with-router';

const NOTIFICATIONS_TYPES = {
  OFFER: 'OFFER',
};

const MessagingConfig = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const role = useSelector((state) => state.account.role);
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const [config, setState] = useState({
    [NOTIFICATIONS_TYPES.OFFER]: true,
  });

  // global
  const dispatch = useDispatch();
  const selectedProjectId = useSelector((state) => state.projects.selectedProjectId);
  const projects = useSelector((state) => state.projects.list);
  // const data = useSelector((state) => state.subtrack.products.data);
  // const isPending = useSelector((state) => false);
  const selectedProject = useMemo(() => projects.find((p) => p._id === selectedProjectId), [projects, selectedProjectId]);

  useEffect(() => {
    setState({ ...config, [NOTIFICATIONS_TYPES.OFFER]: !!selectedProject?.isNotificationsEnabled });
  }, [selectedProject]);

  const handleChange = (event) => {
    setState({ ...config, [event.target.name]: event.target.checked });
  };

  const handleButtonPress = () => {
    if (config[NOTIFICATIONS_TYPES.OFFER] !== selectedProject.isNotificationsEnabled) {
      dispatch(
        updateProject({
          token: selectedProject.token,
          isNotificationsEnabled: config[NOTIFICATIONS_TYPES.OFFER],
        }),
      );
    }
  };

  return (
    <Grid container className={classes.main}>
      <Grid item xs={12}>
        <Grid container xs={12} alignItems="flex-end" justifyContent="flex-end" className={classes.buttonWrap}>
          <Button variant="contained" size="large" color="primary" type="button" className={classes.button} onClick={handleButtonPress}>
            Save
          </Button>
        </Grid>
        <Widget disableWidgetMenu bodyClass={classes.tableTopOverflow}>
          <Grid item xs={12}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Switch
                    checked={config[NOTIFICATIONS_TYPES.OFFER]}
                    onChange={handleChange}
                    name={NOTIFICATIONS_TYPES.OFFER}
                    color="primary"
                  />
                }
                label="Offer Notifications"
              />
            </FormGroup>
          </Grid>
        </Widget>
      </Grid>
    </Grid>
  );
};

export default withRouter(MessagingConfig);

const useStyles = makeStyles((theme) => ({
  tableOverflow: {
    position: 'relative',
  },
  root: {
    width: '100%',
  },
  main: {
    // marginTop: theme.spacing(2),
  },
  tableTopOverflow: {
    display: 'flex',
    flexDirection: 'column',
  },
  filtersLoaderWrap: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
    marginTop: theme.spacing(2),
    minHeight: theme.spacing(4),
  },
  filters: {},
  form: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      flexDirection: 'column',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
    },
  },
  fieldWrap: {
    display: 'flex',
    width: '100%',
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      marginBottom: theme.spacing(2),
    },
  },
  formControl: {
    display: 'flex',
    width: '100%',
  },
  field: {
    width: '100%',
  },
  buttons: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  buttonWrap: {
    marginBottom: theme.spacing(4),
  },
  buttonReset: {
    marginLeft: theme.spacing(2),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  loaderWrap: {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255,255,255,0.7)',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 9,
  },
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContainer: {
    backgroundColor: '#fff',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxWidth: '70vw',
    minWidth: '70vw',
    minHeight: 400,
    maxHeight: '70vh',
    overflow: 'scroll',
  },
}));
