import { SET_LOADED, SET_SIDEBAR_OPEN } from './types';

export const initialState = {
  loaded: false,
  isSidebarOpened: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADED:
      return {
        ...state,
        loaded: true,
      };
    case SET_SIDEBAR_OPEN:
      return {
        ...state,
        isSidebarOpened: action.payload,
      };
    default:
      return state;
  }
};
