import React from 'react';
import ApexCharts from 'react-apexcharts';
import { useTheme } from '@material-ui/styles';
import defaultTheme from '../../themes/default';

const series = [
  {
    name: 'count',
    data: [31, 40, 28, 51, 42, 109, 100],
  },
  // {
  //   name: 'series2',
  //   data: [11, 32, 45, 32, 34, 52, 41],
  // },
];

export const colors = [
  defaultTheme.palette.primary.main,
  defaultTheme.palette.vanila.main,
  defaultTheme.palette.secondary.main,
  defaultTheme.palette.warning.main,
  defaultTheme.palette.success.main,
  defaultTheme.palette.pink.main,
  defaultTheme.palette.brown.main,
  defaultTheme.palette.red.main,
  defaultTheme.palette.info.main,
  defaultTheme.palette.violet.main,
  defaultTheme.palette.burgundy.main,
  defaultTheme.palette.cian.main,
];

// ############################################################
function themeOptions(theme, categories) {
  return {
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    xaxis: {
      type: 'datetime',
      categories,
    },
    tooltip: {
      x: {
        format: 'dd/MM/yy',
      },
    },
    fill: {
      colors: ['rgba(255,255,255,0)', 'rgba(255,255,255,0)'],
    },
    colors,
    chart: {
      toolbar: {
        show: true,
      },
      // events: {
      //   zoomed: (chartContext) => console.log('zoomed', chartContext),
      //   scrolled: (chartContext, { xaxis }) => {
      //     console.log('scrolled', chartContext);
      //   },
      // },
    },
    legend: {
      show: false,
    },
  };
}

function ApexLineChart({ data, height = 350 }) {
  const theme = useTheme();

  return <ApexCharts options={themeOptions(theme, data?.categories)} series={data?.series || []} type="area" height={height} />;
}

export default ApexLineChart;
