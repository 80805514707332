import { makeStyles } from '@material-ui/styles';
import { alpha } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  header: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  main: {
    position: 'relative',
  },
  tableOverflow: {
    position: 'relative',
  },
  accordion: {
    width: '100%',
    marginTop: theme.spacing(2),
    '&::before': {
      opacity: 0,
    },
  },
  tableTopOverflow: {
    display: 'flex',
    flexDirection: 'column',
  },
  loaderWrap: {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255,255,255,0.7)',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 9,
  },
  loader: {
    position: 'absolute',
    top: 200,
    left: '50%',
    transform: 'translate(-50%, 0)',
  },
  search: {
    position: 'relative',
    marginLeft: theme.spacing(2.5),
    borderRadius: 25,
    paddingLeft: theme.spacing(2.5),
    width: 36,
    backgroundColor: alpha(theme.palette.common.black, 0),
    transition: theme.transitions.create(['background-color', 'width']),
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: alpha(theme.palette.common.black, 0.08),
    },
  },
  searchFocused: {
    backgroundColor: alpha(theme.palette.common.black, 0.08),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 250,
    },
  },
  searchIcon: {
    width: 36,
    right: 0,
    height: '100%',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: theme.transitions.create('right'),
    zIndex: 1,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  clearIcon: {
    right: theme.spacing(1.25),
  },
  searchIconOpened: {
    right: theme.spacing(1.25),
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    height: 36,
    padding: 0,
    paddingRight: 36 + theme.spacing(1.25),
    width: '100%',
  },
  btnOrders: {
    color: '#ffffff',
    marginRight: theme.spacing(),
    backgroundColor: theme.palette.grey.light,
    '&:hover': {
      backgroundColor: theme.palette.grey.main,
    },
  },
  btnOrdersSave: {
    backgroundColor: theme.palette.success.light,
    '&:hover': {
      backgroundColor: theme.palette.success.main,
    },
  },
  cellLength: {
    cursor: 'pointer',
  },
  cellLengthEditInput: {
    marginRight: theme.spacing(1.25),

    '& input': {
      width: 50,
      height: 30,
      padding: '0 5px',
    },
  },
  cellLengthEdit: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  cellLengthEditWrap: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  cellLengthEditIcon: {
    width: 15,
    marginLeft: 5,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContainer: {
    backgroundColor: '#fff',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxWidth: '70vw',
    minWidth: '70vw',
    minHeight: 400,
    maxHeight: '70vh',
    overflow: 'scroll',
  },
  filtersLoaderWrap: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
    marginTop: theme.spacing(2),
    minHeight: theme.spacing(4),
  },
  filters: {},
  form: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      flexDirection: 'column',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
    },
  },
  fieldWrap: {
    display: 'flex',
    width: '100%',
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      marginBottom: theme.spacing(2),
    },
  },
  formControl: {
    display: 'flex',
    width: '100%',
  },
  fieldWrapMulti: {
    display: 'flex',
    width: '100%',
    marginTop: theme.spacing(4),
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      marginBottom: theme.spacing(2),
    },
  },
  field: {
    width: '100%',
  },
  buttons: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  button: {},
  buttonReset: {
    marginLeft: theme.spacing(2),
  },
}));
