import { createAction } from 'redux-actions';
import queryString from 'query-string';
import * as TYPES from './types';
import * as marketingApi from '../../api/marketing';

export const setPending = createAction(TYPES.SET_PENDING);
const setAdsData = createAction(TYPES.SET_ADS_DATA);
const setAdsPending = createAction(TYPES.SET_ADS_PENDING);
const setAdsExpensesPageNumber = createAction(TYPES.SET_ADS_EXPENSES_PAGE_NUMBER);
const setAdsExpensesItemCount = createAction(TYPES.SET_ADS_EXPENSES_PAGE_ITEM_COUNT);
const setAdsExpensesData = createAction(TYPES.SET_ADS_EXPENSES_DATA);
const setAdsExpensesPending = createAction(TYPES.SET_ADS_EXPENSES_PENDING);
const setReportsData = createAction(TYPES.SET_REPORTS_DATA);
const setReportsPending = createAction(TYPES.SET_REPORTS_PENDING);
const setTimelineReportData = createAction(TYPES.SET_TIMELINE_REPORT_DATA);
const setTimelineReportPending = createAction(TYPES.SET_TIMELINE_REPORT_PENDING);

export const getAds = (params) => async (dispatch) => {
  dispatch(setAdsPending(true));
  try {
    const data = await marketingApi.getAds(
      queryString.stringify({
        ...params,
      }),
    );
    dispatch(setAdsData(data));
    return data;
  } catch (error) {
    console.log('[GET ADS DATA ERROR]', error);
    throw error;
  } finally {
    dispatch(setAdsPending(false));
  }
};

export const createAd = (data) => async (dispatch) => {
  dispatch(setAdsPending(true));
  try {
    const ad = await marketingApi.createAd(data);
    dispatch(getAds());
    return ad;
  } catch (error) {
    console.log('[CREATE AD ERROR]', error);
    throw error;
  } finally {
    dispatch(setAdsPending(false));
  }
};

export const updateAd = (data) => async (dispatch) => {
  dispatch(setAdsPending(true));
  try {
    const ad = await marketingApi.updateAd(data);
    dispatch(getAds());
    return ad;
  } catch (error) {
    console.log('[UPDATE AD ERROR]', error);
    throw error;
  } finally {
    dispatch(setAdsPending(false));
  }
};

export const deleteAd = (id) => async (dispatch) => {
  dispatch(setAdsPending(true));
  try {
    await marketingApi.deleteAd(id);
    dispatch(getAds());
  } catch (error) {
    console.log('[DELETE AD ERROR]', error);
    throw error;
  } finally {
    dispatch(setAdsPending(false));
  }
};

// AD EXPENSES
export const setAdExpensesPage = (page) => async (dispatch) => {
  dispatch(setAdsExpensesPageNumber(page));
};

export const setAdExpensesPageItems = (numb) => async (dispatch) => {
  dispatch(setAdsExpensesItemCount(numb));
};

export const getAdExpenses = (params) => async (dispatch, getState) => {
  dispatch(setAdsExpensesPending(true));

  const {
    marketing: {
      adExpenses: { pageNumber, pageItemCount },
    },
  } = getState();

  try {
    const data = await marketingApi.getAdExpenses(
      queryString.stringify({
        ...params,
        pageNumber,
        pageItemCount,
      }),
    );
    dispatch(setAdsExpensesData(data));
    return data;
  } catch (error) {
    console.log('[GET AD EXPENSES DATA ERROR]', error);
    throw error;
  } finally {
    dispatch(setAdsExpensesPending(false));
  }
};

export const createAdExpense = (data) => async (dispatch) => {
  dispatch(setAdsExpensesPending(true));
  try {
    const adExpense = await marketingApi.createAdExpense(data);
    return adExpense;
  } catch (error) {
    console.log('[CREATE AD EXPENSE ERROR]', error);
    throw error;
  } finally {
    dispatch(setAdsExpensesPending(false));
  }
};

export const updateAdExpense = (data) => async (dispatch) => {
  dispatch(setAdsExpensesPending(true));
  try {
    const ad = await marketingApi.updateAdExpense(data);
    return ad;
  } catch (error) {
    console.log('[UPDATE AD EXPENSE ERROR]', error.message);
    throw error;
  } finally {
    dispatch(setAdsExpensesPending(false));
  }
};

export const deleteAdExpense = (id) => async (dispatch) => {
  dispatch(setAdsExpensesPending(true));
  try {
    await marketingApi.deleteAdExpense(id);
    return true;
  } catch (error) {
    console.log('[DELETE AD EXPENSE ERROR]', error);
    throw error;
  } finally {
    dispatch(setAdsExpensesPending(false));
  }
};

export const getReports = (params) => async (dispatch) => {
  dispatch(setReportsPending(true));
  try {
    const data = await marketingApi.getReports(
      queryString.stringify({
        ...params,
      }),
    );
    dispatch(setReportsData(data));
    return data;
  } catch (error) {
    console.log('[GET REPORTS DATA ERROR]', error);
    throw error;
  } finally {
    dispatch(setReportsPending(false));
  }
};

export const getTimelineReport = (params) => async (dispatch) => {
  dispatch(setTimelineReportPending(true));
  try {
    const data = await marketingApi.getTimelineReport(
      queryString.stringify({
        ...params,
      }),
    );
    dispatch(setTimelineReportData(data));
    return data;
  } catch (error) {
    console.log('[GET TIMELINE REPORT DATA ERROR]', error);
    throw error;
  } finally {
    dispatch(setTimelineReportPending(false));
  }
};
