import React, { useState, useMemo, useEffect, useReducer, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import debounce from 'lodash/debounce';
// import moment from 'moment';
import classNames from 'classnames';
import JSONPretty from 'react-json-pretty';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Grid, Select, InputBase, MenuItem, InputLabel, FormControl } from '@material-ui/core';
import { Search as SearchIcon, Close as CloseIcon } from '@material-ui/icons';
import CircularProgress from '@material-ui/core/CircularProgress';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { Typography } from '../../../../components/Wrappers/Wrappers';
import Widget from '../../../../components/Widget/Widget';
import Table from '../../../../components/Table';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import { ROUTES, NAMES, IDS } from '../../../../constants/routes';
import { ROLES } from '../../../../constants/roles';
import { getQueryParams } from '../../../../utils/query-string';

import { getUserList, getUserFilters, setUserPage, setUserPageItems, setUserSearchQuery } from '../../../../store/apps/clockly/actions';

import useStyles from '../styles';

const DEBOUNCE_DELAY = 1500;

const breadcrumsRoutes = [
  {
    route: ROUTES[IDS.APP],
    name: NAMES[IDS.APP],
  },
];

const formReducer = (state, e) => {
  const { name, value } = e;
  return {
    ...state,
    [name]: {
      ...state[name],
      value,
    },
  };
};

const headCells = [
  { id: 'id', numeric: false, disablePadding: true, label: 'Id' },
  { id: 'deviceId', numeric: false, disablePadding: false, label: 'Device Id(idfv)' },
  { id: 'country', numeric: false, disablePadding: false, label: 'Country' },
  { id: 'deviceLanguage', numeric: false, disablePadding: false, label: 'Device Language' },
  { id: 'paid', numeric: false, disablePadding: false, label: 'Paid' },
  { id: 'email', numeric: false, disablePadding: false, label: 'email' },
  { id: 'createdAt', numeric: false, disablePadding: false, label: 'Created At' },
  { id: 'updatedAt', numeric: false, disablePadding: false, label: 'Updated At' },
];

const multipleFilters = ['deviceLanguage', 'country', 'watchSeries', 'watchCase'];

const ClocklyUser = () => {
  // const navigate = useNavigate();
  const isFieldsRendered = useRef(false);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const queryParams = getQueryParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const classes = useStyles();
  const [isSearchOpen, setSearchOpen] = useState(false);
  const [searchQueryValue, setSearchQueryValue] = useState('');
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('createdAt');
  const [activeRowId, setActiveRowId] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [fields, setFormData] = useReducer(formReducer, {});

  const dispatch = useDispatch();
  const role = useSelector((state) => state.account.role);
  const data = useSelector((state) => state.apps.clockly.user.data);
  const count = useSelector((state) => state.apps.clockly.user.count);
  const searchQuery = useSelector((state) => state.apps.clockly.user.searchQuery);
  const pageNumber = useSelector((state) => state.apps.clockly.user.pageNumber);
  const pageItemCount = useSelector((state) => state.apps.clockly.user.pageItemCount);
  const isPending = useSelector((state) => state.apps.clockly.pending);
  const fieldsData = useSelector((state) => state.apps.clockly.user.filters);
  const isFiltersPending = useSelector((state) => state.apps.clockly.user.filtersPending);

  useEffect(() => {
    if (!_.isEmpty(fieldsData)) {
      if (!isFieldsRendered.current) {
        isFieldsRendered.current = true;

        Object.keys(fieldsData).map((key) => {
          const defaultValue = multipleFilters.includes(key) ? [] : '';
          const queryValue = multipleFilters.includes(key) && !_.isEmpty(queryParams[key]) ? queryParams[key].split(',') : queryParams[key];
          const value = !queryParams[key] ? defaultValue : queryValue;

          setFormData({
            name: key,
            value,
          });

          return key;
        });
      }
    }
  }, [fieldsData, queryParams]);

  useEffect(() => {
    dispatch(setUserPageItems(25));
    dispatch(setUserPage(1));
  }, [searchQuery]);

  useEffect(() => {
    getUserListByQueries();
    dispatch(getUserFilters());
  }, []);

  const debouncedChangeHandler = useMemo(() => debounce((searchValue) => dispatch(setUserSearchQuery(searchValue)), DEBOUNCE_DELAY), []);

  useEffect(
    () => () => {
      debouncedChangeHandler.cancel();
    },
    [debouncedChangeHandler],
  );

  const tableData = useMemo(
    () =>
      data.reduce((acc, i) => {
        const d = _.pick(i, ['id', 'deviceId', 'country', 'deviceLanguage', 'paid', 'email', 'createdAt', 'updatedAt']);
        return [...acc, d];
      }, []),
    [data],
  );

  const modalData = useMemo(() => {
    if (activeRowId) {
      return data.find((t) => t.id === activeRowId);
    }

    return null;
  }, [data, activeRowId]);

  const getUserListByQueries = () => {
    const valuesFields = Object.keys(queryParams).reduce(
      (acc, key) => ({
        ...acc,
        [key]: queryParams[key],
      }),
      {},
    );

    dispatch(getUserList({ ...valuesFields }));
  };

  const handleSearchInputChange = (e) => {
    e.preventDefault();
    const { value } = e.target;
    debouncedChangeHandler(value);
    setSearchQueryValue(value);

    getUserListByQueries();
  };

  const handleClearSearchInput = (e) => {
    e.preventDefault();
    dispatch(setUserSearchQuery(''));
    setSearchQueryValue('');
    getUserListByQueries();
  };

  const handleChange = useCallback((e) => {
    e.preventDefault();
    const { value } = e.target;
    const { name } = e.target;

    setSearchParams((params) => {
      if (!_.isEmpty(value)) {
        params.set(name, value);
      } else {
        params.delete(name);
      }
      return params;
    });

    setFormData({
      name,
      value,
    });
  }, []);

  const handleButtonPress = () => {
    getUserListByQueries();
  };

  const handleButtonResetPress = () => {
    Object.keys(fieldsData).map((key) => {
      setFormData({
        name: key,
        value: multipleFilters.includes(key) ? [] : '',
      });

      setSearchParams((params) => {
        params.delete(key);
        return params;
      });
      return false;
    });

    dispatch(getUserList());
  };

  const renderField = (key, attributes) => {
    const { value } = attributes;

    return (
      <Grid item xs={12} key={key} className={classes.fieldWrap}>
        <FormControl className={classes.formControl} variant="outlined">
          <InputLabel>{key}</InputLabel>
          <Select
            className={classes.field}
            label={key}
            name={key}
            variant="outlined"
            value={value}
            id={key}
            onChange={handleChange}
            autoWidth
            multiple={multipleFilters.includes(key)}
          >
            {!multipleFilters.includes(key) && <MenuItem value="">&nbsp;</MenuItem>}
            {_.isArray(fieldsData[key]) &&
              fieldsData[key].map((item) => {
                const val = _.isBoolean(item) ? JSON.stringify(item) : item;
                return (
                  <MenuItem key={val} value={val}>
                    {val}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </Grid>
    );
  };

  const renderFields = () => (
    <Grid item xs={12} className={classes.form}>
      {Object.keys(fields).map((key) => renderField(key, fields[key]))}
    </Grid>
  );

  const renderFilters = () => {
    if (!isSmall) {
      return renderFields();
    }

    return (
      <Accordion className={classes.accordion}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography className={classes.heading}>Filters</Typography>
        </AccordionSummary>
        <AccordionDetails>{renderFields()}</AccordionDetails>
      </Accordion>
    );
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setUserPageItems(parseInt(event.target.value, 10)));
    dispatch(setUserPage(1));

    getUserListByQueries();
  };

  const handleChangePage = (event, newPage) => {
    if (newPage - 1 < pageNumber) {
      dispatch(setUserPage(newPage + 1));
    }

    if (newPage - 1 > pageNumber) {
      dispatch(setUserPage(newPage - 1));
    }

    getUserListByQueries();
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);
    setActiveRowId(null);
  };

  const handleRowClick = (tr) => {
    console.log('handleRowClick', tr);
    window.open(`${ROUTES[IDS.APP_CLOCKLY_USERS]}/${tr.id}`);
    // navigate(`${ROUTES[IDS.USERS]}/${tr.id}`);
  };

  const handleInfoRowClick = (_id) => {
    setIsOpenModal(true);
    console.log('_id', _id);
    setActiveRowId(_id);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    if (property === 'updatedAt' || property === 'createdAt') {
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    }

    // getUserListByQueries();
  };

  return (
    <>
      <Breadcrumbs links={breadcrumsRoutes} currentRoute={NAMES[IDS.APP_CLOCKLY_USERS]} />
      <Grid container xs={12} className={classes.header} alignItems="center" justifyContent="space-between">
        <Grid container xs={6} className={classes.headerGrid} alignItems="center">
          <Grid item>
            <Typography variant="h5" color="textSecondary" noWrap className={classes.title}>
              Users
            </Typography>
          </Grid>
          <Grid item>
            <div
              className={classNames(classes.search, {
                [classes.searchFocused]: isSearchOpen,
              })}
            >
              {!searchQueryValue ? (
                <div
                  className={classNames(classes.searchIcon, {
                    [classes.searchIconOpened]: isSearchOpen,
                  })}
                  onClick={() => setSearchOpen(!isSearchOpen)}
                >
                  {!isSearchOpen ? (
                    <SearchIcon classes={{ root: classes.headerIcon }} />
                  ) : (
                    <CloseIcon classes={{ root: classes.headerIcon }} />
                  )}
                </div>
              ) : (
                <div className={classNames(classes.searchIcon, classes.clearIcon)} onClick={handleClearSearchInput}>
                  <CloseIcon classes={{ root: classes.headerIcon }} />
                </div>
              )}

              <InputBase
                placeholder="Search..."
                onChange={handleSearchInputChange}
                value={searchQueryValue}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Widget disableWidgetMenu bodyClass={classes.tableTopOverflow}>
          <Grid container item xs={12} className={classes.filters}>
            {isFiltersPending ? (
              <div className={classes.filtersLoaderWrap}>
                <div className={classes.loader}>
                  <CircularProgress size={26} />
                </div>
              </div>
            ) : (
              renderFilters()
            )}
          </Grid>

          <Grid container item xs={12} className={classes.buttons}>
            <Button variant="contained" size="large" color="primary" type="button" className={classes.button} onClick={handleButtonPress}>
              Apply
            </Button>
            <Button
              variant="contained"
              size="large"
              color="secondary"
              type="button"
              className={classes.buttonReset}
              onClick={handleButtonResetPress}
            >
              Reset
            </Button>
          </Grid>
        </Widget>
      </Grid>

      <Grid item xs={12}>
        <Widget noBodyPadding bodyClass={classes.tableOverflow} disableWidgetMenu noHeaderPadding>
          <Table
            headCells={headCells}
            rows={tableData}
            count={count}
            order={order}
            orderBy={orderBy}
            page={pageNumber - 1}
            rowsPerPage={pageItemCount}
            onRequestSort={handleRequestSort}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            onInfoClick={role === ROLES.OWNER && handleInfoRowClick}
            onRowClick={handleRowClick}
          />
          {isPending && (
            <div className={classes.loaderWrap}>
              <div className={classes.loader}>
                <CircularProgress size={26} />
              </div>
            </div>
          )}
        </Widget>
      </Grid>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isOpenModal}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpenModal}>
          <div className={classes.modalContainer}>{!_.isEmpty(modalData) && <JSONPretty id="json-pretty" data={modalData} />}</div>
        </Fade>
      </Modal>
    </>
  );
};

export default ClocklyUser;
