import http from '../utils/http';

const PATH = '/evtrack';

export const getEvents = (query) => http.get(`${PATH}/events?${query}`);

export const getCustomerEvents = (query) => http.get(`${PATH}/events/list?${query}`);

export const getEvent = (name, query) => http.get(`${PATH}/events/${name}?${query}`);

export const getDeviceLogs = (query) => http.get(`${PATH}/device-logs?${query}`);

export const getCustomers = (query) => http.get(`${PATH}/customers/list?${query}`);

export const getInstalls = (query) => http.get(`${PATH}/installs/list?${query}`);
export const getInstallsFilters = () => http.get(`${PATH}/installs/list/filters`);

export const getAllInstalls = () => http.get(`${PATH}/installs/all`);
