import { createAction } from 'redux-actions';
import queryString from 'query-string';

import * as API from '../../../api/apps/clockly';

import * as TYPES from './types';

const setPending = createAction(TYPES.SET_PENDING);
const setPageNumber = createAction(TYPES.SET_PAGE_NUMBER);
const setItemCount = createAction(TYPES.SET_PAGE_ITEM_COUNT);
const setSearchQuery = createAction(TYPES.SET_SEARCH_QUERY);

const setUserListData = createAction(TYPES.SET_USER_LIST_DATA);
const setUserData = createAction(TYPES.SET_USER_DATA);
const setCategoryListData = createAction(TYPES.SET_CATEGORY_LIST_DATA);
const setFaceListData = createAction(TYPES.SET_FACE_LIST_DATA);

export const setActiveCategoryId = createAction(TYPES.SET_ACTIVE_CATEGORY_ID);
const setUserFilters = createAction(TYPES.SET_USER_FILTERS);
const setUserFiltersPending = createAction(TYPES.SET_USER_FILTERS_PENDING);

export const setUserSearchQuery = (value) => async (dispatch) => {
  dispatch(
    setSearchQuery({
      key: 'user',
      value,
    }),
  );
};

export const setUserPage = (value) => async (dispatch) => {
  dispatch(
    setPageNumber({
      key: 'user',
      value,
    }),
  );
};

export const setUserPageItems = (value) => async (dispatch) => {
  dispatch(
    setItemCount({
      key: 'user',
      value,
    }),
  );
};

export const getUserList = (params) => async (dispatch, getState) => {
  const {
    apps: {
      clockly: {
        user: { pageNumber, pageItemCount, searchQuery },
      },
    },
  } = getState();

  dispatch(setPending(true));

  const query = {
    ...params,
  };

  if (searchQuery) {
    query.id = searchQuery;
  }

  query.pageNumber = pageNumber;
  query.pageItemCount = pageItemCount;

  try {
    const data = await API.getUserList(queryString.stringify(query));
    return dispatch(setUserListData(data));
  } catch (error) {
    console.log('[ERROR GET USER LIST]', error);
    throw error;
  } finally {
    dispatch(setPending(false));
  }
};

export const getUserData = (id) => async (dispatch) => {
  dispatch(setPending(true));

  try {
    const data = await API.getUserData(id);
    console.log('data', data);
    dispatch(setUserData(data));
    return data;
  } catch (error) {
    console.log('[GET USER DATA ERROR]', error);
    throw error;
  } finally {
    dispatch(setPending(false));
  }
};

export const updateUserData = (data) => async (dispatch) => {
  dispatch(setPending(true));

  try {
    const res = await API.setUserData(data);
    dispatch(setUserData(res));
    return data;
  } catch (error) {
    console.log('[UPDATE USER DATA ERROR]', error);
    throw error;
  } finally {
    dispatch(setPending(false));
  }
};

export const getUserFilters = () => async (dispatch) => {
  dispatch(setUserFiltersPending(true));

  try {
    const data = await API.getUserFiltes();
    dispatch(setUserFilters(data));
    return data;
  } catch (error) {
    console.log('[GET USER FILTERS ERROR]', error);
    dispatch(setUserFilters({}));
    throw error;
  } finally {
    dispatch(setUserFiltersPending(false));
  }
};

export const getCategoryList = () => async (dispatch) => {
  dispatch(setPending(true));

  try {
    const data = await API.getCategoryList();
    return dispatch(setCategoryListData(data));
  } catch (error) {
    console.log('[ERROR GET CATEGORY LIST]', error);
    throw error;
  } finally {
    dispatch(setPending(false));
  }
};

export const saveCategoryListOrders = (ordersList) => async (dispatch) => {
  try {
    await API.setCategoryListOrders(ordersList);
    const data = await API.getCategoryList();
    return dispatch(setCategoryListData(data));
  } catch (error) {
    console.log('[ERROR SET CATEGORY LIST ORDERS]', error);
    throw error;
  }
};

export const createCategory = (data) => async (dispatch) => {
  dispatch(setPending(true));

  try {
    await API.createCategory(data);
    return true;
  } catch (error) {
    console.log('[ERROR CREATE CATEGORY]', error);
    throw error;
  } finally {
    dispatch(setPending(false));
  }
};

export const updateCategory = (data) => async (dispatch) => {
  dispatch(setPending(true));

  try {
    await API.updateCategory(data);
    return true;
  } catch (error) {
    console.log('[ERROR UPDATE CATEGORY]', error);
    throw error;
  } finally {
    dispatch(setPending(false));
  }
};

export const deleteCategory = (data) => async (dispatch) => {
  dispatch(setPending(true));

  try {
    await API.deleteCategory(data);
    return true;
  } catch (error) {
    console.log('[ERROR DELETE CATEGORY]', error);
    throw error;
  } finally {
    dispatch(setPending(false));
  }
};

export const setFaceSearchQuery = (value) => async (dispatch) => {
  dispatch(
    setSearchQuery({
      key: 'face',
      value,
    }),
  );
};

export const setFacePage = (value) => async (dispatch) => {
  dispatch(
    setPageNumber({
      key: 'face',
      value,
    }),
  );
};

export const setFacePageItems = (value) => async (dispatch) => {
  dispatch(
    setItemCount({
      key: 'face',
      value,
    }),
  );
};

export const getFaceList =
  ({ withoutPagination, withoutPending }) =>
  async (dispatch, getState) => {
    const {
      apps: {
        clockly: {
          face: { pageNumber, pageItemCount, activeCategoryId, searchQuery },
        },
      },
    } = getState();

    if (!withoutPending) {
      dispatch(setPending(true));
    }

    const query = {};

    if (searchQuery) {
      query.text = searchQuery;
    } else if (activeCategoryId) {
      query.category_id = activeCategoryId;
    }

    if (!withoutPagination) {
      query.pageNumber = pageNumber;
      query.pageItemCount = pageItemCount;
    }

    try {
      const data = await API.getFaceList(queryString.stringify(query));
      return dispatch(setFaceListData(data));
    } catch (error) {
      console.log('[ERROR GET FACE LIST]', error);
      throw error;
    } finally {
      dispatch(setPending(false));
    }
  };

export const saveFaceListOrders = (ordersList) => async (dispatch) => {
  try {
    await API.setFaceListOrders(ordersList);
    return dispatch(getFaceList({ withoutPagination: true, withoutPending: true }));
  } catch (error) {
    console.log('[ERROR SET FACE LIST ORDERS]', error);
    throw error;
  }
};

export const createFace = (data) => async (dispatch) => {
  dispatch(setPending(true));

  try {
    await API.createFace(data);
    return true;
  } catch (error) {
    console.log('[ERROR CREATE FACE]', error);
    throw error;
  } finally {
    dispatch(setPending(false));
  }
};

export const updateFace = (data) => async (dispatch) => {
  dispatch(setPending(true));

  try {
    await API.updateFace(data);
    return true;
  } catch (error) {
    console.log('[ERROR UPDATE FACE]', error);
    throw error;
  } finally {
    dispatch(setPending(false));
  }
};

export const deleteFace = (data) => async (dispatch) => {
  dispatch(setPending(true));

  try {
    await API.deleteFace(data);
    return true;
  } catch (error) {
    console.log('[ERROR DELETE FACE]', error);
    throw error;
  } finally {
    dispatch(setPending(false));
  }
};
