import { SIGN_OUT } from '../../auth/types';
import * as TYPES from './types';

export const initialState = {
  pending: false,
  background: {
    data: [],
    count: 0,
  },
  category: {
    data: [],
    count: 0,
  },
  collection: {
    data: [],
    category_list: [],
    count: 0,
  },
  message: {
    data: [],
    count: 0,
    searchQuery: '',
    pageNumber: 1,
    pageItemCount: 25,
    activeCollectionId: '',
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.SET_PENDING:
      return {
        ...state,
        pending: action.payload,
      };

    case TYPES.SET_ACTIVE_COLLECTION_ID:
      return {
        ...state,
        message: {
          ...state.message,
          activeCollectionId: action.payload,
        },
      };

    case TYPES.SET_BACKGROUND_LIST_DATA:
      return {
        ...state,
        background: {
          ...state.background,
          ...action.payload,
        },
      };

    case TYPES.SET_CATEGORY_LIST_DATA:
      return {
        ...state,
        category: {
          ...state.category,
          ...action.payload,
        },
      };

    case TYPES.SET_COLLECTION_LIST_DATA:
      return {
        ...state,
        collection: {
          ...state.collection,
          ...action.payload,
        },
      };

    case TYPES.SET_MESSAGE_LIST_DATA:
      return {
        ...state,
        message: {
          ...state.message,
          ...action.payload,
        },
      };

    case TYPES.SET_SEARCH_QUERY:
      return {
        ...state,
        message: {
          ...state.message,
          searchQuery: action.payload,
        },
        pending: true,
      };

    case TYPES.SET_PAGE_NUMBER:
      return {
        ...state,
        message: {
          ...state.message,
          pageNumber: action.payload,
        },
      };

    case TYPES.SET_PAGE_ITEM_COUNT:
      return {
        ...state,
        message: {
          ...state.message,
          pageItemCount: action.payload,
        },
      };

    case SIGN_OUT:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

reducer.blacklist = [];
reducer.whitelist = [];

export default reducer;
