import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Typography } from '../../../../components/Wrappers/Wrappers';

export default function ModalImage({ onClose, item }) {
  const classes = useStyles();

  const { url, previewUrl } = item;

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in>
        <div className={classes.container}>
          <img src={url} className={classes.img} width={393} height={852} />

          {!!previewUrl && <img src={previewUrl} className={classes.previeImg} width={323} height={87} />}
        </div>
      </Fade>
    </Modal>
  );
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    backgroundColor: '#fff',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    minWidth: 393,
    minHeight: 640,
    maxHeight: '90%',
    overflow: 'scroll',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  img: {
    marginTop: 250,
  },
  previeImg: {
    objectFit: 'cover',
    marginTop: theme.spacing(2),
  },
}));
