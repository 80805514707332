import { createAction } from 'redux-actions';
import queryString from 'query-string';

import {
  SET_EVENTS_PAGE_NUMBER,
  SET_PENDING,
  SET_EVENTS_PAGE_ITEM_COUNT,
  SET_DEVICE_LOGS_DATA,
  RESET_DEVICE_LOGS_DATA,
  SET_EVENTS_DATA,
  SET_CUSTOMER_EVENTS_DATA,
  SET_CUSTOMER_EVENTS_PAGE_NUMBER,
  SET_CUSTOMER_EVENTS_PAGE_ITEM_COUNT,
  SET_CUSTOMERS_DATA,
  SET_CUSTOMERS_PAGE_NUMBER,
  SET_CUSTOMERS_PAGE_ITEM_COUNT,
  SET_INSTALLS_DATA,
  SET_INSTALLS_PAGE_NUMBER,
  SET_INSTALLS_PAGE_ITEM_COUNT,
  SET_EVENT_DATA,
  SET_ALL_INSTALLS,
  SET_INSTALLS_FILTERS,
} from './types';
import * as evtrackApi from '../../api/evtrack';

export const setPending = createAction(SET_PENDING);
const setEventsPageNumber = createAction(SET_EVENTS_PAGE_NUMBER);
const setEventsPageItemCount = createAction(SET_EVENTS_PAGE_ITEM_COUNT);
const setDeviceLogsData = createAction(SET_DEVICE_LOGS_DATA);
export const resetDeviceLogsData = createAction(RESET_DEVICE_LOGS_DATA);
const setEventsData = createAction(SET_EVENTS_DATA);
const setCustomerEventsData = createAction(SET_CUSTOMER_EVENTS_DATA);
const setCustomerEventsPageNumber = createAction(SET_CUSTOMER_EVENTS_PAGE_NUMBER);
const setCustomerEventsPageItemCount = createAction(SET_CUSTOMER_EVENTS_PAGE_ITEM_COUNT);
const setCustomersData = createAction(SET_CUSTOMERS_DATA);
const setCustomersPageNumber = createAction(SET_CUSTOMERS_PAGE_NUMBER);
const setCustomersPageItemCount = createAction(SET_CUSTOMERS_PAGE_ITEM_COUNT);
const setInstallsData = createAction(SET_INSTALLS_DATA);
const setInstallsFilters = createAction(SET_INSTALLS_FILTERS);
const setInstallsPageNumber = createAction(SET_INSTALLS_PAGE_NUMBER);
const setInstallsPageItemCount = createAction(SET_INSTALLS_PAGE_ITEM_COUNT);
const setEventData = createAction(SET_EVENT_DATA);
const setAllInstalls = createAction(SET_ALL_INSTALLS);

export const getEvents = () => async (dispatch, getState) => {
  dispatch(setPending(true));
  const {
    evtrack: {
      events: { pageNumber, pageItemCount },
    },
  } = getState();
  try {
    const params = {
      pageNumber,
      pageItemCount,
    };

    const data = await evtrackApi.getEvents(queryString.stringify(params));
    dispatch(setEventsData(data));
    return data;
  } catch (error) {
    console.log('[GET EVENTS ERROR]', error);
    throw error;
  } finally {
    dispatch(setPending(false));
  }
};

export const setPage = (page) => async (dispatch) => {
  dispatch(setEventsPageNumber(page));
};

export const setPageItems = (numb) => async (dispatch) => {
  dispatch(setEventsPageItemCount(numb));
};

export const getCustomerEvents = (params) => async (dispatch, getState) => {
  dispatch(setPending(true));
  const {
    evtrack: {
      deviceLogs: {
        events: { pageNumber, pageItemCount },
      },
    },
  } = getState();

  try {
    const data = await evtrackApi.getCustomerEvents(
      queryString.stringify({
        ...params,
        pageNumber,
        pageItemCount,
      }),
    );

    dispatch(setCustomerEventsData(data));
  } catch (error) {
    console.log('[GET CUSTOMER EVENTS ERROR]', error);
    throw error;
  } finally {
    dispatch(setPending(false));
  }
};

export const getEvent = (name, params) => async (dispatch) => {
  dispatch(setPending(true));

  try {
    const data = await evtrackApi.getEvent(
      name,
      queryString.stringify({
        ...params,
      }),
    );

    dispatch(setEventData(data));
  } catch (error) {
    console.log('[GET EVENT ERROR]', error);
    throw error;
  } finally {
    dispatch(setPending(false));
  }
};

export const setCustomerPage = (page) => async (dispatch) => {
  dispatch(setCustomerEventsPageNumber(page));
};

export const setCustomerPageItems = (numb) => async (dispatch) => {
  dispatch(setCustomerEventsPageItemCount(numb));
};

export const getDeviceLogs = (params) => async (dispatch) => {
  dispatch(setPending(true));

  try {
    const data = await evtrackApi.getDeviceLogs(queryString.stringify(params));

    dispatch(setDeviceLogsData(data));
  } catch (error) {
    console.log('[GET DEVICE LOGS ERROR]', error);
    throw error;
  } finally {
    dispatch(setPending(false));
  }
};

export const setCustomersPage = (page) => async (dispatch) => {
  dispatch(setCustomersPageNumber(page));
};

export const setCustomersPageItems = (numb) => async (dispatch) => {
  dispatch(setCustomersPageItemCount(numb));
};

export const getCustomers = (params) => async (dispatch, getState) => {
  dispatch(setPending(true));
  const {
    evtrack: {
      customers: { pageNumber, pageItemCount },
    },
  } = getState();

  try {
    const data = await evtrackApi.getCustomers(
      queryString.stringify({
        ...params,
        pageNumber,
        pageItemCount,
      }),
    );

    dispatch(setCustomersData(data));
  } catch (error) {
    console.log('[GET CUSTOMERS ERROR]', error);
    throw error;
  } finally {
    dispatch(setPending(false));
  }
};

export const setInstallsPage = (page) => async (dispatch) => {
  dispatch(setInstallsPageNumber(page));
};

export const setInstallsPageItems = (numb) => async (dispatch) => {
  dispatch(setInstallsPageItemCount(numb));
};

export const getInstalls = (params) => async (dispatch, getState) => {
  dispatch(setPending(true));
  const {
    evtrack: {
      installs: { pageNumber, pageItemCount },
    },
  } = getState();

  try {
    const data = await evtrackApi.getInstalls(
      queryString.stringify({
        ...params,
        pageNumber,
        pageItemCount,
      }),
    );

    dispatch(setInstallsData(data));
  } catch (error) {
    console.log('[GET INSTALLS ERROR]', error);
    throw error;
  } finally {
    dispatch(setPending(false));
  }
};

export const getInstallsFilters = () => async (dispatch) => {
  try {
    const data = await evtrackApi.getInstallsFilters();
    dispatch(setInstallsFilters(data));
  } catch (error) {
    console.log('[GET INSTALLS FILTERS ERROR]', error);
    throw error;
  }
};

export const getAllInstalls = () => async (dispatch) => {
  dispatch(setPending(true));

  try {
    const data = await evtrackApi.getAllInstalls();

    dispatch(setAllInstalls(data));
  } catch (error) {
    console.log('[GET ALL INSTALLS ERROR]', error);
    throw error;
  } finally {
    dispatch(setPending(false));
  }
};
