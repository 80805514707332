import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';

import DateFnsUtils from '@date-io/moment';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import { createAdExpense, updateAdExpense } from '../../../../../store/marketing/actions';

import { formatDate } from '../../../../../utils/date';

const TODAY = formatDate();

export default function ModalForm({ isOpen, onClose, onSubmit, selectedAdExpense, adId }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.marketing.adExpenses.data);
  const isPending = useSelector((state) => state.marketing.adExpenses.pending);
  const [amountValue, setAmountValue] = useState('');
  const [commentValue, setCommentValue] = useState('');
  const [dateValue, setDateValue] = useState(TODAY);
  const [isInvalid, setInvalid] = useState(false);

  useEffect(() => {
    if (!_.isEmpty(selectedAdExpense)) {
      if (selectedAdExpense.date) {
        setDateValue(moment(selectedAdExpense.date).format('YYYY-MM-DD'));
      }
      if (selectedAdExpense.amount) {
        setAmountValue(selectedAdExpense.amount);
      }
      if (selectedAdExpense.comment) {
        setCommentValue(selectedAdExpense.comment);
      }
    }
  }, [selectedAdExpense]);

  useEffect(() => {
    if (isOpen && _.isEmpty(selectedAdExpense) && !_.isEmpty(data)) {
      const firstAdExpense = data[0];

      if (firstAdExpense.date) {
        setDateValue(moment(firstAdExpense.date).add(1, 'days').format('YYYY-MM-DD'));
      }
    }
  }, [isOpen]);

  const handleAmountChange = (e) => {
    e.preventDefault();
    setInvalid(false);
    setAmountValue(e.target.value);
  };

  const handleDateChange = (newValue) => {
    const date = formatDate(newValue);
    setDateValue(date);
  };

  const handleCommentChange = (e) => {
    e.preventDefault();
    setInvalid(false);
    setCommentValue(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setInvalid(false);

    if (!!+amountValue && dateValue.length) {
      try {
        if (_.isEmpty(selectedAdExpense)) {
          await dispatch(createAdExpense({ adId, amount: +amountValue, comment: commentValue, date: dateValue }));
        } else if (selectedAdExpense._id) {
          await dispatch(updateAdExpense({ _id: selectedAdExpense._id, amount: +amountValue, comment: commentValue, date: dateValue }));
        }
        onSubmit();
      } catch (error) {
        console.log('[CREATE AD EXPENSE MODAL ERROR]', error);
      }
    } else {
      setInvalid(true);
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isOpen}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <div className={classes.container}>
          {isPending ? (
            <CircularProgress size={26} className={classes.loader} />
          ) : (
            <>
              <div className={classes.header}>
                <h2 className={classes.title}>{!_.isEmpty(selectedAdExpense) ? 'Edit Ad Expense' : 'Create Ad Expense'}</h2>
              </div>
              <form className={classes.form} noValidate autoComplete="off" onSubmit={handleSubmit}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} className={classes.datepicker}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="DD/MM/YYYY"
                    margin="none"
                    id="date-picker-inline"
                    label="Date"
                    value={moment(dateValue, 'YYYY-MM-DD').toDate()}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    className={classes.datepickerLeft}
                  />
                </MuiPickersUtilsProvider>

                <TextField
                  placeholder="Enter Amount"
                  name="Amount"
                  label="Amount"
                  error={isInvalid && !+amountValue}
                  value={amountValue}
                  onChange={handleAmountChange}
                  variant="outlined"
                />

                <TextField
                  placeholder="Enter Comment"
                  name="Comment"
                  label="Comment"
                  value={commentValue}
                  onChange={handleCommentChange}
                  variant="outlined"
                />

                <Button variant="contained" size="large" color="primary" type="submit">
                  Submit
                </Button>
              </form>
            </>
          )}
        </div>
      </Fade>
    </Modal>
  );
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    backgroundColor: '#fff',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    minWidth: 500,
    minHeight: 200,
    position: 'relative',
  },
  loader: {
    position: 'absolute',
    top: 'calc(50% - 12px)',
    left: 'calc(50% - 12px)',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  header: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: 20,
    margin: 0,
  },
  headerDeleteIcon: {
    fontSize: 28,
  },
}));
