import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import debounce from 'lodash/debounce';
import moment from 'moment';
import classNames from 'classnames';
import { Grid, InputBase } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import { MoreVert as MoreIcon, Search as SearchIcon, Close as CloseIcon, Edit as EditIcon } from '@material-ui/icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { Typography } from '../../../../components/Wrappers/Wrappers';
import Widget from '../../../../components/Widget/Widget';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import { ROUTES, NAMES, IDS } from '../../../../constants/routes';

import {
  getMessageList,
  saveMessageListOrders,
  getCollectionList,
  createMessage,
  updateMessage,
  deleteMessage,
  setPage,
  setPageItems,
  setActiveCollectionId,
  setSearchQuery,
} from '../../../../store/apps/inspiro/actions';

import Modal from './modal';
import useStyles from '../styles';

const breadcrumsRoutes = [
  {
    route: ROUTES[IDS.APP],
    name: NAMES[IDS.APP],
  },
];

const DEBOUNCE_DELAY = 1500;

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getChangedItems = (originalData, updatedData) => {
  const changedItems = {};

  updatedData.forEach((updatedItem, updatedIndex) => {
    const originalItem = originalData.find((item) => item.id === updatedItem.id);
    if (originalItem) {
      const indexBefore = originalData.indexOf(originalItem);
      const indexAfter = updatedIndex;
      const indexChanged = indexBefore - indexAfter;
      if (indexChanged !== 0) {
        changedItems[updatedItem.id] = indexChanged;
      }
    }
  });

  return changedItems;
};

const LengthItem = ({ row, index, list, onChangeList }) => {
  const classes = useStyles();

  const [isEdit, setIsEdit] = useState(false);
  const [value, setValue] = useState('');

  const handleClick = (e) => {
    e.preventDefault();
    setValue(row.length);
    setIsEdit(true);
  };

  const handleChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    setValue(Number(val));
  };

  const handleButtonPress = (e) => {
    e.preventDefault();

    if (value !== row.length) {
      const changedIndex = list.findIndex((i) => i.length === value);

      if (_.isNumber(changedIndex)) {
        const updatedItems = reorder(list, index, changedIndex);
        const changedItems = getChangedItems(list, updatedItems);
        onChangeList(updatedItems, changedItems);
      }
    }

    setIsEdit(false);
  };

  return (
    <TableCell scope="row" className={classes.cellLength}>
      {isEdit ? (
        <div className={classes.cellLengthEdit}>
          <TextField
            className={classes.cellLengthEditInput}
            value={value}
            onChange={handleChange}
            variant="outlined"
            InputProps={{ inputProps: { min: 1, max: list.length } }}
            type="number"
            size="small"
          />
          <Button disabled={!value} variant="contained" size="small" color="primary" type="button" onClick={handleButtonPress}>
            Save
          </Button>
        </div>
      ) : (
        <Typography size="sm" color="text" colorBrightness="secondary" onClick={handleClick}>
          <div className={classes.cellLengthEditWrap}>
            {row.length}
            <EditIcon className={classes.cellLengthEditIcon} />
          </div>
        </Typography>
      )}
    </TableCell>
  );
};

const InspiroMessage = () => {
  const classes = useStyles();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selected, setSelected] = useState(null);
  const [anchorEls, setAnchorEl] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [isSearchOpen, setSearchOpen] = useState(false);
  const [searchQueryValue, setSearchQueryValue] = useState('');
  const [list, setList] = useState([]);
  const [isDragDisabled, setIsDragDisabled] = useState(true);
  const [tabs, setTabs] = useState([]);

  const dispatch = useDispatch();
  const data = useSelector((state) => state.apps.inspiro.message.data);
  const collectionList = useSelector((state) => state.apps.inspiro.collection.data);
  const searchQuery = useSelector((state) => state.apps.inspiro.message.searchQuery);
  const count = useSelector((state) => state.apps.inspiro.message.count);
  const isPending = useSelector((state) => state.apps.inspiro.pending);
  const pageNumber = useSelector((state) => state.apps.inspiro.message.pageNumber);
  const pageItemCount = useSelector((state) => state.apps.inspiro.message.pageItemCount);

  useEffect(() => {
    dispatch(getCollectionList());
  }, []);

  useEffect(() => {
    setTabs([
      {
        id: 'all_tab',
        name: 'ALL',
      },
      ...collectionList,
    ]);
  }, [collectionList]);

  useEffect(() => {
    setList(data);
  }, [data]);

  useEffect(() => {
    dispatch(setActiveCollectionId(tabs[activeTab]?.id === 'all_tab' ? '' : tabs[activeTab]?.id));
    dispatch(setPageItems(25));
    dispatch(setPage(1));
  }, [activeTab, searchQuery, collectionList]);

  useEffect(() => {
    setTimeout(() => {
      dispatch(getMessageList({ withoutPagination: !isDragDisabled }));
    }, 300);
  }, [activeTab, pageNumber, pageItemCount, searchQuery, isDragDisabled]);

  const tableData = useMemo(
    () =>
      list.reduce((acc, i) => {
        const d = _.pick(i, [
          'id',
          'author',
          'text',
          'short_text',
          'collection_id',
          'video_url',
          'cover_url',
          'like_count',
          'play_amount',
          'share_count',
          'paid',
          'length',
          'createdAt',
          'updatedAt',
        ]);
        return [...acc, d];
      }, []),
    [list],
  );

  const debouncedChangeHandler = useMemo(() => debounce((searchValue) => dispatch(setSearchQuery(searchValue)), DEBOUNCE_DELAY), []);

  useEffect(
    () => () => {
      debouncedChangeHandler.cancel();
    },
    [debouncedChangeHandler],
  );

  const handleSearchInputChange = (e) => {
    e.preventDefault();
    const { value } = e.target;
    debouncedChangeHandler(value);
    setSearchQueryValue(value);
  };

  const handleClearSearchInput = (e) => {
    e.preventDefault();
    dispatch(setSearchQuery(''));
    setSearchQueryValue('');
  };

  const handleEditOrdersButtonPress = () => {
    setIsDragDisabled(!isDragDisabled);
  };

  const handleButtonPress = () => {
    setIsOpenModal(true);
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);

    if (selected) {
      setSelected(null);
    }
  };

  const handleSubmit = async (newData) => {
    try {
      if (_.isEmpty(selected)) {
        await dispatch(createMessage(newData));
      } else if (selected.id) {
        console.log('newData', newData);

        await dispatch(updateMessage({ id: selected.id, ...newData }));
      }

      dispatch(getMessageList());
      setIsOpenModal(false);
    } catch (error) {
      console.log('[ERROR SUBMIT]', error);
    }
  };

  const handleEdit = useCallback(
    (id, e) => {
      e.preventDefault();
      e.stopPropagation();

      const message = list.find((v) => v.id === id);

      anchorEls[id] = null;
      setAnchorEl({
        ...anchorEls,
        [id]: null,
      });

      setSelected(message);
      setIsOpenModal(true);
    },
    [list],
  );

  const handleRemove = useCallback(async (id, e) => {
    e.preventDefault();
    e.stopPropagation();

    if (window.confirm('Are you sure you want to remove this message?')) {
      await dispatch(deleteMessage(id));
      dispatch(getMessageList());
    }

    anchorEls[id] = null;
    setAnchorEl({
      ...anchorEls,
      [id]: null,
    });
  }, []);

  const handleMenuClick = useCallback(
    (id, e) => {
      e.preventDefault();
      e.stopPropagation();

      setAnchorEl({
        ...anchorEls,
        [id]: e.target,
      });
    },
    [anchorEls],
  );

  const handleMenuClose = useCallback(
    (id, e) => {
      e.preventDefault();
      e.stopPropagation();

      anchorEls[id] = null;
      setAnchorEl({
        ...anchorEls,
        [id]: null,
      });
    },
    [anchorEls],
  );

  const handleTabChange = (e, tab) => {
    setActiveTab(tab);
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setPageItems(parseInt(event.target.value, 10)));
    dispatch(setPage(1));
  };

  const handleChangePage = (event, newPage) => {
    if (newPage - 1 < pageNumber) {
      dispatch(setPage(newPage + 1));
    }

    if (newPage - 1 > pageNumber) {
      dispatch(setPage(newPage - 1));
    }
  };

  const handleChangeList = useCallback(async (updatedItems, changedItems) => {
    setList(updatedItems);
    dispatch(saveMessageListOrders(changedItems));
  }, []);

  const handleDragEnd = async ({ destination, source }) => {
    if (!destination) return;

    const updatedItems = reorder(list, source.index, destination.index);
    const changedItems = getChangedItems(list, updatedItems);

    handleChangeList(updatedItems, changedItems);
  };

  return (
    <>
      <Breadcrumbs links={breadcrumsRoutes} currentRoute={NAMES[IDS.APP_INSPIRO_MESSAGE]} />
      <Grid container xs={12} className={classes.header} alignItems="center" justifyContent="space-between">
        <Grid container xs={6} className={classes.headerGrid} alignItems="center">
          <Grid item>
            <Typography variant="h5" color="textSecondary" noWrap className={classes.title}>
              Messages
            </Typography>
          </Grid>
          <Grid item>
            <div
              className={classNames(classes.search, {
                [classes.searchFocused]: isSearchOpen,
              })}
            >
              {!searchQueryValue ? (
                <div
                  className={classNames(classes.searchIcon, {
                    [classes.searchIconOpened]: isSearchOpen,
                  })}
                  onClick={() => (isDragDisabled ? setSearchOpen(!isSearchOpen) : () => {})}
                >
                  {!isSearchOpen ? (
                    <SearchIcon classes={{ root: classes.headerIcon }} />
                  ) : (
                    <CloseIcon classes={{ root: classes.headerIcon }} />
                  )}
                </div>
              ) : (
                <div className={classNames(classes.searchIcon, classes.clearIcon)} onClick={handleClearSearchInput}>
                  <CloseIcon classes={{ root: classes.headerIcon }} />
                </div>
              )}

              <InputBase
                placeholder="Search..."
                onChange={handleSearchInputChange}
                value={searchQueryValue}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
              />
            </div>
          </Grid>
        </Grid>
        <Grid item xs={6} alignItems="flex-end" justifyContent="flex-end">
          <Grid container alignItems="flex-end" justifyContent="flex-end">
            {tabs[activeTab]?.id !== 'all_tab' && (
              <Button
                variant="contained"
                size="large"
                color="inherit"
                type="button"
                onClick={handleEditOrdersButtonPress}
                className={classNames(classes.btnOrders, {
                  [classes.btnOrdersSave]: !isDragDisabled,
                })}
              >
                {isDragDisabled ? 'Edit Position' : 'Done'}
              </Button>
            )}

            <Button disabled={!isDragDisabled} variant="contained" size="large" color="primary" type="button" onClick={handleButtonPress}>
              Add
            </Button>
          </Grid>
        </Grid>
      </Grid>

      {!searchQuery && (
        <Grid container xs={12} className={classes.header} alignItems="center" justifyContent="space-between">
          <Grid item xs={12} className={classes.tabs}>
            <Tabs
              value={activeTab}
              variant="scrollable"
              scrollButtons="auto"
              indicatorColor="primary"
              textColor="primary"
              onChange={handleTabChange}
            >
              {tabs.map((collection) => (
                <Tab key={collection.id} label={collection.name} disabled={!isDragDisabled} />
              ))}
            </Tabs>
          </Grid>
        </Grid>
      )}

      <Grid container spacing={4} className={classes.main}>
        <Grid item xs={12}>
          <Widget disableWidgetMenu noBodyPadding noHeaderPadding bodyClass={classes.tableTopOverflow}>
            <Grid item xs={12}>
              <TableContainer>
                <Table stickyHeader className={classes.table}>
                  <TableHead>
                    <TableRow>
                      {!isDragDisabled && (
                        <TableCell align="left">
                          <Typography size="sm" color="text" colorBrightness="secondary">
                            Position
                          </Typography>
                        </TableCell>
                      )}
                      <TableCell align="left">
                        <Typography size="sm" color="text" colorBrightness="secondary">
                          Text
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography size="sm" color="text" colorBrightness="secondary">
                          Author
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography size="sm" color="text" colorBrightness="secondary">
                          Paid
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography size="sm" color="text" colorBrightness="secondary">
                          Collection
                        </Typography>
                      </TableCell>
                      {/* <TableCell align="left">
                        <Typography size="sm" color="text" colorBrightness="secondary">
                          Video Url
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography size="sm" color="text" colorBrightness="secondary">
                          Cover Url
                        </Typography>
                      </TableCell> */}
                      <TableCell align="left">
                        <Typography size="sm" color="text" colorBrightness="secondary">
                          Like Count
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography size="sm" color="text" colorBrightness="secondary">
                          Play Amount
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography size="sm" color="text" colorBrightness="secondary">
                          Share Count
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography size="sm" color="text" colorBrightness="secondary">
                          Created At
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography size="sm" color="text" colorBrightness="secondary">
                          Updated At
                        </Typography>
                      </TableCell>
                      <TableCell align="right" />
                    </TableRow>
                  </TableHead>
                  {!isPending && (
                    <DragDropContext onDragEnd={handleDragEnd}>
                      <Droppable droppableId="droppable">
                        {(pr) => (
                          <TableBody ref={pr.innerRef} {...pr.droppableProps}>
                            {tableData.map((row, index) => (
                              <Draggable key={row.id} index={index} draggableId={row.id} isDragDisabled={isDragDisabled}>
                                {(provided) => (
                                  <TableRow ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} hover>
                                    {!isDragDisabled && <LengthItem row={row} index={index} list={list} onChangeList={handleChangeList} />}
                                    <TableCell scope="row">
                                      <Typography size="sm" color="text" colorBrightness="secondary">
                                        {`${row.text.slice(0, 30)}...`}
                                      </Typography>
                                    </TableCell>
                                    <TableCell scope="row">
                                      <Typography size="sm" color="text" colorBrightness="secondary">
                                        {row.author?.name || '--'}
                                      </Typography>
                                    </TableCell>
                                    <TableCell scope="row">
                                      <Typography size="sm" color="text" colorBrightness="secondary">
                                        {JSON.stringify(row.paid)}
                                      </Typography>
                                    </TableCell>
                                    <TableCell scope="row">
                                      <Typography size="sm" color="text" colorBrightness="secondary">
                                        {row.collection_id}
                                      </Typography>
                                    </TableCell>
                                    {/* <TableCell scope="row">
                                      <Typography size="sm" color="text" colorBrightness="secondary">
                                        {`${row.video_url.slice(0, 30)}...`}
                                      </Typography>
                                    </TableCell>
                                    <TableCell scope="row">
                                      <Typography size="sm" color="text" colorBrightness="secondary">
                                        {`${row.cover_url.slice(0, 30)}...`}
                                      </Typography>
                                    </TableCell> */}
                                    <TableCell scope="row">
                                      <Typography size="sm" color="text" colorBrightness="secondary">
                                        {row.like_count}
                                      </Typography>
                                    </TableCell>
                                    <TableCell scope="row">
                                      <Typography size="sm" color="text" colorBrightness="secondary">
                                        {row.play_amount}
                                      </Typography>
                                    </TableCell>
                                    <TableCell scope="row">
                                      <Typography size="sm" color="text" colorBrightness="secondary">
                                        {row.share_count}
                                      </Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                      <Typography size="sm" color="text" colorBrightness="secondary">
                                        {moment(row.createdAt).format('YYYY-MM-DD h:mm:ss')}
                                      </Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                      <Typography size="sm" color="text" colorBrightness="secondary">
                                        {moment(row.updatedAt).format('YYYY-MM-DD h:mm:ss')}
                                      </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                      <IconButton
                                        aria-label="more"
                                        aria-controls="long-menu"
                                        aria-haspopup="true"
                                        disabled={!isDragDisabled}
                                        onClick={(e) => handleMenuClick(list[index].id, e)}
                                      >
                                        <MoreIcon />
                                      </IconButton>
                                      <Menu
                                        id={list[index].id}
                                        anchorEl={anchorEls[list[index].id]}
                                        keepMounted
                                        open={Boolean(anchorEls[list[index].id])}
                                        onClose={(e) => handleMenuClose(list[index].id, e)}
                                      >
                                        <MenuItem onClick={(e) => handleEdit(list[index].id, e)}>Edit</MenuItem>
                                        <MenuItem onClick={(e) => handleRemove(list[index].id, e)}>Remove</MenuItem>
                                      </Menu>
                                    </TableCell>
                                  </TableRow>
                                )}
                              </Draggable>
                            ))}
                            {pr.placeholder}
                          </TableBody>
                        )}
                      </Droppable>
                    </DragDropContext>
                  )}
                </Table>
              </TableContainer>
              {isDragDisabled && (
                <TablePagination
                  rowsPerPageOptions={[25, 50, 100]}
                  component="div"
                  count={count}
                  rowsPerPage={pageItemCount}
                  page={pageNumber - 1}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              )}

              {isPending && (
                <div className={classes.loaderWrap}>
                  <div className={classes.loader}>
                    <CircularProgress size={26} />
                  </div>
                </div>
              )}
            </Grid>
          </Widget>
        </Grid>
      </Grid>

      {isOpenModal && (
        <Modal
          isOpen={isOpenModal}
          onClose={handleCloseModal}
          onSubmit={handleSubmit}
          selected={selected}
          collectionList={collectionList}
        />
      )}
    </>
  );
};

export default InspiroMessage;
