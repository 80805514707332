import React, { useState, useCallback } from 'react';
import copyToClipboard from 'copy-to-clipboard';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const LinkCopyButton = ({ link }) => {
  const classes = useStyles();
  const [copied, setCopied] = useState(false);

  const handleCopyClick = useCallback(() => {
    copyToClipboard(link);
    setCopied(true);
  }, [link]);

  return (
    <Button variant="contained" size="large" type="button" onClick={handleCopyClick} className={classes.button}>
      {copied ? 'Copied Link ✓' : 'Copy Link ❯'}
    </Button>
  );
};

export default LinkCopyButton;

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: theme.palette.success.light,
    color: '#fff',

    '&:hover': {
      backgroundColor: theme.palette.success.main,
    },
  },
  buttonTitle: {
    // marginBottom: theme.spacing(3),
    // theme.palette.primary.main
  },
}));
