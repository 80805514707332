import { SIGN_OUT } from '../auth/types';

import * as TYPES from './types';

export const initialState = {
  list: [],
  pending: false,
  selectedProject: null,
  selectedProjectId: null,
  isEditModalShow: false,
  products: {
    data: [],
    count: 0,
    pending: false,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.SET_PROJECTS:
      return {
        ...state,
        list: action.payload,
      };

    case TYPES.SET_PENDING:
      return {
        ...state,
        pending: action.payload,
      };

    case TYPES.SET_SELECTED_PROJECT:
      return {
        ...state,
        selectedProject: state.list?.find((p) => p._id === action.payload),
        selectedProjectId: action.payload,
      };

    case TYPES.SET_EDIT_PROJECT_MODAL_SHOW:
      return {
        ...state,
        isEditModalShow: action.payload,
      };

    case TYPES.SET_PRODUCTS_PENDING:
      return {
        ...state,
        products: {
          ...state.products,
          pending: action.payload,
        },
      };

    case TYPES.SET_PRODUCTS:
      return {
        ...state,
        products: {
          ...state.products,
          data: action.payload.data,
          count: action.payload.count,
        },
      };
    case SIGN_OUT:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
