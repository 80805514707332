export const IDS = {
  OVERVIEW: 'OVERVIEW',
  APP: 'APP',
  PRODUCTS: 'PRODUCTS',
  APP_INSPIRO: 'APP_INSPIRO',
  APP_INSPIRO_CATEGORY: 'APP_INSPIRO_CATEGORY',
  APP_INSPIRO_COLLECTION: 'APP_INSPIRO_COLLECTION',
  APP_INSPIRO_MESSAGE: 'APP_INSPIRO_MESSAGE',
  APP_INSPIRO_BACKGROUND: 'APP_INSPIRO_BACKGROUND',
  APP_INSPIRO_CONTENT_UPLOAD: 'APP_INSPIRO_CONTENT_UPLOAD',
  APP_INSPIRO_CONTENT_PREVIEW: 'APP_INSPIRO_CONTENT_PREVIEW',
  APP_CLOCKLY: 'APP_CLOCKLY',
  APP_CLOCKLY_USERS: 'APP_CLOCKLY_USERS',
  APP_CLOCKLY_USER: 'APP_CLOCKLY_USER',
  APP_CLOCKLY_CATEGORY: 'APP_CLOCKLY_CATEGORY',
  APP_CLOCKLY_FACE: 'APP_CLOCKLY_FACE',
  APP_CLOCKLY_CONTENT_UPLOAD: 'APP_CLOCKLY_CONTENT_UPLOAD',
  APP_CLOCKLY_CONTENT_PREVIEW: 'APP_CLOCKLY_CONTENT_PREVIEW',
  SUBTRACK: 'SUBTRACK',
  SUBTRACK_DASHBOARD: 'SUBTRACK_DASHBOARD',
  TRANSACTIONS: 'TRANSACTIONS',
  REVENUE: 'REVENUE',
  TRANSACTION: 'TRANSACTION',
  EVTRACK: 'EVTRACK',
  USERS: 'USERS',
  USER: 'USER',
  INSTALLS: 'INSTALLS',
  EVENTS: 'EVENTS',
  EVENT: 'EVENT',
  EVTRACK_DASHBOARD: 'EVTRACK_DASHBOARD',
  DEVICE_LOGS: 'DEVICE_LOGS',
  MESSAGING: 'MESSAGING',
  NOTIFICATION: 'NOTIFICATION',
  NOTIFICATIONS: 'NOTIFICATIONS',
  MARKETING: 'MARKETING',
  MARKETING_DASHBOARD: 'MARKETING_DASHBOARD',
  MARKETING_TIMELINE_REPORT: 'MARKETING_TIMELINE_REPORT',
  MARKETING_ADS: 'MARKETING_ADS',
  MARKETING_AD: 'MARKETING_AD',
  UTILS: 'UTILS',
  UTILS_DEVICES: 'UTILS_DEVICES',
};

export const NAMES = {
  [IDS.OVERVIEW]: 'Overview',
  [IDS.APP]: 'App',
  [IDS.PRODUCTS]: 'Products',
  [IDS.APP_INSPIRO]: 'Dashboard',
  [IDS.APP_INSPIRO_BACKGROUND]: 'Backgrounds',
  [IDS.APP_INSPIRO_CATEGORY]: 'Categories',
  [IDS.APP_INSPIRO_COLLECTION]: 'Collections',
  [IDS.APP_INSPIRO_MESSAGE]: 'Messages',
  [IDS.APP_INSPIRO_CONTENT_UPLOAD]: 'Content Upload',
  [IDS.APP_INSPIRO_CONTENT_PREVIEW]: 'Content Preview',
  [IDS.APP_CLOCKLY]: 'Dashboard',
  [IDS.APP_CLOCKLY_USERS]: 'Users',
  [IDS.APP_CLOCKLY_USER]: 'User',
  [IDS.APP_CLOCKLY_CATEGORY]: 'Categories',
  [IDS.APP_CLOCKLY_FACE]: 'Faces',
  [IDS.APP_CLOCKLY_CONTENT_UPLOAD]: 'Content Upload',
  [IDS.APP_CLOCKLY_CONTENT_PREVIEW]: 'Content Preview',
  [IDS.SUBTRACK]: 'Subtrack',
  [IDS.SUBTRACK_DASHBOARD]: 'Dashboard',
  [IDS.TRANSACTIONS]: 'Transactions',
  [IDS.REVENUE]: 'Revenue',
  [IDS.TRANSACTION]: 'Transaction',
  [IDS.EVTRACK]: 'Evtrack',
  [IDS.USERS]: 'Users',
  [IDS.USER]: 'User',
  [IDS.INSTALLS]: 'Installs',
  [IDS.EVENTS]: 'Events',
  [IDS.EVENT]: 'Event',
  [IDS.EVTRACK_DASHBOARD]: 'Dashboard',
  [IDS.DEVICE_LOGS]: 'Device Logs',
  [IDS.MESSAGING]: 'Messaging',
  [IDS.NOTIFICATION]: 'Notification',
  [IDS.NOTIFICATIONS]: 'Notifications',
  [IDS.MARKETING]: 'Marketing',
  [IDS.MARKETING_DASHBOARD]: 'Dashboard',
  [IDS.MARKETING_TIMELINE_REPORT]: 'Timeline Report',
  [IDS.MARKETING_ADS]: 'Ads',
  [IDS.MARKETING_AD]: 'Ad',
  [IDS.UTILS_DEVICES]: 'Utils Devices',
};

export const ROOT = '/root';

export const ROUTES = {
  [IDS.OVERVIEW]: '/overview',
  [IDS.APP]: '/app',
  [IDS.PRODUCTS]: '/app/products',
  [IDS.APP_INSPIRO]: '/app/dashboard',
  [IDS.APP_INSPIRO_BACKGROUND]: '/app/backgrounds',
  [IDS.APP_INSPIRO_CATEGORY]: '/app/categories',
  [IDS.APP_INSPIRO_COLLECTION]: '/app/collections',
  [IDS.APP_INSPIRO_MESSAGE]: '/app/messages',
  [IDS.APP_INSPIRO_CONTENT_PREVIEW]: '/app/preview',
  [IDS.APP_INSPIRO_CONTENT_PREVIEW]: '/app/content/preview',
  [IDS.APP_INSPIRO_CONTENT_UPLOAD]: '/app/content/upload',
  [IDS.APP_CLOCKLY]: '/app/dashboard',
  [IDS.APP_CLOCKLY_USERS]: '/app/users',
  [IDS.APP_CLOCKLY_USER]: '/app/users/:id',
  [IDS.APP_CLOCKLY_CATEGORY]: '/app/categories',
  [IDS.APP_CLOCKLY_FACE]: '/app/faces',
  [IDS.APP_CLOCKLY_CONTENT_PREVIEW]: '/app/content/preview',
  [IDS.APP_CLOCKLY_CONTENT_UPLOAD]: '/app/content/upload',
  [IDS.SUBTRACK]: '/subtrack',
  [IDS.SUBTRACK_DASHBOARD]: '/subtrack/dashboard',
  [IDS.REVENUE]: '/subtrack/revenue',
  [IDS.TRANSACTIONS]: '/subtrack/transactions',
  [IDS.TRANSACTION]: '/subtrack/transactions/:id',
  [IDS.EVTRACK]: '/evtrack',
  [IDS.EVTRACK_DASHBOARD]: '/evtrack/dashboard',
  [IDS.USERS]: '/evtrack/users',
  [IDS.USER]: '/evtrack/users/:id',
  [IDS.INSTALLS]: '/evtrack/installs',
  [IDS.EVENTS]: '/evtrack/events',
  [IDS.EVENT]: '/evtrack/events/:id',
  [IDS.DEVICE_LOGS]: '/evtrack/device-logs',
  [IDS.MESSAGING]: '/messaging',
  [IDS.NOTIFICATION]: '/messaging/create',
  [IDS.NOTIFICATIONS]: '/messaging/notifications',
  [IDS.MARKETING]: '/marketing',
  [IDS.MARKETING_DASHBOARD]: '/marketing/dashboard',
  [IDS.MARKETING_TIMELINE_REPORT]: '/marketing/timeline-report',
  [IDS.MARKETING_ADS]: '/marketing/ads',
  [IDS.MARKETING_AD]: '/marketing/ads/:id',
  [IDS.UTILS_DEVICES]: '/utils/devices',
};
