import React, { useState, useEffect, useCallback, useMemo } from 'react';
import _, { debounce } from 'lodash';
import { Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

// import { getEvents, setPage, setPageItems } from '../../../store/evtrack/actions';
// components
import Button from '@material-ui/core/Button';
import Table from '../../../components/Table';
import Widget from '../../../components/Widget/Widget';
import Breadcrumbs from '../../../components/Breadcrumbs';
import { ROUTES, NAMES, IDS } from '../../../constants/routes';
import { ROLES } from '../../../constants/roles';
import Modal from './modal';

const headCells = [
  { id: 'name', numeric: false, disablePadding: true, label: 'Name' },
  { id: 'count', numeric: false, disablePadding: false, label: 'Count' },
  { id: 'unique', numeric: false, disablePadding: false, label: 'Uniq' },
];

const breadcrumsRoutes = [
  {
    route: ROUTES[IDS.EVTRACK],
    name: NAMES[IDS.EVTRACK],
  },
];

const EvtrackDashboard = () => {
  const classes = useStyles();

  // global
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedProjectId = useSelector((state) => state.projects.selectedProjectId);
  const events = useSelector((state) => state.evtrack.events.data);
  const count = useSelector((state) => state.evtrack.events.count);
  const pageNumber = useSelector((state) => state.evtrack.events.pageNumber);
  const pageItemCount = useSelector((state) => state.evtrack.events.pageItemCount);
  const isPending = useSelector((state) => state.evtrack.pending);
  const role = useSelector((state) => state.account.role);
  const [isOpenModal, setIsOpenModal] = useState(false);

  useEffect(() => {
    // dispatch(getEvents(searchValue));
  }, [pageNumber, pageItemCount, selectedProjectId]);

  const tableData = useMemo(
    () =>
      events.reduce((acc, i) => {
        const d = _.pick(i, ['name', 'count', 'unique']);

        return [...acc, d];
      }, []),
    [events],
  );

  const handleRowClick = useCallback((item) => {}, []);

  const handleButtonPress = () => {
    setIsOpenModal(true);
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);
  };

  const handleSubmit = async (body) => {
    try {
      // await dispatch(createProduct(body));
      setIsOpenModal(false);
    } catch (error) {
      console.log('[CREATE PRODUCT MODAL ERROR]', error);
    }
  };

  return (
    <>
      <Grid container xs={12}>
        <Grid item xs={10}>
          <Breadcrumbs links={breadcrumsRoutes} currentRoute={NAMES[IDS.EVTRACK_DASHBOARD]} />
        </Grid>
        {role === ROLES.OWNER && (
          <Grid container xs={2} alignItems="flex-end" justifyContent="flex-end">
            <Button
              variant="contained"
              size="large"
              color="primary"
              disabled
              type="button"
              className={classes.button}
              onClick={handleButtonPress}
            >
              Create
            </Button>
          </Grid>
        )}
      </Grid>

      <Grid container spacing={4} className={classes.main}>
        <Grid item xs={12}>
          <Widget disableWidgetMenu noHeaderPadding noBodyPadding bodyClass={classes.tableTopOverflow}>
            <Grid item xs={12}>
              <Table
                headCells={headCells}
                rows={tableData}
                count={count}
                order="asc"
                orderBy="createdAt"
                page={0}
                rowsPerPage={100}
                onRowClick={handleRowClick}
              />
              {isPending && (
                <div className={classes.loaderWrap}>
                  <div className={classes.loader}>
                    <CircularProgress size={26} />
                  </div>
                </div>
              )}
            </Grid>
          </Widget>
        </Grid>
      </Grid>
      {isOpenModal && <Modal isOpen={isOpenModal} isPending={isPending} onClose={handleCloseModal} onSubmit={handleSubmit} />}
    </>
  );
};

export default EvtrackDashboard;

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  main: {
    marginTop: theme.spacing(2),
  },
  tableTopOverflow: {
    display: 'flex',
    flexDirection: 'column',
  },
  button: {},
  loaderWrap: {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255,255,255,0.7)',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 9,
  },
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));
