import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { Grid } from '@material-ui/core';
import { useNavigate, Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { MoreVert as MoreIcon } from '@material-ui/icons';
import { Typography } from '../../../components/Wrappers/Wrappers';

import Widget from '../../../components/Widget/Widget';
import Breadcrumbs from '../../../components/Breadcrumbs';
import { ROUTES, NAMES, IDS } from '../../../constants/routes';
import { ROLES } from '../../../constants/roles';
import { getAds, deleteAd } from '../../../store/marketing/actions';

import Modal from './modal';

const breadcrumsRoutes = [
  {
    route: ROUTES[IDS.MARKETING],
    name: NAMES[IDS.MARKETING],
  },
];

const MarketingAds = () => {
  const classes = useStyles();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedAd, setSelectedAd] = useState(null);
  const [anchorEls, setAnchorEl] = useState([]);
  // global
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedProjectId = useSelector((state) => state.projects.selectedProjectId);
  const data = useSelector((state) => state.marketing.ads.data);
  const isPending = useSelector((state) => state.marketing.ads.pending);
  const role = useSelector((state) => state.account.role);

  useEffect(() => {
    dispatch(getAds());
  }, [selectedProjectId]);

  const tableData = useMemo(
    () =>
      data.reduce((acc, i) => {
        const d = _.pick(i, ['name', 'mediaSource', 'startedAt', 'createdAt', 'updatedAt']);
        return [...acc, d];
      }, []),
    [data],
  );

  const handleRowClick = useCallback(
    (index) => {
      navigate(`${ROUTES[IDS.MARKETING_ADS]}/${data[index]?._id}`);
    },
    [data],
  );

  const handleButtonPress = () => {
    setIsOpenModal(true);
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);
    setSelectedAd(null);
  };

  const handleSubmit = async () => {
    try {
      handleCloseModal();
    } catch (error) {
      console.log('[CREATE ADS MODAL ERROR]', error);
    }
  };

  const handleAdEdit = useCallback(
    (id, e) => {
      e.preventDefault();
      e.stopPropagation();

      const ad = data.find((a) => a._id === id);

      anchorEls[id] = null;
      setAnchorEl({
        ...anchorEls,
        [id]: null,
      });

      setSelectedAd(ad);
      setIsOpenModal(true);
    },
    [data],
  );

  const handleAdRemove = useCallback((id, e) => {
    e.preventDefault();
    e.stopPropagation();
    if (window.confirm('Are you sure you want to remove this ad?')) {
      if (role === ROLES.OWNER) {
        dispatch(deleteAd(id));
      } else {
        window.alert('You do not have permission to install the game');
      }
    }

    anchorEls[id] = null;
    setAnchorEl({
      ...anchorEls,
      [id]: null,
    });
  }, []);

  const handleMenuClick = useCallback(
    (id, e) => {
      e.preventDefault();
      e.stopPropagation();

      setAnchorEl({
        ...anchorEls,
        [id]: e.target,
      });
    },
    [anchorEls],
  );

  const handleMenuClose = useCallback(
    (id, e) => {
      e.preventDefault();
      e.stopPropagation();

      anchorEls[id] = null;
      setAnchorEl({
        ...anchorEls,
        [id]: null,
      });
    },
    [anchorEls],
  );

  return (
    <>
      <Grid container xs={12}>
        <Grid item xs={10}>
          <Breadcrumbs links={breadcrumsRoutes} currentRoute={NAMES[IDS.MARKETING_ADS]} />
        </Grid>
        <Grid container xs={2} alignItems="flex-end" justifyContent="flex-end">
          <Button variant="contained" size="large" color="primary" type="button" className={classes.button} onClick={handleButtonPress}>
            Create
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={4} className={classes.main}>
        <Grid item xs={12}>
          <Widget disableWidgetMenu noBodyPadding noHeaderPadding bodyClass={classes.tableTopOverflow}>
            <Grid item xs={12}>
              <TableContainer>
                <Table stickyHeader className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">
                        <Typography size="sm" color="text" colorBrightness="secondary">
                          Name
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography size="sm" color="text" colorBrightness="secondary">
                          Media Source
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography size="sm" color="text" colorBrightness="secondary">
                          Started At
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography size="sm" color="text" colorBrightness="secondary">
                          Created At
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography size="sm" color="text" colorBrightness="secondary">
                          Updated At
                        </Typography>
                      </TableCell>
                      <TableCell align="right" />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableData.map((row, index) => (
                      <TableRow
                        key={data[index]._id}
                        hover
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleRowClick(index);
                        }}
                      >
                        <TableCell align="left" scope="row">
                          <Link color="inherit" to={`${ROUTES[IDS.MARKETING_ADS]}/${data[index]?._id}`} className={classes.link}>
                            <Typography color="textSecondary">{row.name}</Typography>
                          </Link>
                        </TableCell>
                        <TableCell align="left" scope="row">
                          <Typography color="textSecondary">{row.mediaSource}</Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography size="sm" color="text" colorBrightness="secondary">
                            {moment(row.startedAt).format('YYYY-MM-DD')}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography size="sm" color="text" colorBrightness="secondary">
                            {moment(row.createdAt).format('YYYY-MM-DD h:mm:ss')}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography size="sm" color="text" colorBrightness="secondary">
                            {moment(row.updatedAt).format('YYYY-MM-DD h:mm:ss')}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <IconButton
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={(e) => handleMenuClick(data[index]._id, e)}
                          >
                            <MoreIcon />
                          </IconButton>
                          <Menu
                            id={data[index]._id}
                            anchorEl={anchorEls[data[index]._id]}
                            keepMounted
                            open={Boolean(anchorEls[data[index]._id])}
                            onClose={(e) => handleMenuClose(data[index]._id, e)}
                          >
                            <MenuItem onClick={(e) => handleAdEdit(data[index]._id, e)}>Edit</MenuItem>
                            <MenuItem onClick={(e) => handleAdRemove(data[index]._id, e)}>Remove</MenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              {isPending && (
                <div className={classes.loaderWrap}>
                  <div className={classes.loader}>
                    <CircularProgress size={26} />
                  </div>
                </div>
              )}
            </Grid>
          </Widget>
        </Grid>
      </Grid>
      {isOpenModal && (
        <Modal isOpen={isOpenModal} isPending={isPending} onClose={handleCloseModal} onSubmit={handleSubmit} selectedAd={selectedAd} />
      )}
    </>
  );
};

export default MarketingAds;

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  main: {
    marginTop: theme.spacing(2),
  },
  tableTopOverflow: {
    display: 'flex',
    flexDirection: 'column',
  },
  button: {},
  loaderWrap: {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255,255,255,0.7)',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 9,
  },
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));
