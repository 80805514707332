import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { Grid } from '@material-ui/core';
import { useNavigate, useSearchParams, Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import DateFnsUtils from '@date-io/moment';
import Button from '@material-ui/core/Button';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import { Typography } from '../../../components/Wrappers';

import Widget from '../../../components/Widget/Widget';
import Breadcrumbs from '../../../components/Breadcrumbs';
import { ROUTES, NAMES, IDS } from '../../../constants/routes';
import { ROLES } from '../../../constants/roles';
import { getReports } from '../../../store/marketing/actions';
import { formatDate } from '../../../utils/date';
import { getQueryParams } from '../../../utils/query-string';
// import Modal from './modal';

const breadcrumsRoutes = [
  {
    route: ROUTES[IDS.MARKETING],
    name: NAMES[IDS.MARKETING],
  },
];

const TABLE_COL_TITLE = {
  installs: 'Installs',
  paidInstalls: 'Paid Installs',
  transactions: 'Subscriptions & Purchases',
  adsExpenses: 'Ads Expenses($)',
  cpi: 'CPI($)',
  cpa: 'CPA($)',
  romi: 'ROMI(%)',
  arpu: 'ARPU($)',
  arppu: 'ARPPU($)',
  revenue: 'Revenue($)',
  grossProfitMargin: 'Gross Profit Magin(%)',
  grossProfit: 'Gross Profit($)',
};

const FROM_DATE = formatDate(moment().subtract(1, 'weeks'));
const TO_DATE = formatDate();
const DEFAULT_REFUNDS = false;

const MarketingDashboard = () => {
  const queryParams = getQueryParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const classes = useStyles();

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [valueDateFrom, setValueDateFrom] = useState(queryParams && queryParams.dateFrom ? queryParams.dateFrom : FROM_DATE);
  const [valueDateTo, setValueDateTo] = useState(queryParams && queryParams.dateTo ? queryParams.dateTo : TO_DATE);
  const [subtractRefundsEnabled, setSubtractRefundsEnabled] = useState(!!queryParams.subtractRefundsEnabled);

  // global
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedProjectId = useSelector((state) => state.projects.selectedProjectId);
  const data = useSelector((state) => state.marketing.reports.data);
  const isPending = useSelector((state) => state.marketing.reports.pending);

  useEffect(() => {
    dispatch(getReports({ dateFrom: valueDateFrom, dateTo: valueDateTo, subtractRefundsEnabled }));
  }, [selectedProjectId]);

  useEffect(() => {
    if (!queryParams.dateFrom) {
      setSearchParams((params) => {
        params.set('dateFrom', FROM_DATE);
        return params;
      });
    }
    if (!queryParams.dateTo) {
      setSearchParams((params) => {
        params.set('dateTo', TO_DATE);
        return params;
      });
    }
  }, [queryParams]);

  const tableData = useMemo(
    () =>
      data.reduce((acc, i) => {
        const d = _.pick(i, [
          'installs',
          'transactions',
          'adsExpenses',
          'cpi',
          'cpa',
          'romi',
          'arpu',
          'arppu',
          'revenue',
          'grossProfitMargin',
          'grossProfit',
        ]);
        return [...acc, d];
      }, []),
    [data],
  );

  // const handleRowClick = useCallback((item) => {}, []);

  const handleButtonPress = () => {
    dispatch(getReports({ dateFrom: valueDateFrom, dateTo: valueDateTo, subtractRefundsEnabled }));
  };

  const handleButtonResetPress = () => {
    setValueDateFrom(FROM_DATE);
    setValueDateTo(TO_DATE);
    setSubtractRefundsEnabled(DEFAULT_REFUNDS);

    setSearchParams((params) => {
      params.set('dateFrom', FROM_DATE);
      params.set('dateTo', TO_DATE);
      params.delete('subtractRefundsEnabled');
      return params;
    });

    dispatch(getReports({ dateFrom: FROM_DATE, dateTo: TO_DATE, subtractRefundsEnabled: DEFAULT_REFUNDS }));
  };

  const handleFromChange = (newValue) => {
    const date = formatDate(newValue);
    setValueDateFrom(date);
    setSearchParams((params) => {
      params.set('dateFrom', date);
      return params;
    });
  };

  const handleToChange = (newValue) => {
    const date = formatDate(newValue);
    setValueDateTo(date);
    setSearchParams((params) => {
      params.set('dateTo', date);
      return params;
    });
  };

  const handleChangeRevenueStatus = (e) => {
    setSubtractRefundsEnabled(e.target.checked);
    setSearchParams((params) => {
      if (e.target.checked) {
        params.set('subtractRefundsEnabled', true);
      } else {
        params.delete('subtractRefundsEnabled');
      }
      return params;
    });
  };

  return (
    <>
      <Grid container xs={12}>
        <Grid item xs={10}>
          <Grid item xs={10}>
            <Breadcrumbs links={breadcrumsRoutes} currentRoute={NAMES[IDS.MARKETING_DASHBOARD]} />
          </Grid>
        </Grid>
        <Grid container xs={2} alignItems="flex-end" justifyContent="flex-end">
          {/* <Button
            variant="contained"
            size="large"
            color="primary"
            type="button"
            className={classes.button}
            onClick={handleChartButtonPress}
          >
            Save
          </Button> */}
        </Grid>
      </Grid>

      <Grid container spacing={4} className={classes.main}>
        <Grid item xs={12}>
          <Widget disableWidgetMenu bodyClass={classes.tableTopOverflow}>
            <Grid container item xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} className={classes.datepicker}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="DD/MM/YYYY"
                  margin="none"
                  id="date-picker-inline"
                  label="Date From"
                  value={moment(valueDateFrom, 'YYYY-MM-DD').toDate()}
                  onChange={handleFromChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  className={classes.datepickerLeft}
                />
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="DD/MM/YYYY"
                  margin="none"
                  id="date-picker-inline"
                  label="Date To"
                  value={moment(valueDateTo, 'YYYY-MM-DD').toDate()}
                  onChange={handleToChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  className={classes.datepickerRight}
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid container item xs={12} className={classes.filters}>
              <FormGroup row className={classes.revenueCheckboxWrap}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={subtractRefundsEnabled}
                      onChange={handleChangeRevenueStatus}
                      name="refund"
                      color="primary"
                      className={classes.revenueCheckbox}
                    />
                  }
                  label="Subtract Refunds"
                />
              </FormGroup>
            </Grid>

            <Grid container item xs={12} className={classes.buttons}>
              <Button variant="contained" size="large" color="primary" type="button" className={classes.button} onClick={handleButtonPress}>
                Apply
              </Button>
              <Button
                variant="contained"
                size="large"
                color="secondary"
                type="button"
                className={classes.buttonReset}
                onClick={handleButtonResetPress}
              >
                Reset
              </Button>
            </Grid>
          </Widget>
        </Grid>

        <Grid item xs={12}>
          <Widget noBodyPadding bodyClass={classes.tableOverflow} disableWidgetMenu noHeaderPadding>
            <Grid item xs={12}>
              <TableContainer>
                <Table stickyHeader className={classes.table}>
                  <TableHead>
                    {!_.isEmpty(tableData) &&
                      Object.keys(tableData[0]).map((key) => (
                        <TableCell align="left" key={key}>
                          <Typography size="sm" color="text" colorBrightness="secondary">
                            {TABLE_COL_TITLE[key]}
                          </Typography>
                        </TableCell>
                      ))}
                  </TableHead>
                  <TableBody>
                    {tableData.map((row, index) => (
                      <TableRow key={row} hover>
                        <TableCell align="left">
                          <Typography size="sm" color="text" colorBrightness="secondary">
                            {row.installs}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography size="sm" color="text" colorBrightness="secondary">
                            {row.transactions}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography size="sm" color="text" colorBrightness="secondary">
                            {row.adsExpenses ? row.adsExpenses : 'N/A'}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography size="sm" color="text" colorBrightness="secondary">
                            {row.cpi ? row.cpi : 'N/A'}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography size="sm" color="text" colorBrightness="secondary">
                            {row.cpa ? row.cpa : 'N/A'}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography size="sm" color="text" colorBrightness="secondary">
                            {row.romi ? row.romi : 'N/A'}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography size="sm" color="text" colorBrightness="secondary">
                            {row.arpu ? row.arpu : 'N/A'}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography size="sm" color="text" colorBrightness="secondary">
                            {row.arppu ? row.arppu : 'N/A'}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography size="sm" color="text" colorBrightness="secondary">
                            {row.revenue ? row.revenue : 'N/A'}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography
                            size="sm"
                            color={!row.grossProfitMargin ? 'grey' : `${Number(row.grossProfitMargin) <= 0 ? 'secondary' : 'success'}`}
                            colorBrightness="main"
                          >
                            {row.grossProfitMargin ? row.grossProfitMargin : 'N/A'}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography
                            size="sm"
                            color={!row.grossProfit ? 'grey' : `${Number(row.grossProfit) <= 0 ? 'secondary' : 'success'}`}
                            colorBrightness="main"
                          >
                            {row.grossProfit ? row.grossProfit : 'N/A'}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              {isPending && (
                <div className={classes.loaderWrap}>
                  <div className={classes.loader}>
                    <CircularProgress size={26} />
                  </div>
                </div>
              )}
            </Grid>
          </Widget>
        </Grid>
      </Grid>
      {/* {isOpenModal && <Modal isOpen={isOpenModal} isPending={isPending} onClose={handleCloseModal} onSubmit={handleSubmit} />} */}
    </>
  );
};

export default MarketingDashboard;

const useStyles = makeStyles((theme) => ({
  main: {
    marginTop: theme.spacing(2),
  },
  tableOverflow: {
    position: 'relative',
  },
  root: {
    width: '100%',
  },
  accordion: {
    width: '100%',
    marginTop: theme.spacing(2),
    '&::before': {
      opacity: 0,
    },
  },
  tableTopOverflow: {
    display: 'flex',
    flexDirection: 'column',
  },
  datepickerLeft: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  datepickerRight: {
    marginLeft: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      marginLeft: 0,
      width: '100%',
    },
  },
  filtersLoaderWrap: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
    marginTop: theme.spacing(2),
    minHeight: theme.spacing(4),
    zIndex: 9999,
  },
  filters: {},
  form: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      flexDirection: 'column',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
    },
  },
  fieldWrap: {
    display: 'flex',
    width: '100%',
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      marginBottom: theme.spacing(2),
    },
  },
  formControl: {
    display: 'flex',
    width: '100%',
  },
  fieldWrapMulti: {
    display: 'flex',
    width: '100%',
    marginTop: theme.spacing(4),
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      marginBottom: theme.spacing(2),
    },
  },
  field: {
    width: '100%',
  },
  revenueCheckbox: {
    marginLeft: 0,
  },
  revenueCheckboxWrap: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  buttons: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  button: {},
  buttonReset: {
    marginLeft: theme.spacing(2),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  loaderWrap: {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255,255,255,0.7)',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 99,
  },
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));
