import http from '../utils/http';

export const getProjects = () => http.get('/projects');
export const getProject = (id) => http.get(`/project/${id}`);

export const createProject = (data) =>
  http.post('/project/create', {
    ...data,
  });

export const updateProject = (data) =>
  http.post('/project/edit', {
    ...data,
  });

// PRODUCTS
export const getProducts = () => http.get('/products');

export const createProduct = (data) =>
  http.post('/product/create', {
    ...data,
  });

export const editProduct = (data) =>
  http.post('/product/edit', {
    ...data,
  });

export const deleteProduct = (id) => http.delete('/product/delete', { data: { id } });
