export const SET_EVENTS_PAGE_NUMBER = 'EVTRACK/SET_EVENTS_PAGE_NUMBER';
export const SET_EVENTS_PAGE_ITEM_COUNT = 'EVTRACK/SET_EVENTS_PAGE_ITEM_COUNT';
export const SET_DEVICE_LOGS_DATA = 'EVTRACK/SET_DEVICE_LOGS_DATA';
export const RESET_DEVICE_LOGS_DATA = 'EVTRACK/RESET_DEVICE_LOGS_DATA';
export const SET_EVENTS_DATA = 'EVTRACK/SET_EVENTS_DATA';
export const SET_CUSTOMER_EVENTS_DATA = 'EVTRACK/SET_CUSTOMER_EVENTS_DATA';
export const SET_CUSTOMER_EVENTS_PAGE_NUMBER = 'EVTRACK/SET_CUSTOMER_EVENTS_PAGE_NUMBER';
export const SET_CUSTOMER_EVENTS_PAGE_ITEM_COUNT = 'EVTRACK/SET_CUSTOMER_EVENTS_PAGE_ITEM_COUNT';
export const SET_CUSTOMERS_DATA = 'EVTRACK/SET_CUSTOMERS';
export const SET_CUSTOMERS_PAGE_NUMBER = 'EVTRACK/SET_CUSTOMERS_PAGE_NUMBER';
export const SET_CUSTOMERS_PAGE_ITEM_COUNT = 'EVTRACK/SET_CUSTOMERS_PAGE_ITEM_COUNT';
export const SET_INSTALLS_DATA = 'EVTRACK/SET_INSTALLS';
export const SET_INSTALLS_FILTERS = 'EVTRACK/SET_INSTALLS_FILTERS';
export const SET_ALL_INSTALLS = 'EVTRACK/SET_ALL_INSTALLS';
export const SET_INSTALLS_PAGE_NUMBER = 'EVTRACK/SET_INSTALLS_PAGE_NUMBER';
export const SET_INSTALLS_PAGE_ITEM_COUNT = 'EVTRACK/SET_INSTALLS_PAGE_ITEM_COUNT';
export const SET_EVENT_DATA = 'EVTRACK/SET_EVENT_DATA';
export const SET_PENDING = 'EVTRACK/SET_PENDING';
