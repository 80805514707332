import queryString from 'query-string';

export const getQueryParams = () => queryString.parse(window.location.search);

export const pushQueryParam = (key, value) => {
  if (window.history.pushState) {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set(key, value);
    const newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${searchParams.toString()}`;
    window.history.pushState({ path: newurl }, '', newurl);
  }
};

export const removeQueryParam = (paramKey) => {
  const url = window.location.href;
  console.log('url', url);
  const r = new URL(url);
  r.searchParams.delete(paramKey);
  const newUrl = r.href;
  console.log('r.href', newUrl);
  window.history.pushState({ path: newUrl }, '', newUrl);
};

export const updateQueryParam = (key, value) => {
  const params = new URLSearchParams({ [key]: value });
  window.history.replace({ pathname: window.location.pathname, search: params.toString() });
};

export const clearQueryParams = () => {
  const newUrl = window.location.origin;
  window.history.pushState({ path: newUrl }, '', newUrl);
};
