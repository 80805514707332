import queryString from 'query-string';
// import http from '../utils/http';
import { BASE_URL } from '../constants/general';

const PATH = '/utils';

// export const getDevices = (query) => http.get(`${PATH}/devices?${query}`);
export const getDevices = (query) => {
  const myHeaders = new Headers();
  myHeaders.append('Accept', 'application/json');
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('x-api-key', process.env.REACT_APP_API_KEY);
  myHeaders.append('Authorization', 'Bearer 359c8e57f651510517e7212720fae78d');

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  return fetch(`${BASE_URL}${PATH}/devices?${queryString.stringify(query)}`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result).data)
    .catch((error) => new Error(error));
};
