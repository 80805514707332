import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import moment from 'moment-timezone';

import { ThemeProvider } from '@material-ui/styles';
import { CssBaseline } from '@material-ui/core';
import * as serviceWorker from './serviceWorker';

import Themes from './themes';

import configureStore from './store';
import { load } from './store/app/actions';
import { init as httpInit } from './utils/http';

window.__DEV__ = !!process.env.REACT_APP_DEV;

moment.tz.setDefault('Europe/Minsk');

export default function (App) {
  const { store, persistor } = configureStore();

  httpInit(store);

  const root = createRoot(document.getElementById('root'));

  root.render(
    <ThemeProvider theme={Themes.default}>
      <CssBaseline />
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </ThemeProvider>,
  );

  store.dispatch(load(persistor));

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.register({
    onUpdate: async (registration) => {
      await registration.unregister();
      window.location.reload();
    },
  });
}
