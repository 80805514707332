import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _, { isEmpty } from 'lodash';
import moment from 'moment';
import JSONPretty from 'react-json-pretty';
import { useParams } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Divider from '@material-ui/core/Divider';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import { Typography } from '../../../../components/Wrappers/Wrappers';
import Widget from '../../../../components/Widget/Widget';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import { ROUTES, NAMES, IDS } from '../../../../constants/routes';
import { ROLES } from '../../../../constants/roles';

import { getUserData, updateUserData } from '../../../../store/apps/clockly/actions';

const breadcrumsRoutes = [
  {
    route: ROUTES[IDS.APP],
    name: NAMES[IDS.APP],
  },
  {
    route: ROUTES[IDS.APP_CLOCKLY_USERS],
    name: NAMES[IDS.APP_CLOCKLY_USERS],
  },
];

const ClocklyUsers = () => {
  const { id } = useParams();
  const classes = useStyles();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [faceIdValue, setValueFaceId] = useState('');
  const [isValid, setIsValid] = useState(true);

  const dispatch = useDispatch();
  const role = useSelector((state) => state.account.role);
  const data = useSelector((state) => state.apps.clockly.user.userData);
  const isPending = useSelector((state) => state.apps.clockly.pending);

  useEffect(() => {
    dispatch(getUserData(id));
  }, [id]);

  const handleCloseModal = () => {
    setIsOpenModal(false);
    setValueFaceId('');
    setIsValid(true);
  };

  const handleButtonPress = () => {
    setIsOpenModal(true);
  };

  const handleValueFaceIdChange = (e) => {
    e.preventDefault();
    const { value } = e.target;
    setValueFaceId(value);

    if (!isValid) {
      setIsValid(true);
    }
  };

  const handleSubmit = () => {
    if (faceIdValue.trim().length >= 5) {
      dispatch(
        updateUserData({
          id,
          purchasedFaceId: faceIdValue.trim(),
        }),
      );

      handleCloseModal();
    } else {
      setIsValid(false);
    }
  };

  return (
    <>
      <Grid container xs={12}>
        <Grid item xs={10} alignItems="center">
          <Breadcrumbs links={breadcrumsRoutes} currentRoute={id} />
        </Grid>
        {role === ROLES.OWNER && (
          <Grid container xs={2} alignItems="flex-start" justifyContent="flex-end">
            <Button variant="contained" size="large" color="primary" type="button" onClick={handleButtonPress}>
              Add Face Id
            </Button>
          </Grid>
        )}
      </Grid>

      <Grid container spacing={4} className={classes.main}>
        <Grid item xs={12}>
          <Widget noBodyPadding disableWidgetMenu noHeaderPadding bodyClass={classes.tableOverflow}>
            {!isEmpty(data) && (
              <>
                <Grid container xs={12} className={classes.row}>
                  <Grid container alignItems="center" xs={4}>
                    <Grid container xs={3} className={classes.leftCol}>
                      <Divider orientation="vertical" flexItem />
                      <Typography size="sm" color="text" colorBrightness="primary" className={classes.text}>
                        ID
                      </Typography>
                    </Grid>

                    <Grid container xs={9} className={classes.col}>
                      <Divider orientation="vertical" flexItem />

                      <Typography size="sm" color="text" colorBrightness="primary" className={classes.text}>
                        {data._id}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container alignItems="center" xs={4}>
                    <Grid container xs={3} className={classes.leftCol}>
                      <Divider orientation="vertical" flexItem />
                      <Typography size="sm" color="text" colorBrightness="primary" className={classes.text}>
                        Device Id(idfv)
                      </Typography>
                    </Grid>

                    <Grid container xs={9} className={classes.col}>
                      <Divider orientation="vertical" flexItem />

                      <Typography size="sm" color="text" colorBrightness="primary" className={classes.text}>
                        {data.deviceId || '-'}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container alignItems="center" xs={4}>
                    <Grid container xs={3} className={classes.leftCol}>
                      <Divider orientation="vertical" flexItem />
                      <Typography size="sm" color="text" colorBrightness="primary" className={classes.text}>
                        UID
                      </Typography>
                    </Grid>

                    <Grid container xs={9} className={classes.col}>
                      <Divider orientation="vertical" flexItem />
                      <Typography size="sm" color="text" colorBrightness="primary" className={classes.text}>
                        {data.uid || '-'}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container xs={12} className={classes.row}>
                  <Grid container alignItems="center" xs={4}>
                    <Grid container xs={3} className={classes.leftCol}>
                      <Divider orientation="vertical" flexItem />
                      <Typography size="sm" color="text" colorBrightness="primary" className={classes.text}>
                        Paid
                      </Typography>
                    </Grid>

                    <Grid container xs={9} className={classes.col}>
                      <Divider orientation="vertical" flexItem />

                      <Typography size="sm" color="text" colorBrightness="primary" className={classes.text}>
                        {data.paid ? 'true' : 'false'}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container alignItems="center" xs={4}>
                    <Grid container xs={3} className={classes.leftCol}>
                      <Divider orientation="vertical" flexItem />
                      <Typography size="sm" color="text" colorBrightness="primary" className={classes.text}>
                        Language
                      </Typography>
                    </Grid>

                    <Grid container xs={9} className={classes.col}>
                      <Divider orientation="vertical" flexItem />

                      <Typography size="sm" color="text" colorBrightness="primary" className={classes.text}>
                        {data.deviceLanguage || '-'}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container alignItems="center" xs={4}>
                    <Grid container xs={3} className={classes.leftCol}>
                      <Divider orientation="vertical" flexItem />
                      <Typography size="sm" color="text" colorBrightness="primary" className={classes.text}>
                        Country
                      </Typography>
                    </Grid>

                    <Grid container xs={9} className={classes.col}>
                      <Divider orientation="vertical" flexItem />

                      <Typography size="sm" color="text" colorBrightness="primary" className={classes.text}>
                        {data.country || '-'}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container xs={12} className={classes.row}>
                  <Grid container alignItems="center" xs={4}>
                    <Grid container xs={3} className={classes.leftCol}>
                      <Divider orientation="vertical" flexItem />
                      <Typography size="sm" color="text" colorBrightness="primary" className={classes.text}>
                        Face Ids
                      </Typography>
                    </Grid>

                    <Grid container xs={9} className={classes.col}>
                      <Divider orientation="vertical" flexItem />

                      <Typography size="sm" color="text" colorBrightness="primary" className={classes.text}>
                        {data.purchasedFaceIds.length ? JSON.stringify(data.purchasedFaceIds) : '-'}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container alignItems="center" xs={4}>
                    <Grid container xs={3} className={classes.leftCol}>
                      <Divider orientation="vertical" flexItem />
                      <Typography size="sm" color="text" colorBrightness="primary" className={classes.text}>
                        Watch Series
                      </Typography>
                    </Grid>

                    <Grid container xs={9} className={classes.col}>
                      <Divider orientation="vertical" flexItem />

                      <Typography size="sm" color="text" colorBrightness="primary" className={classes.text}>
                        {data.watchSeries || '-'}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container alignItems="center" xs={4}>
                    <Grid container xs={3} className={classes.leftCol}>
                      <Divider orientation="vertical" flexItem />
                      <Typography size="sm" color="text" colorBrightness="primary" className={classes.text}>
                        Watch Case
                      </Typography>
                    </Grid>

                    <Grid container xs={9} className={classes.col}>
                      <Divider orientation="vertical" flexItem />

                      <Typography size="sm" color="text" colorBrightness="primary" className={classes.text}>
                        {data.watchCase || '-'}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container xs={12} className={[classes.row, classes.rowLast]}>
                  <Grid container alignItems="center" xs={4}>
                    <Grid container xs={3} className={classes.leftCol}>
                      <Divider orientation="vertical" flexItem />
                      <Typography size="sm" color="text" colorBrightness="primary" className={classes.text}>
                        Time Zone
                      </Typography>
                    </Grid>

                    <Grid container xs={9} className={classes.col}>
                      <Divider orientation="vertical" flexItem />

                      <Typography size="sm" color="text" colorBrightness="primary" className={classes.text}>
                        {_.isNumber(data.timeZone) ? data.timeZone : '-'}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container alignItems="center" xs={4}>
                    <Grid container xs={3} className={classes.leftCol}>
                      <Divider orientation="vertical" flexItem />
                      <Typography size="sm" color="text" colorBrightness="primary" className={classes.text}>
                        Created At
                      </Typography>
                    </Grid>

                    <Grid container xs={9} className={classes.col}>
                      <Divider orientation="vertical" flexItem />

                      {data.createdAt && (
                        <Typography size="sm" color="text" colorBrightness="primary" className={classes.text}>
                          {moment(data.createdAt).format('YYYY-MM-DD h:mm:ss')}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>

                  <Grid container alignItems="center" xs={4}>
                    <Grid container xs={3} className={classes.leftCol}>
                      <Divider orientation="vertical" flexItem />
                      <Typography size="sm" color="text" colorBrightness="primary" className={classes.text}>
                        Updated At
                      </Typography>
                    </Grid>

                    <Grid container xs={9} className={classes.col}>
                      <Divider orientation="vertical" flexItem />

                      {data.updatedAt && (
                        <Typography size="sm" color="text" colorBrightness="primary" className={classes.text}>
                          {moment(data.updatedAt).format('YYYY-MM-DD h:mm:ss')}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                <Divider orientation="horizontal" flexItem />
              </>
            )}

            {isPending && (
              <div className={classes.loaderWrap}>
                <div className={classes.loader}>
                  <CircularProgress size={26} />
                </div>
              </div>
            )}
          </Widget>
        </Grid>
      </Grid>

      {role === ROLES.OWNER && !_.isEmpty(data) && (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Widget noBodyPadding disableWidgetMenu noHeaderPadding bodyClass={classes.tableOverflow}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <Typography className={classes.heading}>Data</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <JSONPretty id="json-pretty" data={data} />
                </AccordionDetails>
              </Accordion>
            </Widget>
          </Grid>
        </Grid>
      )}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isOpenModal}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpenModal}>
          <div className={classes.container}>
            {isPending ? (
              <CircularProgress size={26} className={classes.loader} />
            ) : (
              <>
                <div className={classes.header}>
                  <h2 className={classes.title}>Add Face Id</h2>
                </div>
                <form className={classes.form} noValidate autoComplete="off" onSubmit={handleSubmit}>
                  <TextField
                    placeholder="face_id"
                    name="face_id"
                    label="Face Id"
                    value={faceIdValue}
                    error={!isValid}
                    onChange={handleValueFaceIdChange}
                    variant="outlined"
                  />
                  <Button variant="contained" size="large" color="primary" type="submit" onClick={handleSubmit}>
                    Submit
                  </Button>
                </form>
              </>
            )}
          </div>
        </Fade>
      </Modal>
    </>
  );
};

export default ClocklyUsers;

const useStyles = makeStyles((theme) => ({
  main: {
    marginTop: theme.spacing(2),
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  leftCol: {
    width: '100%',
    backgroundColor: theme.palette.background.default,
  },
  text: {
    padding: theme.spacing(1, 2),
  },
  row: {
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  rowLast: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  col: {
    // border: `1px solid ${theme.palette.divider}`,
  },
  container: {
    backgroundColor: '#fff',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    minWidth: 500,
    minHeight: 200,
    maxHeight: '90%',
    overflowX: 'hidden',
    overflowY: 'scroll',
    position: 'relative',
  },
  loader: {
    position: 'absolute',
    top: 'calc(50% - 12px)',
    left: 'calc(50% - 12px)',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  header: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: 20,
    margin: 0,
  },
  headerDeleteIcon: {
    fontSize: 28,
  },
}));
