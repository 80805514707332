import React, { useState, useEffect, useRef, useReducer } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
import JSONPretty from 'react-json-pretty';
import { Grid, Select, MenuItem, InputLabel, FormControl } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Info as InfoIcon } from '@material-ui/icons';
import DateFnsUtils from '@date-io/moment';
import Button from '@material-ui/core/Button';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import { getEvent } from '../../../store/evtrack/actions';
// components
import PageTitle from '../../../components/PageTitle';
import Widget from '../../../components/Widget';
import ApexChart from '../../../components/Charts/ApexChart';
import ApexLineChart from '../../../components/Charts/ApexLineChart';
import { ROUTES, NAMES, IDS } from '../../../constants/routes';
import Breadcrumbs from '../../../components/Breadcrumbs';
import withRouter from '../../../hooks/with-router';
import { formatDate } from '../../../utils/date';

const breadcrumsRoutes = [
  {
    route: ROUTES[IDS.EVTRACK],
    name: NAMES[IDS.EVTRACK],
  },
  {
    route: ROUTES[IDS.EVENTS],
    name: NAMES[IDS.EVENTS],
  },
];

const FROM_DATE = formatDate(moment().subtract(1, 'months'));
const TO_DATE = formatDate();

const FIELDS = {
  params: {
    label: 'Params',
    type: 'select',
  },
};

const formReducer = (state, e) => {
  const { name, value } = e;
  return {
    ...state,
    [name]: {
      ...state[name],
      value,
    },
  };
};

const initialFields = Object.keys(FIELDS).reduce(
  (acc, key) => ({
    ...acc,
    [key]: {
      ...FIELDS[key],
      name: key,
      value: FIELDS[key]?.value ? FIELDS[key]?.value : '',
    },
  }),
  {},
);

const Event = () => {
  const location = useLocation();
  const event = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
  const classes = useStyles();
  const dispatch = useDispatch();
  const isFieldsRendered = useRef(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [valueFrom, setValueFrom] = useState(FROM_DATE);
  const [valueTo, setValueTo] = useState(TO_DATE);
  const [chartData, setChartData] = useState({});

  const [fields, setFormData] = useReducer(formReducer, initialFields);

  // global
  const selectedProjectId = useSelector((state) => state.projects.selectedProjectId);
  const eventParams = useSelector((state) => state.evtrack.event.params);
  const events = useSelector((state) => state.evtrack.event.data);
  const isPending = useSelector((state) => state.evtrack.pending);

  const handleCloseModal = () => {
    setIsOpenModal(false);
    setModalData({});
  };

  // useEffect(() => {
  //   if (!isFieldsRendered.current && !_.isEmpty(fieldsData)) {
  //     isFieldsRendered.current = true;
  //     Object.keys(fieldsData).map((key) => {
  //       setFormData({
  //         name: key,
  //         value: '',
  //       });
  //       return key;
  //     });
  //   }
  // }, [fieldsData]);

  const getEventByQueries = () => {
    const valuesFields = Object.keys(fields).reduce((acc, key) => {
      if (fields[key].value) {
        return {
          ...acc,
          [key]: fields[key].value,
        };
      }

      return acc;
    }, {});

    dispatch(getEvent(event, { dateFrom: valueFrom, dateTo: valueTo, ...valuesFields }));
  };

  useEffect(() => {
    if (event) {
      getEventByQueries();
    }
  }, [event]);

  const handleParamClick = (data) => {
    // setIsOpenModal(true);
    // setModalData(data);
  };

  const handleButtonPress = () => {
    getEventByQueries();
  };

  const handleButtonResetPress = () => {
    Object.keys(fields).map((key) => {
      setFormData({
        name: key,
        value: '',
      });

      return null;
    });

    setValueFrom(FROM_DATE);
    setValueTo(TO_DATE);
    setChartData({});
    dispatch(getEvent(event, { dateFrom: FROM_DATE, dateTo: TO_DATE }));
  };

  const handleFromChange = (newValue) => {
    const date = formatDate(newValue);

    setValueFrom(date);
  };

  const handleToChange = (newValue) => {
    const date = formatDate(newValue);

    setValueTo(date);
  };
  const handleChartData = (param) => {
    const data = eventParams[param];

    setChartData({
      series: [
        {
          name: 'count',
          data: Object.values(data),
        },
      ],
      categories: Object.keys(data),
    });
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { value } = e.target;
    const { name } = e.target;

    if (name === 'params') {
      handleChartData(value);
    }

    setFormData({
      name,
      value,
    });
  };

  const renderField = (key, attributes) => {
    const { value } = attributes;

    return (
      <Grid item xs={2} key={key} className={classes.fieldWrap}>
        <FormControl className={classes.formControl} variant="outlined">
          <InputLabel>{key}</InputLabel>
          <Select
            className={classes.field}
            label={key}
            name={key}
            variant="outlined"
            value={value}
            id={key}
            onChange={handleChange}
            autoWidth
          >
            {!_.isEmpty(eventParams) &&
              Object.keys(eventParams).map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
    );
  };

  return (
    <>
      <Breadcrumbs links={breadcrumsRoutes} currentRoute={event} />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget disableWidgetMenu bodyClass={classes.tableTopOverflow}>
            <Grid container xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} className={classes.datepicker}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="DD/MM/YYYY"
                  margin="none"
                  id="date-picker-inline"
                  label="Date From"
                  value={moment(valueFrom, 'YYYY-MM-DD').toDate()}
                  onChange={handleFromChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  className={classes.datepickerLeft}
                />
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="DD/MM/YYYY"
                  margin="none"
                  id="date-picker-inline"
                  label="Date To"
                  value={moment(valueTo, 'YYYY-MM-DD').toDate()}
                  onChange={handleToChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  className={classes.datepickerRight}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} className={classes.form}>
              {Object.keys(fields).map((key) => renderField(key, fields[key]))}
            </Grid>

            <Grid item xs={3}>
              <Button variant="contained" size="large" color="primary" type="button" className={classes.button} onClick={handleButtonPress}>
                Apply
              </Button>
              <Button
                variant="contained"
                size="large"
                color="secondary"
                type="button"
                className={classes.buttonReset}
                onClick={handleButtonResetPress}
              >
                Reset
              </Button>
            </Grid>
          </Widget>
        </Grid>

        <Grid item xs={12}>
          <Widget noBodyPadding disableWidgetMenu bodyClass={classes.tableOverflow}>
            <ApexLineChart
              data={{
                series: [
                  {
                    name: 'count',
                    data: Object.values(events),
                  },
                ],
                categories: Object.keys(events),
              }}
            />
            {isPending && (
              <div className={classes.loaderWrap}>
                <div className={classes.loader}>
                  <CircularProgress size={26} />
                </div>
              </div>
            )}
          </Widget>
        </Grid>

        {!_.isEmpty(chartData) && (
          <Grid item xs={12}>
            <Widget title={fields.params?.value} noBodyPadding disableWidgetMenu upperTitle bodyClass={classes.tableOverflow}>
              <ApexChart data={chartData} />
              {isPending && (
                <div className={classes.loaderWrap}>
                  <div className={classes.loader}>
                    <CircularProgress size={26} />
                  </div>
                </div>
              )}
            </Widget>
          </Grid>
        )}
      </Grid>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isOpenModal}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        {/* <Fade in={isOpenModal}>
          <div className={classes.modalContainer}>
            {!_.isEmpty(modalData) && <JSONPretty id="json-pretty" data={modalData} />}
          </div>
        </Fade> */}
      </Modal>
    </>
  );
};

export default withRouter(Event);

const useStyles = makeStyles((theme) => ({
  tableOverflow: {
    position: 'relative',
  },
  root: {
    width: '100%',
  },
  tableTopOverflow: {
    display: 'flex',
    flexDirection: 'column',
  },
  datepickerLeft: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  datepickerRight: {
    marginLeft: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      marginLeft: 0,
      width: '100%',
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(4),
  },
  fieldWrap: {
    display: 'flex',
    width: '100%',
    marginRight: theme.spacing(2),
  },
  formControl: {
    display: 'flex',
    width: '100%',
  },
  field: {},
  datepicker: {},
  button: {
    marginTop: theme.spacing(4),
  },
  buttonReset: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(2),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  loaderWrap: {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255,255,255,0.7)',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 9,
  },
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContainer: {
    backgroundColor: '#fff',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxWidth: '70vw',
    minWidth: '70vw',
    minHeight: 400,
    maxHeight: '70vh',
    overflow: 'scroll',
  },
}));
