import React, { useState, useEffect, useRef, useReducer, useMemo } from 'react';
import { Grid, Select, MenuItem, InputLabel, FormControl } from '@material-ui/core';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import DateFnsUtils from '@date-io/moment';
import Button from '@material-ui/core/Button';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { setEditProjectModalShow } from '../../store/projects/actions';
// components
import Widget from '../../components/Widget/Widget';
import PageTitle from '../../components/PageTitle/PageTitle';
import Table from '../../components/Table';
import { Typography } from '../../components/Wrappers/Wrappers';
import { ROUTES, NAMES, IDS } from '../../constants/routes';
import { ROLES } from '../../constants/roles';
import Breadcrumbs from '../../components/Breadcrumbs';
// import Modal from './modal';

const formReducer = (state, e) => {
  const { name, value } = e;
  return {
    ...state,
    [name]: {
      ...state[name],
      value,
    },
  };
};

const Project = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const role = useSelector((state) => state.account.role);
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const [fields, setFormData] = useReducer(formReducer, {});
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const classes = useStyles();

  // global
  const dispatch = useDispatch();
  const selectedProjectId = useSelector((state) => state.projects.selectedProjectId);
  const projects = useSelector((state) => state.projects.list);
  // const data = useSelector((state) => state.subtrack.products.data);
  const isPending = useSelector((state) => false);

  const selectedProject = useMemo(() => projects.find((p) => p._id === selectedProjectId), [projects, selectedProjectId]);

  useEffect(() => {
    // get products
  }, [selectedProjectId]);

  const handleEditButtonPress = () => {
    dispatch(setEditProjectModalShow(true));
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);
  };

  const handleSubmit = (data) => {
    console.log('data', data);
    setIsOpenModal(false);
    setSelectedProduct(null);
  };

  const handleRowClick = (product) => {
    setSelectedProduct(product);
  };

  return (
    <>
      <PageTitle
        title={selectedProject?.name}
        button={
          role === ROLES.OWNER ? (
            <Button
              variant="contained"
              size="large"
              color="primary"
              type="button"
              className={classes.button}
              onClick={handleEditButtonPress}
            >
              Edit
            </Button>
          ) : null
        }
      />
      <Grid container className={classes.main}>
        <Grid item xs={12}>
          <Widget disableWidgetMenu bodyClass={classes.tableTopOverflow}>
            <Grid item xs={12}>
              {/* <Table
                headCells={headCells}
                rows={tableData}
                count={count}
                order={order}
                orderBy={orderBy}
                page={pageNumber - 1}
                rowsPerPage={pageItemCount}
                onRequestSort={handleRequestSort}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                onInfoClick={handleInfoRowClick}
                onRowClick={handleRowClick}
              />
              {isPending && (
                <div className={classes.loaderWrap}>
                  <div className={classes.loader}>
                    <CircularProgress size={26} />
                  </div>
                </div>
              )} */}
            </Grid>
          </Widget>
        </Grid>
      </Grid>

      {/* <Modal
        selectedProduct={selectedProduct}
        isEdit={false}
        isOpen={isOpenModal}
        isPending={false}
        onClose={handleCloseModal}
        onSubmit={handleSubmit}
      /> */}
    </>
  );
};

export default Project;

const useStyles = makeStyles((theme) => ({
  tableOverflow: {
    position: 'relative',
  },
  root: {
    width: '100%',
  },
  main: {
    marginTop: theme.spacing(2),
  },
  tableTopOverflow: {
    display: 'flex',
    flexDirection: 'column',
  },
  filtersLoaderWrap: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
    marginTop: theme.spacing(2),
    minHeight: theme.spacing(4),
  },
  filters: {},
  form: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      flexDirection: 'column',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
    },
  },
  fieldWrap: {
    display: 'flex',
    width: '100%',
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      marginBottom: theme.spacing(2),
    },
  },
  formControl: {
    display: 'flex',
    width: '100%',
  },
  field: {
    width: '100%',
  },
  buttons: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  button: {},
  buttonReset: {
    marginLeft: theme.spacing(2),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  loaderWrap: {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255,255,255,0.7)',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 9,
  },
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContainer: {
    backgroundColor: '#fff',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxWidth: '70vw',
    minWidth: '70vw',
    minHeight: 400,
    maxHeight: '70vh',
    overflow: 'scroll',
  },
}));
