import { SIGN_OUT } from '../../auth/types';
import * as TYPES from './types';

export const initialState = {
  pending: false,
  user: {
    data: [],
    userData: null,
    count: 0,
    searchQuery: '',
    pageNumber: 1,
    pageItemCount: 25,
    filters: {},
    filtersPending: false,
  },
  category: {
    data: [],
    count: 0,
  },
  face: {
    data: [],
    count: 0,
    searchQuery: '',
    pageNumber: 1,
    pageItemCount: 25,
    activeCategoryId: '',
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.SET_PENDING:
      return {
        ...state,
        pending: action.payload,
      };

    case TYPES.SET_SEARCH_QUERY:
      return {
        ...state,
        [action.payload.key]: {
          ...state[action.payload.key],
          searchQuery: action.payload.value,
        },
        pending: true,
      };

    case TYPES.SET_PAGE_NUMBER:
      return {
        ...state,
        [action.payload.key]: {
          ...state[action.payload.key],
          pageNumber: action.payload.value,
        },
      };

    case TYPES.SET_PAGE_ITEM_COUNT:
      return {
        ...state,
        [action.payload.key]: {
          ...state[action.payload.key],
          pageItemCount: action.payload.value,
        },
      };

    case TYPES.SET_USER_LIST_DATA:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      };

    case TYPES.SET_USER_DATA:
      return {
        ...state,
        user: {
          ...state.user,
          userData: action.payload,
        },
      };

    case TYPES.SET_USER_FILTERS:
      return {
        ...state,
        user: {
          ...state.user,
          filters: action.payload,
        },
      };

    case TYPES.SET_USER_FILTERS_PENDING:
      return {
        ...state,
        user: {
          ...state.user,
          filtersPending: action.payload,
        },
      };

    case TYPES.SET_ACTIVE_CATEGORY_ID:
      return {
        ...state,
        face: {
          ...state.face,
          activeCategoryId: action.payload,
        },
      };

    case TYPES.SET_CATEGORY_LIST_DATA:
      return {
        ...state,
        category: {
          ...state.category,
          ...action.payload,
        },
      };

    case TYPES.SET_FACE_LIST_DATA:
      return {
        ...state,
        face: {
          ...state.face,
          ...action.payload,
        },
      };

    case SIGN_OUT:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

reducer.blacklist = [];
reducer.whitelist = [];

export default reducer;
