import { createAction } from 'redux-actions';
import queryString from 'query-string';
import * as TYPES from './types';
import * as subtrackApi from '../../api/subtrack';

export const setPending = createAction(TYPES.SET_PENDING);
const setTransactionsPageNumber = createAction(TYPES.SET_TRANSACTIONS_PAGE_NUMBER);
const setTransactionsPageItemCount = createAction(TYPES.SET_TRANSACTIONS_PAGE_ITEM_COUNT);
const setTransactionsData = createAction(TYPES.SET_TRANSACTIONS_DATA);
const setActiveTransactionsData = createAction(TYPES.SET_ACTIVE_TRANSACTIONS_DATA);
const setAllTransactionsData = createAction(TYPES.SET_ALL_TRANSACTIONS_DATA);
const setTransactionData = createAction(TYPES.SET_TRANSACTION_DATA);
const setTransactionsFilters = createAction(TYPES.SET_TRANSACTIONS_FILTERS);
const setTransactionsFiltersPending = createAction(TYPES.SET_TRANSACTIONS_FILTERS_PENDING);
const setRevenuesData = createAction(TYPES.SET_REVENUES_DATA);
const setAllRevenuesData = createAction(TYPES.SET_ALL_REVENUES_DATA);
const setRevenuesPageNumber = createAction(TYPES.SET_REVENUES_PAGE_NUMBER);
const setRevenuesPageItemCount = createAction(TYPES.SET_REVENUES_PAGE_ITEM_COUNT);
const setRevenuesPending = createAction(TYPES.SET_REVENUES_PENDING);
const setRevenuesChartData = createAction(TYPES.SET_REVENUES_CHART_DATA);
const setRevenuesChartDataPending = createAction(TYPES.SET_REVENUES_CHART_DATA_PENDING);
const setRevenuesFilters = createAction(TYPES.SET_REVENUES_FILTERS);
const setRevenuesFiltersPending = createAction(TYPES.SET_REVENUES_FILTERS_PENDING);

const setChartsData = createAction(TYPES.SET_CHARTS_DATA);
const setChartsPending = createAction(TYPES.SET_CHARTS_PENDING);

export const setRevenuesPage = (page) => async (dispatch) => {
  dispatch(setRevenuesPageNumber(page));
};

export const setRevenuesPageItems = (numb) => async (dispatch) => {
  dispatch(setRevenuesPageItemCount(numb));
};

export const getRevenuesFilters = () => async (dispatch) => {
  dispatch(setRevenuesFiltersPending(true));

  try {
    const data = await subtrackApi.getRevenuesFiltes();
    dispatch(setRevenuesFilters(data));
    return data;
  } catch (error) {
    console.log('[GET REVENUES FILTERS ERROR]', error);
    dispatch(setRevenuesFilters({}));
    throw error;
  } finally {
    dispatch(setRevenuesFiltersPending(false));
  }
};

export const getRevenuesChartData = (params) => async (dispatch) => {
  dispatch(setRevenuesChartDataPending(true));

  try {
    const data = await subtrackApi.getRevenuesChartData(
      queryString.stringify({
        ...params,
      }),
    );
    dispatch(setRevenuesChartData(data));
    return data;
  } catch (error) {
    console.log('[GET REVENUES CHART DATA ERROR]', error);
    throw error;
  } finally {
    dispatch(setRevenuesChartDataPending(false));
  }
};

export const getRevenues = (params) => async (dispatch, getState) => {
  dispatch(setRevenuesPending(true));

  const {
    subtrack: {
      revenues: { pageNumber, pageItemCount },
    },
  } = getState();

  try {
    const data = await subtrackApi.getRevenues(
      queryString.stringify({
        ...params,
        pageNumber,
        pageItemCount,
      }),
    );
    dispatch(setRevenuesData(data));
    return data;
  } catch (error) {
    console.log('[GET REVENUES DATA ERROR]', error);
    throw error;
  } finally {
    dispatch(setRevenuesPending(false));
  }
};

export const getAllRevenues = () => async (dispatch) => {
  dispatch(setRevenuesPending(true));
  try {
    const data = await subtrackApi.getAllRevenues();
    dispatch(setAllRevenuesData(data));
    return data;
  } catch (error) {
    dispatch(
      setAllRevenuesData({
        allCount: 0,
        todayCount: 0,
      }),
    );
    console.log('[GET ALL REVENUES ERROR]', error);
    throw error;
  } finally {
    dispatch(setRevenuesPending(false));
  }
};

export const getTransactionsFilters = () => async (dispatch) => {
  dispatch(setTransactionsFiltersPending(true));

  try {
    const data = await subtrackApi.getTransactionsFiltes();
    dispatch(setTransactionsFilters(data));
    return data;
  } catch (error) {
    console.log('[GET TRANSACTIONS FILTERS ERROR]', error);
    dispatch(setTransactionsData({}));
    throw error;
  } finally {
    dispatch(setTransactionsFiltersPending(false));
  }
};

export const getTransactions = (params) => async (dispatch, getState) => {
  dispatch(setPending(true));
  const {
    subtrack: {
      transactions: { pageNumber, pageItemCount },
    },
  } = getState();
  try {
    const data = await subtrackApi.getTransactions(
      queryString.stringify({
        ...params,
        pageNumber,
        pageItemCount,
      }),
    );
    dispatch(setTransactionsData(data));
    return data;
  } catch (error) {
    console.log('[GET TRANSACTIONS ERROR]', error);
    dispatch(
      setTransactionsData({
        transactions: [],
        count: 0,
      }),
    );
    throw error;
  } finally {
    dispatch(setPending(false));
  }
};

export const setPage = (page) => async (dispatch) => {
  dispatch(setTransactionsPageNumber(page));
};

export const setPageItems = (numb) => async (dispatch) => {
  dispatch(setTransactionsPageItemCount(numb));
};

export const getTransaction = (id) => async (dispatch) => {
  dispatch(setPending(true));
  try {
    const data = await subtrackApi.getTransaction(id);
    dispatch(setTransactionData(data));
    return data;
  } catch (error) {
    console.log('[GET TRANSACTION ERROR]', error);
    throw error;
  } finally {
    dispatch(setPending(false));
  }
};

export const getAllActiveTransactions = () => async (dispatch) => {
  dispatch(setPending(true));
  try {
    const data = await subtrackApi.getAllActiveTransactions();
    dispatch(setActiveTransactionsData(data));
    return data;
  } catch (error) {
    dispatch(
      setActiveTransactionsData({
        data: [],
        count: 0,
      }),
    );
    console.log('[GET ALL ACTIVE TRANSACTIONS ERROR]', error);
    throw error;
  } finally {
    dispatch(setPending(false));
  }
};

export const getAllTransactions = () => async (dispatch) => {
  dispatch(setPending(true));
  try {
    const data = await subtrackApi.getAllTransactions();
    dispatch(setAllTransactionsData(data));
    return data;
  } catch (error) {
    dispatch(
      setActiveTransactionsData({
        activeCount: 0,
        allCount: 0,
        todayCount: 0,
      }),
    );
    console.log('[GET ALL TRANSACTIONS ERROR]', error);
    throw error;
  } finally {
    dispatch(setPending(false));
  }
};

export const verifyActiveTransactions = () => async (dispatch) => {
  dispatch(setPending(true));
  try {
    const data = await subtrackApi.verifyTransactions();
    dispatch(getAllActiveTransactions());

    return data;
  } catch (error) {
    dispatch(setPending(false));
    console.log('[VERIFY ALL ACTIVE TRANSACTIONS ERROR]', error);
    throw error;
  }
};

export const verifyAllTransactions = () => async (dispatch) => {
  dispatch(setPending(true));
  try {
    const data = await subtrackApi.verifyAllTransactions();
    dispatch(getAllActiveTransactions());

    return data;
  } catch (error) {
    dispatch(setPending(false));
    console.log('[VERIFY ALL TRANSACTIONS ERROR]', error);
    throw error;
  }
};

export const getCharts = () => async (dispatch) => {
  dispatch(setChartsPending(true));
  try {
    const data = await subtrackApi.getCharts();
    dispatch(setChartsData(data));
    return data;
  } catch (error) {
    dispatch(setChartsData([]));
    console.log('[GET CHARTS ERROR]', error);
    throw error;
  } finally {
    dispatch(setChartsPending(false));
  }
};

export const createChart = (data) => async (dispatch) => {
  dispatch(setChartsPending(true));
  try {
    const chart = await subtrackApi.createChart(data);
    dispatch(getCharts());
    return chart;
  } catch (error) {
    console.log('[CREATE CHART ERROR]', error);
    throw error;
  } finally {
    dispatch(setChartsPending(false));
  }
};

export const updateChart = (data) => async (dispatch) => {
  dispatch(setChartsPending(true));
  try {
    const chart = await subtrackApi.updateChart(data);
    dispatch(getCharts());
    return chart;
  } catch (error) {
    console.log('[UPDATE CHART ERROR]', error);
    throw error;
  } finally {
    dispatch(setChartsPending(false));
  }
};

export const deleteChart = (id) => async (dispatch) => {
  dispatch(setChartsPending(true));
  try {
    await subtrackApi.deleteChart(id);
    dispatch(getCharts());
  } catch (error) {
    console.log('[DELETE CHART ERROR]', error);
    throw error;
  } finally {
    dispatch(setChartsPending(false));
  }
};
