import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import inspiro from './inspiro';
import clockly from './clockly';

const appsReducer = combineReducers({
  inspiro: persistReducer(
    {
      key: 'inspiro',
      storage,
      blacklist: inspiro.blacklist,
      whitelist: inspiro.whitelist,
    },
    inspiro,
  ),
  clockly: persistReducer(
    {
      key: 'clockly',
      storage,
      blacklist: inspiro.blacklist,
      whitelist: inspiro.whitelist,
    },
    clockly,
  ),
});

export default appsReducer;
