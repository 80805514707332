export const SET_PENDING = 'APPS/CLOCKLY/SET_PENDING';
export const SET_PAGE_NUMBER = 'APPS/CLOCKLY/SET_PAGE_NUMBER';
export const SET_PAGE_ITEM_COUNT = 'APPS/CLOCKLY/SET_PAGE_ITEM_COUNT';

export const SET_SEARCH_QUERY = 'APPS/CLOCKLY/SET_SEARCH_QUERY';

export const SET_USER_LIST_DATA = 'APPS/CLOCKLY/SET_USER_LIST_DATA';
export const SET_USER_DATA = 'APPS/CLOCKLY/SET_USER_DATA';
export const SET_USER_FILTERS = 'APPS/CLOCKLY/SET_USER_FILTERS';
export const SET_USER_FILTERS_PENDING = 'APPS/CLOCKLY/SET_USER_FILTERS_PENDING';

export const SET_CATEGORY_LIST_DATA = 'APPS/CLOCKLY/SET_CATEGORY_LIST_DATA';
export const SET_ACTIVE_CATEGORY_ID = 'APPS/CLOCKLY/SET_ACTIVE_CATEGORY_ID';
export const SET_FACE_LIST_DATA = 'APPS/CLOCKLY/SET_FACE_LIST_DATA';
