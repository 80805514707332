import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { Grid, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Info as InfoIcon } from '@material-ui/icons';
import DateFnsUtils from '@date-io/moment';
import Button from '@material-ui/core/Button';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import { getCustomers, setCustomersPage, setCustomersPageItems } from '../../../store/evtrack/actions';
// components
import Widget from '../../../components/Widget';
import { Typography } from '../../../components/Wrappers';
import { ROUTES, NAMES, IDS } from '../../../constants/routes';
import Breadcrumbs from '../../../components/Breadcrumbs';
import withRouter from '../../../hooks/with-router';
import { formatDate } from '../../../utils/date';

const breadcrumsRoutes = [
  {
    route: ROUTES[IDS.EVTRACK],
    name: NAMES[IDS.EVTRACK],
  },
];

const headCells = [
  { id: 'id', numeric: false, disablePadding: true, label: 'Id' },
  { id: 'appVersion', numeric: false, disablePadding: false, label: 'App Version' },
  { id: 'osVersion', numeric: false, disablePadding: false, label: 'OS Version' },
  { id: 'idfa', numeric: false, disablePadding: false, label: 'IDFA' },
  { id: 'idfv', numeric: false, disablePadding: false, label: 'IDFV' },
  { id: 'idfm', numeric: false, disablePadding: false, label: 'IDFM' },
  { id: 'appsFlyerUID', numeric: false, disablePadding: false, label: 'Appsflyer Id' },
  { id: 'createdAt', numeric: false, disablePadding: false, label: 'Created At' },
  { id: 'updatedAt', numeric: false, disablePadding: false, label: 'Updated At' },
];

const FROM_DATE = formatDate(moment().subtract(1, 'months'));
const TO_DATE = formatDate();

const Customers = () => {
  const navigate = useNavigate();

  const classes = useStyles();
  const [valueFrom, setValueFrom] = useState(FROM_DATE);
  const [valueTo, setValueTo] = useState(TO_DATE);
  // global
  const dispatch = useDispatch();
  const isPending = useSelector((state) => state.evtrack.pending);
  const customers = useSelector((state) => state.evtrack.customers.data);
  const count = useSelector((state) => state.evtrack.customers.count);
  const pageNumber = useSelector((state) => state.evtrack.customers.pageNumber);
  const pageItemCount = useSelector((state) => state.evtrack.customers.pageItemCount);
  const selectedProjectId = useSelector((state) => state.projects.selectedProjectId);

  useEffect(() => {
    dispatch(getCustomers({ dateFrom: valueFrom, dateTo: valueTo }));
  }, [pageNumber, pageItemCount, selectedProjectId]);

  const handleChangeRowsPerPage = (event) => {
    dispatch(setCustomersPageItems(parseInt(event.target.value, 10)));
    dispatch(setCustomersPage(1));
  };

  const handleChangePage = (event, newPage) => {
    if (newPage - 1 < pageNumber) {
      dispatch(setCustomersPage(newPage + 1));
    }

    if (newPage - 1 > pageNumber) {
      dispatch(setCustomersPage(newPage - 1));
    }
  };

  const handleButtonPress = () => {
    dispatch(getCustomers({ dateFrom: valueFrom, dateTo: valueTo }));
  };

  const handleFromChange = (newValue) => {
    const date = formatDate(newValue);

    setValueFrom(date);
  };

  const handleToChange = (newValue) => {
    const date = formatDate(newValue);

    setValueTo(date);
  };

  const handleRowClick = useCallback((id) => {
    navigate(`${ROUTES[IDS.USERS]}/${id}`);
  }, []);

  return (
    <>
      <Breadcrumbs links={breadcrumsRoutes} currentRoute={NAMES[IDS.USERS]} />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget disableWidgetMenu bodyClass={classes.tableTopOverflow}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="DD/MM/YYYY"
                margin="none"
                id="date-picker-inline"
                label="Date From"
                value={moment(valueFrom, 'YYYY-MM-DD').toDate()}
                onChange={handleFromChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                className={classes.datepickerLeft}
              />
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="DD/MM/YYYY"
                margin="none"
                id="date-picker-inline"
                label="Date To"
                value={moment(valueTo, 'YYYY-MM-DD').toDate()}
                onChange={handleToChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                className={classes.datepickerRight}
              />
            </MuiPickersUtilsProvider>

            <Button variant="contained" size="large" color="primary" type="button" className={classes.button} onClick={handleButtonPress}>
              Apply
            </Button>
          </Widget>
        </Grid>

        <Grid item xs={12}>
          <Widget disableWidgetMenu noBodyPadding noHeaderPadding bodyClass={classes.tableOverflow}>
            <TableContainer>
              <Table stickyHeader className={classes.table} aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell key={headCell.id} align="left">
                        <TableSortLabel>
                          <Typography size="sm" color="text" colorBrightness="secondary">
                            {headCell.label}
                          </Typography>
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {customers.map((row) => (
                    <TableRow hover tabIndex={-1} key={row._id} onClick={() => handleRowClick(row._id)}>
                      <TableCell component="th" scope="row">
                        <Typography size="sm" color="text" colorBrightness="secondary">
                          {row._id}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography size="sm" color="text" colorBrightness="secondary">
                          {row.appVersion}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography size="sm" color="text" colorBrightness="secondary">
                          {row.osVersion}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography size="sm" color="text" colorBrightness="secondary">
                          {row.idfa}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography size="sm" color="text" colorBrightness="secondary">
                          {row.idfv}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography size="sm" color="text" colorBrightness="secondary">
                          {row.idfm}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography size="sm" color="text" colorBrightness="secondary">
                          {row.appsFlyerUID}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography size="sm" color="text" colorBrightness="secondary">
                          {moment(row.createdAt).format('YYYY-MM-DD h:mm:ss')}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography size="sm" color="text" colorBrightness="secondary">
                          {moment(row.updatedAt).format('YYYY-MM-DD h:mm:ss')}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              component="div"
              count={count}
              rowsPerPage={pageItemCount}
              page={pageNumber - 1}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            {isPending && (
              <div className={classes.loaderWrap}>
                <div className={classes.loader}>
                  <CircularProgress size={26} />
                </div>
              </div>
            )}
          </Widget>
        </Grid>
      </Grid>
    </>
  );
};

export default withRouter(Customers);

const useStyles = makeStyles((theme) => ({
  tableOverflow: {
    position: 'relative',
  },
  root: {
    width: '100%',
  },
  tableTopOverflow: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  datepicker: {
    marginLeft: theme.spacing(4),
  },
  button: {
    marginLeft: theme.spacing(4),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  loaderWrap: {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255,255,255,0.7)',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 9,
  },
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  time: {
    minWidth: '150px',
  },
  params: {
    maxWidth: '500px',
  },
  infoButton: {
    backgroundColor: 'transparent',
    border: 'none',
    outline: 'none',
    padding: 10,
  },
  infoIcon: {
    opacity: 0.6,
  },
  endCell: {
    width: '100px',
  },
}));
